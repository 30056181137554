interface Floorplans {
    modelId: string
}

const controller = {
    getFloorplan(floorplans: Floorplans[], modelId: string) {
        let match = {};

        /**
         * We cannot use floorplans[index] because if a floor plan has no image, it will not be in the photo gallery.
         * In other words: listing.floorplans[i] !== floorplansShownInPhotoGallery[i]
         */
        floorplans.forEach((fp) => {
            if (fp.modelId === modelId) {
                match = fp;
            }
        });

        return match;
    }
};

export default controller;
