// @ts-nocheck
/* eslint-enable */
import styled from 'styled-components';
import { font } from 'app/shared/styles/_fontsize';
import { inline } from 'app/shared/styles/_spacing';
import { colors } from 'app/shared/styles/_colors';
import { zindex } from 'app/shared/styles/_zindex';

/**
 * Adapted from a11ywithlindsey - accessible radio buttons
 * @see: https://www.a11ywithlindsey.com/blog/create-custom-keyboard-accessible-radio-buttons
 */

export const Wrapper = styled.div`
    display: flex;
    margin: 4px 0 8px 0;
`;

export const Label = styled.label`
    ${font.sm};

    ${(props) =>
        props.disabled &&
        `
        color: ${colors['$hpx-grey-500']};
    `}
`;

export const Input = styled.input.attrs(() => ({
    type: 'radio'
}))`
    width: 20px;
    height: 20px;
    opacity: 0;
    ${inline._1x}; // If margin is adjusted, don't forget to adjust the "left" property below!
    z-index: ${zindex['$z-index-base']};

    + ${Label} {
        position: relative;
        display: inline-block;

        &::before {
            content: '';
            position: absolute;
            display: inline-block;
            left: -28px; // Adjust this if margin has changed
            width: 20px;
            height: 20px;
            top: 0;
            background: transparent;
            border-radius: 50%;
            border: 1px solid ${colors['$hpx-grey-500']};
        }

        &::after {
            content: '';
            position: absolute;
            display: inline-block;
            left: -28px; // Adjust this if margin has changed
            top: 0;
            border-radius: 50%;
            width: 20px;
            height: 20px;
        }
    }

    &:hover {
        cursor: pointer;
    }

    &:checked {
        + ${Label}::after {
            background-color: ${colors['$hpx-teal-500']};
            outline-color: ${colors['$hpx-white']};
            outline-offset: -3px;
            outline-style: auto;
            outline-width: 1px;
        }
    }

    &:focus {
        + ${Label}::before {
            outline-color: ${colors['$hpx-teal-700']};
            outline-offset: 1px;
            outline-style: auto;
            outline-width: 1px;
        }
    }
`;
export const Icon = styled.img`
    ${inline._1x};
`;
