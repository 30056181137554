// @ts-nocheck
/* eslint-enable */
/* eslint-disable react/no-multi-comp */
// App
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { margin, padding, inline, stacked } from 'app/shared/styles/_spacing';

// Components
import HdpContentWrapper from 'app/shared/modules/hdp/HdpContentWrapper';
import Linker from 'app/shared/modules/Linker';
import ListItem from 'app/shared/modules/ListItem';
import Text from 'app/shared/core/Text';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';

// Misc / Utils
import IconPetCat from 'images/icons/cat.svg';
import IconPetDog from 'images/icons/dog.svg';
import IconPetAllowed from 'images/icons/allowed-teal.svg';
import IconPetNotAllowed from 'images/icons/not-allowed-red.svg';
import IconUnknown from 'images/icons/question-mark.svg';
import './style.scss';

const IconCat = <img className="PetPolicy-icon-pets" src={IconPetCat} alt="" />;
const IconDog = <img className="PetPolicy-icon-pets" src={IconPetDog} alt="" />;

const StyledContainer = styled.div`
    ${margin.top._1x};
`;

const StyledPetPolicyList = styled.ul`
    ${padding.top_1x};
`;

const StyledAskAboutPetPolicy = styled.div`
    ${padding.top._1x};
`;

const StyledPetTextIcon = styled.span`
    ${inline._1x};
`;
const StyledCatIcon = styled.img`
    ${inline._1x};
`;
const StyledStackedDivs = styled.div`
    ${stacked._2x};
`;
const petHeadingText = (icon, text) => {
    return (
        <Text size="md">
            <StyledPetTextIcon>{icon}</StyledPetTextIcon>
            {text}
        </Text>
    );
};

const petPolicyList = (policy) => {
    return (
        <StyledPetPolicyList>
            {policy.deposit && <ListItem className="PetPolicy-list-item">{policy.deposit}</ListItem>}
            {policy.oneTimeFee && <ListItem className="PetPolicy-list-item">{policy.oneTimeFee}</ListItem>}
            {policy.monthlyFee && <ListItem className="PetPolicy-list-item">{policy.monthlyFee}</ListItem>}
            {policy.maxNumberAllowed && <ListItem className="PetPolicy-list-item">{policy.maxNumberAllowed}</ListItem>}
            {policy.maxWeightAllowed && <ListItem className="PetPolicy-list-item">{policy.maxWeightAllowed}</ListItem>}
            {policy.comment && <ListItem className="PetPolicy-list-item">{policy.comment}</ListItem>}
            {policy.restriction && <ListItem className="PetPolicy-list-item">{policy.restriction}</ListItem>}
        </StyledPetPolicyList>
    );
};

const petCtaClick = (dispatch, callback) => {
    // Track
    dispatch(analyticsEvent(gaEvents.HDP_PET_POLICY_CTA_CLICK));

    // Fire callback
    callback();
};

const PetPolicy = ({ onPetCtaClick }) => {
    const dispatch = useDispatch();

    // Redux selectors
    const petPolicy = useSelector((state) => state.currentListingDetails.currentListing.amenities.petPolicies);
    const amenities = useSelector((state) => state.currentListingDetails.currentListing.amenities.amenities);
    const condensedAmenities = useSelector(
        (state) => state.currentListingDetails.currentListing.amenities.condensedAmenities
    );

    const checkPetStatus = () => {
        // No pets allowed condition
        const noPetsAllowed = condensedAmenities && condensedAmenities.pets === 'No Pets Allowed';
        if (noPetsAllowed) {
            return { catsAllowed: false, dogsAllowed: false };
        }

        // Is feed listing?
        const hasPetPolicy = petPolicy;
        if (hasPetPolicy) {
            return {
                catsAllowed: petPolicy && petPolicy.cat && petPolicy.cat.allowed || null,
                dogsAllowed: petPolicy && petPolicy.dog && petPolicy.dog.allowed || null
            };
        }

        // Is manual listing?
        const manListCatsAllowed = (amenities && amenities.includes('Cats Allowed')) || null;
        const manListSmallDogsAllowed = (amenities && amenities.includes('Small Dogs Allowed')) || null;
        const manListLargeDogsAllowed = (amenities && amenities.includes('Large Dogs Allowed')) || null;
        if (!hasPetPolicy && (manListCatsAllowed || manListSmallDogsAllowed || manListLargeDogsAllowed)) {
            return {
                isManualListing: true,
                catsAllowed: manListCatsAllowed,
                dogsAllowed: {
                    small: manListSmallDogsAllowed,
                    large: manListLargeDogsAllowed
                }
            };
        }

        return {
            catsAllowed: null,
            dogsAllowed: null
        };
    };

    const { isManualListing = false, catsAllowed, dogsAllowed } = checkPetStatus();

    const iconPetStatus = {
        null: IconUnknown,
        true: IconPetAllowed,
        false: IconPetNotAllowed
    };

    const IconCatStatus = <StyledCatIcon className="PetPolicy-icon-status" src={iconPetStatus[catsAllowed]} alt="" />;

    const IconDogStatus = (
        <img
            className="PetPolicy-icon-status"
            src={isManualListing ? iconPetStatus[dogsAllowed.small || dogsAllowed.large] : iconPetStatus[dogsAllowed]}
            alt=""
        />
    );

    const petHeaderAside = (
        <span className="PetPolicy-icons">
            {IconCat}
            {IconCatStatus}
            {IconDog}
            {IconDogStatus}
        </span>
    );

    return (
        <HdpContentWrapper collapsable header="Pet policy" headerHtmlTag="h2" headerAside={petHeaderAside}>
            <StyledContainer>
                {catsAllowed === null && dogsAllowed === null ? (
                    <Fragment>
                        <Text>Pet policy information isn't provided.</Text>
                        <StyledAskAboutPetPolicy onClick={() => petCtaClick(dispatch, onPetCtaClick)}>
                            <Linker>Ask about the pet policy</Linker>
                        </StyledAskAboutPetPolicy>
                    </Fragment>
                ) : (
                    <Fragment>
                        {/*
                         * CATS PET POLICY DATA - feed and manual listings
                         */}
                        {catsAllowed === false && (
                            <StyledStackedDivs>{petHeadingText(IconCat, 'No cats allowed')}</StyledStackedDivs>
                        )}

                        {catsAllowed && (
                            <StyledStackedDivs>
                                {petHeadingText(IconCat, 'Cats allowed')}
                                {petPolicy && petPolicy.cat && petPolicyList(petPolicy.cat)}
                            </StyledStackedDivs>
                        )}

                        {/*
                         * DOGS PET POLICY DATA - feed listings
                         */}
                        {!isManualListing && dogsAllowed === false && (
                            <StyledStackedDivs>{petHeadingText(IconDog, 'No dogs allowed')}</StyledStackedDivs>
                        )}

                        {!isManualListing && dogsAllowed && petPolicy && (
                            <StyledStackedDivs>
                                {petHeadingText(IconDog, 'Dogs allowed')}
                                {petPolicyList(petPolicy.dog)}
                            </StyledStackedDivs>
                        )}

                        {/*
                         * SMALL DOGS - manual listings
                         */}
                        {isManualListing && dogsAllowed.small && (
                            <StyledStackedDivs>{petHeadingText(IconDog, 'Small dogs allowed')}</StyledStackedDivs>
                        )}

                        {/*
                         * LARGE DOGS - manual listings
                         */}
                        {isManualListing && dogsAllowed.large && (
                            <StyledStackedDivs>{petHeadingText(IconDog, 'Large dogs allowed')}</StyledStackedDivs>
                        )}
                    </Fragment>
                )}
            </StyledContainer>
        </HdpContentWrapper>
    );
};

export default PetPolicy;
