// @ts-nocheck
/* eslint-enable */
// App
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import HdpContentWrapper from 'app/shared/modules/hdp/HdpContentWrapper';
import ListingWrapper from 'app/shared/modules/listing-cards/ListingWrapper';

// Misc / utils
import IconArrowGrey from 'images/icons/arrow-grey.svg';
import { impression } from 'app/client/zg-analytics';
import * as S from './styles';
import { analyticsEvent } from 'app/client/universal-analytics';
import { TrackImpressionForPropertyCard } from 'app/shared/models/Clickstream/ListingCardClickstreamEvents';

/**
 * HPWEB-3119 - RE: Max portfolio listings to be shown for a given provider...
 * we show 6 by default and the designs have a “view more properties” link …so technically
 * we can expand that list as much as we want to … not sure if there is anything in the
 * contract that says - SHOW ALL THE PROPERTIES within the PORTFOLIO - showing 6 by default
 * and clicking on more expanding to 12 is a good starting point
 */
const ABOVE_THE_FOLD_LISTINGS_TO_SHOW = 6;
const BELOW_THE_FOLD_LISTINGS_TO_SHOW = 12;
const HEADER = 'More from this provider';
const PortfolioProperties = ({ portfolioListings = [], onListingClick }) => {
    const dispatch = useDispatch();
    const currentListing = useSelector((state) => state.currentListingDetails.currentListing);
    const [isCollapsed, setIsCollapsed] = useState(true);

    if (portfolioListings.length < 1) {
        return null;
    }

    const visiblePortfolioListings = portfolioListings.slice(0, ABOVE_THE_FOLD_LISTINGS_TO_SHOW);
    const belowFoldPortfolioListings = portfolioListings.slice(
        ABOVE_THE_FOLD_LISTINGS_TO_SHOW,
        BELOW_THE_FOLD_LISTINGS_TO_SHOW
    );

    const handleClickCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    const handleListingClick = () => {
        onListingClick();
    };

    const trackImpression = (listing) => {
        const { aliasEncoded } = listing;

        impression({
            category: 'RentalHDP',
            label: 'portfolioListingsForRentModule',
            dimension5: currentListing.aliasEncoded,
            dimension60: aliasEncoded
        });

        dispatch(analyticsEvent({ newLaneEvent: TrackImpressionForPropertyCard({ listing, triggerLocation: 'portfolio_listings_module' }) }));
    };

    return (
        <HdpContentWrapper collapsable active header={HEADER} headerHtmlTag="h2" slimPadding>
            <S.ListingsContainer>
                {visiblePortfolioListings.map((listing) => {
                    return (
                        <ListingWrapper
                            key={`portfolio-listing-${listing.aliasEncoded}`}
                            listing={listing}
                            onClick={handleListingClick}
                            trackImpression={trackImpression}
                            triggerLocation="home_details"
                            triggerObject="home_details_component|more_from_provider"
                        />
                    );
                })}

                {!isCollapsed &&
                    belowFoldPortfolioListings.map((listing) => {
                        return (
                            <ListingWrapper
                                key={`portfolio-listing-${listing.aliasEncoded}`}
                                listing={listing}
                                onClick={handleListingClick}
                                trackImpression={trackImpression}
                                triggerLocation="home_details"
                                triggerObject="home_details_component|more_from_provider"
                            />
                        );
                    })}
            </S.ListingsContainer>

            {portfolioListings.length > ABOVE_THE_FOLD_LISTINGS_TO_SHOW && (
                <S.ButtonContainer>
                    <S.ExpandButton onClick={handleClickCollapse}>
                        <S.CollapseArrow src={IconArrowGrey} height="16px" width="16px" isCollapsed={isCollapsed} />
                        {isCollapsed ? 'See all properties' : `See ${ABOVE_THE_FOLD_LISTINGS_TO_SHOW} properties`}
                    </S.ExpandButton>
                </S.ButtonContainer>
            )}
        </HdpContentWrapper>
    );
};

export default PortfolioProperties;
