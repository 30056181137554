// @ts-nocheck
/* eslint-enable */
import React, { useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useYieldCallback } from '@zillow/yield-callback';
import PropTypes from 'prop-types';
import cx from 'classnames';
import FocusLock from 'react-focus-lock';
import styled from 'styled-components';
import { inline, stacked, padding, inset } from 'app/shared/styles/_spacing';
import { colors } from 'app/shared/styles/_colors';
import './style.scss';
import ContactPhone from 'app/shared/modules/hdp/form/Contact/ContactPhone';
import numberUtils from 'app/shared/utils/numberUtils';
import dateUtils from 'app/shared/utils/dateUtils';
import IconVerified from 'images/verified.svg';
import IconX from 'images/icons/x.svg';
import Text from 'app/shared/core/Text';

const { bool, func } = PropTypes;
const StlyedVerifiedIcon = styled.img`
    ${inline._1x};
`;
const VerifiedContainer = styled.div`
    ${stacked._2x};
`;
const StyledContactPhone = styled(ContactPhone)`
    ${stacked._2x};
`;
const StyledContainer = styled.div`
    ${padding.top._3x};
`;
const StyledLandlordLogoContainer = styled.div`
    ${padding.top._1x};
`;
const StyledLandlordButton = styled.button`
    ${inset._2x};
`;
const StyledColoredText = styled(Text)`
    color: ${colors['$hpx-blue-600']};
`;
const StyledDarkText = styled(Text)`
    color: ${colors['$hpx-grey-600']};
`;
const StyledBoldColoredText = styled(StyledColoredText)`
    font-weight: 700;
`;
const LandlordInfo = ({ isPopup, onClickPhoneContact = () => { }, onCloseModal = () => { } }) => {
    // Refs
    const clickAwayRef = useRef(null);

    // Redux
    const address = useSelector((state) => state.currentListingDetails.currentListing.address);
    const details = useSelector((state) => state.currentListingDetails.currentListing.details);
    const listedBy = useSelector((state) => state.currentListingDetails.currentListing.listedBy);
    const trusted = useSelector((state) => state.currentListingDetails.currentListing.trusted);
    const waitlisted = useSelector((state) => state.currentListingDetails.currentListing.waitlisted);
    const isApartmentBldg = useSelector((state) => state.currentListingDetails.currentListing.isApartmentBldg);

    useEffect(() => {
        const handleMouseDown = (e) => {
            const hasClickedAway = clickAwayRef.current && !clickAwayRef.current.contains(e.target);
            if (hasClickedAway) {
                onCloseModal();
            }
        };

        window.addEventListener('mousedown', handleMouseDown);
        window.addEventListener('touchstart', handleMouseDown);

        return () => {
            window.removeEventListener('mousedown', handleMouseDown);
            window.removeEventListener('touchstart', handleMouseDown);
        };
    }, [onCloseModal]);

    const yieldCloseModal = useYieldCallback(() => {
        onCloseModal(null);
    });

    const handleCloseButtonClick = useCallback(() => {
        yieldCloseModal();
    }, [yieldCloseModal]);

    // Esc key will close landlord modal
    const handleOnKeyDown = useCallback(
        (e) => {
            const ESC_KEY = e.keyCode === 27;
            return ESC_KEY && onCloseModal(null);
        },
        [onCloseModal]
    );

    const logoUrl = listedBy.logoUrlCdn || details.logoUrlCdn;
    const hideStreet = address.hideStreet;
    const getFormattedResponseRate = (resRate) => {
        return numberUtils.asPercent(Number(resRate).toFixed(0));
    };
    const getFormattedResponseTime = (resTime) => {
        return dateUtils.recencyTime(Date.now() - resTime, new Date()).displayDiff;
    };

    return (
        <FocusLock>
            <div
                className={cx('LandlordInfo', {
                    'LandlordInfo-popup': isPopup
                })}
                onKeyDown={handleOnKeyDown}
                ref={clickAwayRef}
            >
                <StyledColoredText size="lg">
                    {listedBy.contactName || listedBy.companyName || 'Manager'}
                </StyledColoredText>
                {trusted && (
                    <VerifiedContainer className="LandlordInfo-verified">
                        <StlyedVerifiedIcon src={IconVerified} className="LandlordInfo-verified-icon" />
                        <Text size="sm">Verified</Text>
                    </VerifiedContainer>
                )}
                {!waitlisted && <StyledContactPhone onClick={onClickPhoneContact} isPopup={isPopup} />}
                <StyledBoldColoredText>{hideStreet ? '' : details.title}</StyledBoldColoredText>
                <StyledContainer>
                    {!isApartmentBldg && listedBy.responseRate && (
                        <div className="LandlordInfo-detail">
                            <StyledDarkText size="sm">{'Response rate '}</StyledDarkText>
                            <StyledBoldColoredText size="sm">
                                {getFormattedResponseRate(listedBy.responseRate)}
                            </StyledBoldColoredText>
                        </div>
                    )}
                    {!isApartmentBldg && listedBy.responseTime && (
                        <div className="LandlordInfo-detail">
                            <StyledDarkText size="sm">{'Response time '}</StyledDarkText>
                            <StyledBoldColoredText size="sm">
                                {getFormattedResponseTime(listedBy.responseTime)}
                            </StyledBoldColoredText>
                        </div>
                    )}
                    {listedBy?.memberSince?.date && (
                        <div className="LandlordInfo-detail">
                            <StyledDarkText size="sm">{'Member since '}</StyledDarkText>
                            <StyledBoldColoredText size="sm">
                                {dateUtils.formatDateToString(listedBy.memberSince.date, { skipDay: true })}
                            </StyledBoldColoredText>
                        </div>
                    )}
                </StyledContainer>
                {logoUrl && (
                    <StyledLandlordLogoContainer className="LandlordInfo-logo">
                        {logoUrl && <img className="LandlordInfo-listedby-logo" src={logoUrl} />}
                    </StyledLandlordLogoContainer>
                )}

                <StyledLandlordButton className="LandlordInfo-x" onClick={handleCloseButtonClick}>
                    <img src={IconX} height="16" width="16" />
                </StyledLandlordButton>
            </div>
        </FocusLock>
    );
};

LandlordInfo.propTypes = {
    isPopup: bool,
    onClickPhoneContact: func,
    onCloseModal: func
};

export default LandlordInfo;
