// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import map from 'lodash/map';
import PropTypes from 'prop-types';

import ListingWrapper from 'app/shared/modules/listing-cards/ListingWrapper';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import { analyticsEvent } from 'app/client/universal-analytics';
import Center from 'app/shared/modules/Center';
import Row from 'app/shared/core/Row';
import Title from 'app/shared/modules/Title';

class WaitlistSuccessPopup extends React.Component {
    static propTypes = {
        similarListings: PropTypes.array
    };

    static defaultProps = {
        similarListings: []
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.dispatch(analyticsEvent(gaEvents.CONTACT_SUCCESS));
    }

    handleSimilarListingClick = (listing) => {
        this.props.dispatch(
            analyticsEvent(gaEvents.SIMILAR_LISTING_CLICK, {
                label: listing.aliasEncoded
            })
        );
    };

    displaySimilarListings() {
        const { similarListings } = this.props;
        const shownSimilarListings = similarListings.slice(0, 2);

        if (shownSimilarListings.length > 0) {
            this.props.dispatch(
                analyticsEvent(gaEvents.SIMILAR_LISTING_DISPLAYED, {
                    label: shownSimilarListings.length
                })
            );

            return (
                <div>
                    <br />
                    {map(shownSimilarListings, (similarListing) => {
                        return (
                            <ListingWrapper
                                key={similarListing.aliasEncoded}
                                listing={similarListing}
                                onClick={this.handleSimilarListingClick}
                                triggerLocation='home_details'
                                triggerObject='property_card'
                            />
                        );
                    })}
                </div>
            );
        }

        return null;
    }

    render() {
        return (
            <div className="WaitlistSuccessPopup">
                <Center>
                    <Title id="dialog-title" size="lg">
                        You're on the waitlist!
                    </Title>
                </Center>
                <Row>
                    Success! Your name has been added to the waitlist. Once there's an available unit, we'll email you
                    in no time.
                </Row>
                <Row>'Til then, why don't you check out some similar listings?</Row>
                {this.displaySimilarListings()}
            </div>
        );
    }
}

export default connect()(WaitlistSuccessPopup);
