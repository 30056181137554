import React, { Fragment, ReactNode } from 'react';

const logger = getLogger('modules/tab');

interface TabItemProps {
    tabTitle?: string; // tabTitle is an optional string prop
    children?: ReactNode; // children can be any valid React nodes
}

const TabItem: React.FC<TabItemProps> = ({ tabTitle, children }) => {
    if (!tabTitle) {
        logger.error('TabItem is missing tabTitle prop');
    }
    return <Fragment>{children}</Fragment>;
};

export default TabItem;
