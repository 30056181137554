// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import extend from 'lodash/extend';

// Actions
import AuthActions from 'app/shared/flux/actions/AuthActions';

// Components
import Container from 'app/shared/core/Container';
import PromptCasl from 'app/shared/modules/auth/PromptCasl';
import PromptEmail from 'app/shared/modules/auth/PromptEmail';
import PromptEmailSent from 'app/shared/modules/auth/PromptEmailSent';
import PromptForgotPassword from 'app/shared/modules/auth/PromptForgotPasswordV1';
import PromptPassword from 'app/shared/modules/auth/PromptPassword';

const logger = getLogger('modules/auth');

class PromptLogin extends Component {
    static defaultProps = {
        customHeaders: {
            email: null,
            password: null
        },
        handleLoginSuccess: () => { },
        title: 'Sign In / Register',
        shouldRedirect: true
    };

    constructor(props) {
        super(props);

        const { user } = this.props;
        const email = user.info.email || '';
        const skipToPassword = user.info.email && user.info.passwordSet;
        const isFreepassUser = user.info.email && user.info.passwordStatus === 'freepass';

        this.state = {
            email,
            visible: {
                promptPassword: skipToPassword,
                promptEmailSent: isFreepassUser,
                promptForgotPassword: false,
                promptCasl: false
            }
        };
    }

    componentDidMount() {
        const { dispatch, user } = this.props;
        if (user.info.email && user.info.passwordStatus === 'freepass') {
            // freepass users should only hit /login if they are redirected from a comet page
            // in this case, resend the fp verification email
            dispatch(AuthActions.login.load({ email: user.info.email, dontSendWelcomeEmail: true })).catch((err) => {
                logger.warn(err, 'PromptLogin#constructor error sending email to existing freepass user');
                return false;
            });
        }
    }

    handleBackClick = (type) => {
        // Set all prompts to false by default (which will show email entry screen)
        const updateVisible = {
            promptPassword: type === 'showPasswordPrompt',
            promptEmailSent: false,
            promptForgotPassword: false,
            promptCasl: false
        };

        this.setState({ visible: updateVisible });
    };

    makeVisible = (component) => {
        let newVisible = extend({}, this.state.visible);

        for (let key in newVisible) {
            if (newVisible.hasOwnProperty(key)) {
                newVisible[key] = false;
            }
        }

        newVisible[component] = true;

        this.setState({
            visible: newVisible
        });
    };

    setEmail = (email) => {
        this.setState({
            email
        });
    };

    render() {
        const { customHeaders, handleLoginSuccess, showDescription, showTitle, title, shouldRedirect } = this.props;
        const { email, visible } = this.state;
        let mainContent;

        if (visible.promptPassword) {
            mainContent = (
                <PromptPassword
                    email={email}
                    handleLoginSuccess={handleLoginSuccess}
                    header={customHeaders.password}
                    onBackClick={() => this.handleBackClick('showEmailPrompt')}
                    makeVisible={this.makeVisible}
                    showDescription={showDescription}
                    showTitle={showTitle}
                    shouldRedirect={shouldRedirect}
                />
            );
        } else if (visible.promptEmailSent) {
            mainContent = <PromptEmailSent email={email} showDescription={showDescription} showTitle={showTitle} />;
        } else if (visible.promptForgotPassword) {
            mainContent = (
                <PromptForgotPassword
                    email={email}
                    onBackClick={() => this.handleBackClick('showPasswordPrompt')}
                    showDescription={showDescription}
                    showTitle={showTitle}
                />
            );
        } else if (visible.promptCasl) {
            mainContent = (
                <PromptCasl
                    email={email}
                    onBackClick={() => this.handleBackClick('showEmailPrompt')}
                    showDescription={showDescription}
                    showTitle={showTitle}
                />
            );
        } else {
            mainContent = (
                <PromptEmail
                    header={customHeaders.email}
                    handleLoginSuccess={handleLoginSuccess}
                    makeVisible={this.makeVisible}
                    email={email}
                    setEmail={this.setEmail}
                    showDescription={showDescription}
                    showTitle={showTitle}
                    title={title}
                />
            );
        }

        return (
            <div className="PromptLogin">
                <Container size="sm">{mainContent}</Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isInitialSsrPage: state.app.isInitialSsrPage,
        pathname: state.location.current.pathname,
        user: state.user
    };
};

export default connect(mapStateToProps)(PromptLogin);
