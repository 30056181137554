// @ts-nocheck
/* eslint-enable */
// App
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styled from 'styled-components';
import { stacked } from 'app/shared/styles/_spacing';

// Components
import Text from 'app/shared/core/Text';
import Title from 'app/shared/modules/Title';

// Lodash
import map from 'lodash/map';
import isEqual from 'lodash/isEqual';

// Misc / Utils
import './style.scss';

const StyledText = styled(Text)`
    ${stacked._1x};
`;
const StyledSection = styled.section`
    ${stacked._1x};
`;
const StyledTitle = styled(Title)`
    ${stacked._xs};
`;
class FaqSection extends React.Component {
    static propTypes = {
        data: PropTypes.array,
        wrapper: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.elementType])
    };

    static defaultProps = {
        data: [],
        wrapper: Fragment
    };

    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps) {
        const { data } = this.props;

        if (isEqual(data, nextProps.data)) {
            return false;
        }

        return true;
    }

    renderStandardAnswer = (question) => {
        return <Text>{question.answer}</Text>;
    };

    renderListedAnswer = (question) => {
        const answerType = question.answerType;
        const ListElement = answerType === 'unorderedList' ? 'ul' : 'ol';

        return (
            <Fragment>
                {question.listHeader && (
                    <StyledText htmlTag="p">
                        {question.listHeader}
                    </StyledText>
                )}
                <ListElement className={cx(ListElement === 'ul' ? 'AreaFaq-unordered-list' : 'AreaFaq-ordered-list')}>
                    {Array.isArray(question.answer) &&
                        question.answer.length &&
                        question.answer.map((answer, idx) => {
                            return <li key={`answer-${idx}-${answer}`}>{answer}</li>;
                        })}
                </ListElement>
            </Fragment>
        );
    };

    renderFaqSection = (data) => {
        return map(data, (question) => {
            const answerType = question.answerType;
            return (
                <StyledSection key={`question-id-${question.question}`}>
                    <StyledTitle size="md" htmlTag="h3">
                        {question.question}
                    </StyledTitle>
                    {answerType === 'answer' && this.renderStandardAnswer(question)}
                    {(answerType === 'unorderedList' || answerType === 'orderedList') &&
                        this.renderListedAnswer(question)}
                </StyledSection>
            );
        });
    };

    render() {
        const { wrapper, data, ...wrapperProps } = this.props;
        const Wrapper = wrapper;

        return (
            <article className="AreaFaq">
                <Wrapper {...wrapperProps}>{this.renderFaqSection(data)}</Wrapper>
            </article>
        );
    }
}

export default FaqSection;
