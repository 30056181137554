// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';

import Row from 'app/shared/core/Row';
import Button from 'app/shared/core/Button';
import Center from 'app/shared/modules/Center';

class ErrorModal extends Component {
    render() {
        return (
            <div className="ErrorModal">
                <Row size="md" id="dialog-title">Sorry! Something went wrong on our end.</Row>
                <Row size="md">
                    <Center>
                        <Button size="md" btnType="primary" onClick={this.props.onHidePopup}>
                            Go back
                        </Button>
                    </Center>
                </Row>
            </div>
        );
    }
}

export default ErrorModal;
