// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import './style.scss';
import StaticStarRating from 'app/shared/modules/StaticStarRating';
import ReviewsBreakdown from 'app/shared/modules/reviews/ReviewsBreakdown';
import Text from 'app/shared/core/Text';
import Divider from 'app/shared/modules/Divider';
import Linker from 'app/shared/modules/Linker';
import Row from 'app/shared/core/Row';

const StyledBoldText = styled(Text)`
    font-weight: 700;
`;
class RatingsAndReviewStats extends Component {
    static propTypes = {
        onClickReviews: PropTypes.func,
        ratingsAndReviews: PropTypes.object
    };

    static defaultProps = {
        onClickReviews: null,
        ratingsAndReviews: {}
    };

    constructor(props) {
        super(props);
    }

    handleOnClickReviews = () => {
        const { isValid, onClickReviews } = this.props;
        onClickReviews(isValid);
    };

    render() {
        const { onClickReviews, ratingsAndReviews } = this.props;
        const { reviewsSummary, reviews, reviewsBreakdown } = ratingsAndReviews;
        const isValidReviewsSummary = reviewsSummary && reviewsSummary.totalNumberOfReviews > 0;
        const isValidReviews = reviews && reviews.length > 0;

        if (!isValidReviewsSummary && !isValidReviews) {
            return null;
        }

        return (
            <div className="RatingsAndReviewStats">
                <Row size="sm">
                    <StyledBoldText size="md">
                        {reviewsSummary.ratingLabel}
                    </StyledBoldText>
                </Row>
                <div className="RatingsAndReviewStats-inline">
                    <StaticStarRating filledStars={reviewsSummary.averageStarLevel} totalStars={5} size="lg" />
                </div>
                <div className="RatingsAndReviewStats-inline">
                    {'  •  '}
                    <Text className="RatingsAndReviewStats-rating" size="sm">
                        {reviewsSummary.averageStarLevel}
                    </Text>
                    {'  •  '}
                    {onClickReviews ? (
                        <Linker linkType="underline" onClick={this.handleOnClickReviews}>
                            <Text size="sm">
                                ({reviewsSummary.totalNumberOfReviews}{' '}
                                {reviewsSummary.totalNumberOfReviews === 1 ? 'review' : 'reviews'})
                            </Text>
                        </Linker>
                    ) : (
                        <Text size="sm">
                            ({reviewsSummary.totalNumberOfReviews}{' '}
                            {reviewsSummary.totalNumberOfReviews === 1 ? 'review' : 'reviews'})
                        </Text>
                    )}
                </div>
                <Divider />
                {reviewsBreakdown && <ReviewsBreakdown reviewsBreakdown={reviewsBreakdown} />}
                {Boolean(reviewsSummary.ratingsWithoutReviews) && (
                    <div className="RatingsAndReviewStats-only-stars">
                        <Text size="sm">
                            {reviewsSummary.ratingsWithoutReviews}{' '}
                            {reviewsSummary.ratingsWithoutReviews === 1 ? 'reviewer' : 'reviewers'} only gave a star
                            rating.
                        </Text>
                    </div>
                )}
            </div>
        );
    }
}

export { RatingsAndReviewStats };
export default RatingsAndReviewStats;
