import React from 'react';
import Icon3DGlasses from 'images/icons/3d-glasses.svg';
import './style.scss';
import Text from 'app/shared/core/Text';

const Tour3dButton: React.FC = () => {
    return (
        <div className="Tour3dButton">
            <img src={Icon3DGlasses} className="Tour3dButton-icon" alt="3D Glasses Icon" />
            <Text className="Tour3dButton-3d-text">3D</Text>
            <Text className="Tour3dButton-3dtour-text">3D tour</Text>
        </div>
    );
};

export default Tour3dButton;