// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';

import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import dateUtils from 'app/shared/utils/dateUtils';
import { listingUtils_userRecentlyContactedListing } from 'app/shared/utils/listingUtils';
import Linker from 'app/shared/modules/Linker';
import numberUtils from 'app/shared/utils/numberUtils';
import pathsManifest from 'app/shared/utils/pathsManifest';
import Text from 'app/shared/core/Text';

import './style.scss';

const StyledText = styled(Text)`
    ${(props) => props.shouldUseDarkText && `color: ${colors['$hpx-grey-600']};`}
    ${(props) => props.shouldUseBoldText && `font-weight: bold;`}
`;
const formatTimestamp = (msgTime) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const date = new Date(msgTime);
    const day = date.getDay();
    const month = date.getMonth();
    const hour = date.getHours();
    const minutes = date.getMinutes();

    return `${days[day]}, ${dateUtils.monthNames[month]} ${numberUtils.getOrdinalSuffix(date.getDate())} ${hour > 12 ? hour - 12 : hour
        }:${minutes < 10 ? '0' + minutes : minutes} ${hour >= 12 ? 'PM' : 'AM'}`;
};

const SatelliteMsgStatus = (props) => {
    const dispatch = useDispatch();
    const { className } = props;
    const listing = useSelector((state) => state.currentListingDetails.currentListing) || {};
    const conversation = useSelector((state) => state.user.conversations[listing.aliasEncoded]);
    const recentlyContacted = listingUtils_userRecentlyContactedListing(listing.userItemTypes, listing.userItemDates);

    const handleMessageClick = () => {
        if (className === 'SatelliteMsgStatus-inContactBox') {
            dispatch(analyticsEvent(gaEvents.SATELLITE_CONTACT_VIEW_MSG_CLICK));
        } else {
            dispatch(analyticsEvent(gaEvents.SATELLITE_HDP_VIEW_MSG_CLICK));
        }
    };

    if (!conversation) {
        return null;
    }

    if (!recentlyContacted) {
        return null;
    }

    const { id, isUnread, lastSender, lastMessageTimestamp, satelliteEnabled } = conversation;
    let msgBody = lastSender === 'landlord' ? 'You have received a message.' : 'Your message has been sent.';

    if (!satelliteEnabled) {
        msgBody = 'This property is not using chat.';
    }

    return (
        <div className={cx('SatelliteMsgStatus', className)}>
            <StyledText
                shouldUseDarkText={lastSender === 'user'}
                shouldUseBoldText={isUnread && lastSender === 'landlord'}
                className={cx('SatelliteMsgStatus-msg')}
                htmlTag="div"
            >
                {isUnread && lastSender === 'landlord' && <div className="SatelliteMsg-unread-badge" />}
                {msgBody}
            </StyledText>
            {satelliteEnabled && (
                <Text className="SatelliteMsg-time" htmlTag="div" size="sm">
                    {formatTimestamp(lastMessageTimestamp)}
                </Text>
            )}
            {satelliteEnabled ? (
                <div className="SatelliteMsg-view-link" onClick={handleMessageClick}>
                    <Linker escape to={pathsManifest.InboxPage + `/${id}`}>
                        View message {'>'}
                    </Linker>
                </div>
            ) : (
                <Text className="SatelliteMsg-no-service" htmlTag="div" size="sm">
                    You should check your email to see if they responded to your initial inquiry.
                </Text>
            )}
        </div>
    );
};

export default SatelliteMsgStatus;
