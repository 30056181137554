// @ts-nocheck
/* eslint-enable */
// App
import React from 'react';
import { useSelector } from 'react-redux';

// Dependent Components
import MobileLinkGroup from './MobileLinkGroup';
import DesktopLinkGroup from './DesktopLinkGroup';

// Misc utils
import * as S from './styles';

const SeoFooter = ({ hideDivider = false, shortDivider, className }) => {
    // Redux
    const seoLinks = useSelector((state) => state.seo.seoFooterLinks);
    const isMobile = useSelector((state) => state.app.device.isMobile);

    return (
        <S.SeoFooterArticle className={className}>
            <S.SemanticDivider shortDivider={shortDivider} hideDivider={hideDivider} />
            <S.SeoFooterSectionList>
                {seoLinks.groups &&
                    seoLinks.groups.map((group) => {
                        const hasLinks = group.links.length > 0;

                        if (!hasLinks) {
                            return null;
                        }

                        return hasLinks && isMobile ? (
                            <MobileLinkGroup
                                header={group.header}
                                links={group.links}
                                key={`seo-footer-mobile-link-group-${group.header}`}
                            />
                        ) : (
                            <DesktopLinkGroup
                                header={group.header}
                                links={group.links}
                                key={`seo-footer-desktop-link-group-${group.header}`}
                            />
                        );
                    })}
            </S.SeoFooterSectionList>
        </S.SeoFooterArticle>
    );
};

export default SeoFooter;
