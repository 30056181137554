// @ts-nocheck
/* eslint-enable */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { yieldCallback } from '@zillow/yield-callback';
import LinkToggle from 'app/shared/modules/LinkToggle';
import HdpContentWrapper from 'app/shared/modules/hdp/HdpContentWrapper';
import ModelFloorplanItem from 'app/shared/modules/hdp/ModelFloorplanItem';
import TabGroup from 'app/shared/modules/TabGroup';
import TabItem from 'app/shared/modules/TabItem';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import { filterUtils_hasFilterChanged } from 'app/shared/utils/filterUtils';
import controller from './controller';
import './style.scss';
import { listingUtils_sortModelsByBeds } from 'app/shared/utils/listingUtils';

const StyledExpandDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

interface MultiModelsGroupProps {
    onActionPopup: () => void;
    onClickOpenFdp: () => void;
    dispatch: any;
    filter: any;
    listing: any;
}

const MultiModelsGroup = (props: MultiModelsGroupProps) => {
    const [isTruncated, setIsTruncated] = useState(true);
    const { listing, filter, onActionPopup = () => { }, onClickOpenFdp = () => { } } = props;
    const isDefaultFilter = !filterUtils_hasFilterChanged(filter);
    const sortedFloorplans = listingUtils_sortModelsByBeds(listing.floorplans);
    const tabs = controller.getTabs({ floorplans: sortedFloorplans, filter, isDefaultFilter });

    const handleViewMoreClick = yieldCallback(() => {
        setIsTruncated(!isTruncated);
        props.dispatch(
            analyticsEvent(gaEvents.SHOW_MORE_LINK_TOGGLE, {
                category: 'HDP',
                label: 'MultiModelsGroup'
            })
        );
    });

    return (
        <div className="MultiModelsGroup">
            <HdpContentWrapper active fullContent header="Apartment floor plans" headerHtmlTag="h2">
                <div className="MultiModelsGroup-container">
                    <TabGroup>
                        {tabs.map((tab) => {
                            const { floorplans, title } = tab;
                            let renderedFloorplans = isTruncated ? floorplans.slice(0, 4) : floorplans.slice(0);

                            return (
                                <TabItem tabTitle={title} key={title}>
                                    {renderedFloorplans.map((floorplan, i) => {
                                        const { modelId } = floorplan;

                                        return (
                                            <div
                                                className="MultiModelsGroup-floorplan-item"
                                                key={`${'ModelFloorplanItem-' + modelId + i}`}
                                            >
                                                <ModelFloorplanItem
                                                    floorplan={floorplan}
                                                    listing={listing}
                                                    onActionPopup={onActionPopup}
                                                    onClickOpenFdp={onClickOpenFdp}
                                                />
                                            </div>
                                        );
                                    })}
                                    {floorplans.length > 4 && (
                                        <StyledExpandDiv className="MultiModelsGroup-floorplan-expand">
                                            <LinkToggle onClick={handleViewMoreClick}>
                                                {isTruncated ? 'View more floor plans' : 'View fewer'}
                                            </LinkToggle>
                                        </StyledExpandDiv>
                                    )}
                                </TabItem>
                            );
                        })}
                    </TabGroup>
                </div>
            </HdpContentWrapper>
        </div>
    );
};

export default connect()(MultiModelsGroup);
