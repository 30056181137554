import styled from 'styled-components';
import { fontSize } from 'app/shared/styles/_fontsize';
import Text from 'app/shared/core/Text';

export const ConfirmationStatus = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    padding: 20px;
`;

export const EnvelopeIcon = styled.img`
    width: 40px;
    height: 40px;
    margin-bottom: 5px;
`;

export const Header = styled.h3`
    width: 100%;
    font-weight: bold;
    font-size: ${fontSize['$font-size-xl']};
`;

export const MessageText = styled(Text)`
    margin-bottom: 15px;
`;
