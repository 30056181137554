// @ts-nocheck
/* eslint-enable */
import cloneDeep from 'lodash/cloneDeep';
import some from 'lodash/some';
import uniqBy from 'lodash/uniqBy';
import validator from 'app/shared/utils/validator';

import { listingUtils_modelBeds, listingUtils_modelBaths } from 'app/shared/utils/listingUtils';

/**
 * Delayed contact inquiry cache
 * Temporarily stores contact details here so that
 * it can later be retrieved when a user closes the
 * Renter Profile modal after making a contact.
 */

let delayedInquiryDetails = {
    listing: null,
    inquiryParams: null,
    rentalSubmitId: null
};

const contactUtils = {
    getDelayedContactInquiry() {
        let inquiryToReturn = {
            listing: cloneDeep(delayedInquiryDetails.listing),
            inquiryParams: cloneDeep(delayedInquiryDetails.inquiryParams),
            rentalSubmitId: delayedInquiryDetails.rentalSubmitId
        };

        // Reset temporary inquiry data cache
        // after retrieving delayed contact inquiry.
        delayedInquiryDetails.listing = null;
        delayedInquiryDetails.inquiryParams = null;
        delayedInquiryDetails.rentalSubmitId = null;

        return { ...inquiryToReturn };
    },
    setDelayedContactInquiry({ listing, inquiryParams, rentalSubmitId } = {}) {
        delayedInquiryDetails.listing = cloneDeep(listing);
        delayedInquiryDetails.inquiryParams = cloneDeep(inquiryParams);
        delayedInquiryDetails.rentalSubmitId = rentalSubmitId;
    },
    shouldRequireRestrictedIncomeChoice(listing) {
        return listing.contact.restricted && some(listing.contact.restrictedIncomeByOccupants);
    },
    getInquiryText({ userMessage, lowIncomeMessage, listing }) {
        if (contactUtils.shouldRequireRestrictedIncomeChoice(listing)) {
            return `${userMessage} ${lowIncomeMessage}`;
        } else {
            return userMessage;
        }
    },
    dedupeBedsBathsOptions(models) {
        const sortedModels = models.sort((model1, model2) => {
            if (model2.beds === '8plus') {
                return -1;
            }

            if (model1.beds === model2.beds && model1.baths && model2.baths) {
                return model1.baths - model2.baths;
            }

            return model1.beds - model2.beds;
        });

        const simplifiedModels = sortedModels.map((model) => {
            return { beds: model.beds, baths: model.baths, key: contactUtils.getBedsBathsText(model) };
        });

        return uniqBy(simplifiedModels, 'key');
    },
    getBedsBathsText(model) {
        return `${listingUtils_modelBeds(model.beds)}, ${listingUtils_modelBaths(model.baths)}`;
    },
    validateInput({ inputValues, listing, restrictedConfirmation }) {
        const contact = listing.contact;
        const isStudentHousing = listing.details.studentHousing;
        const isSeniorHousing = listing.details.seniorHousing;
        const isLowIncome = listing.contact.restricted;
        const parsedNumBaths = parseInt(inputValues.numBaths);
        const parsedNumBeds = parseInt(inputValues.numBeds);

        let isValid = true;
        let newErrorsObj = {
            numBeds: null,
            numBaths: null,
            moveDate: null,
            userMessage: null,
            userEmail: null,
            userName: null,
            userPhoneNumber: null
        };
        let newFormErrorVal = null;

        // check if contact requires a message
        if (contact.requiresMessage && inputValues.userMessage && inputValues.userMessage.length === 0) {
            newErrorsObj.userMessage = 'Please enter a message';
            isValid = false;
        }

        // check if email required or user started typing something.
        if (contact.requiresEmail || inputValues.userEmail.length > 0) {
            if (!validator.email(inputValues.userEmail).isValid) {
                newErrorsObj.userEmail = validator.email(inputValues.userEmail).message;
                isValid = false;
            }
        }

        // check if user name required
        if (contact.requiresName && inputValues.userName.trim().length === 0) {
            newErrorsObj.userName = 'Please enter your name';
            isValid = false;
        }

        //check if phone number required or user has entered data into phone number input
        if (contact.requiresPhone || inputValues.userPhoneNumber.length > 0) {
            if (!validator.phoneNumber(inputValues.userPhoneNumber).isValid) {
                newErrorsObj.userPhoneNumber = validator.phoneNumber(inputValues.userPhoneNumber).message;
                isValid = false;
            }
        }

        // check if # of beds / baths required
        if (contact.requiresNumBeds) {
            if (!validator.smallNumber(parsedNumBeds).isValid) {
                newErrorsObj.numBeds = validator.smallNumber(parsedNumBeds).message;
                isValid = false;
            }

            if (!validator.smallNumber(parsedNumBaths).isValid) {
                newErrorsObj.numBaths = validator.smallNumber(parsedNumBaths).message;
                isValid = false;
            }
        }

        // Listing requires income verification and number of residents.
        if (contactUtils.shouldRequireRestrictedIncomeChoice(listing) && !inputValues.lowIncomeMessage) {
            newFormErrorVal = 'Please select resident and income range.';
            isValid = false;
        }

        // Listing is restricted and requires additional confirmation by user.
        if (
            !contactUtils.shouldRequireRestrictedIncomeChoice(listing) &&
            !restrictedConfirmation &&
            (isLowIncome || isSeniorHousing || isStudentHousing)
        ) {
            newFormErrorVal = 'Please confirm you meet the housing requirements for this listing.';
            isValid = false;
        }

        return {
            inputErrors: newErrorsObj,
            formError: newFormErrorVal,
            isValid
        };
    },
    shouldShowChatWidget(listing) {
        const lotIdsToWidgetUrls = contactUtils.getLotIdsToWidgetUrls();

        if (!listing) {
            return false;
        }

        return Object.keys(lotIdsToWidgetUrls).includes(listing.maloneLotIdEncoded);
    },
    getLotIdsToWidgetUrls() {
        const lotIdsToWidgetUrls = {
            sk8nr9: 'https://uc-widget.realpageuc.com/widget/window?wid=PRKa7nV0NGyKbe3lnV1JPA&pnw=true', // the montrose
            skf7rh: 'https://uc-widget.realpageuc.com/widget/window?wid=0uAs4ULdBHN7hO4mEg4ppg&pnw=true', // the carrington
            skdv14: 'https://uc-widget.realpageuc.com/widget/window?wid=cHoVeEk8pInZ0NkgwpUH7A&pnw=true', // the peachtree
            skdxgr: 'https://uc-widget.realpageuc.com/widget/window?wid=7pxmnTKRtLZp7mdMq1p54A&pnw=true', // the apartments at denver place
            sjymv4: 'https://uc-widget.realpageuc.com/widget/window?wid=NfDXoDp4BMJ6g9GvRAdESQ&pnw=true', // presidential towers
            sm0vre: 'https://uc-widget.realpageuc.com/widget/window?wid=ND2MVDcJBECJWQ48JQvYmw&pnw=true', // greenwood plaza
            skh5xy: 'https://uc-widget.realpageuc.com/widget/window?wid=ba1mBgCqJMbZROt7GL5qzQ&pnw=true', // villas at hermann park
            skysqk: 'https://uc-widget.realpageuc.com/widget/window?wid=AVwIODRVNJAYgkeLLqZxOQ&pnw=true', // parkside at firewheel
            //'skdv2d': '', //the pointe at suwanee station
            sm1kfg: 'https://uc-widget.realpageuc.com/widget/window?wid=eMhmKk3EJMpXsUSLeNjw&pnw=true' // hannover grand
        };

        return lotIdsToWidgetUrls;
    }
};

export default contactUtils;
