// @ts-nocheck
/* eslint-enable */
import React from 'react';
import styled from 'styled-components';
import { stacked, padding } from 'app/shared/styles/_spacing';

import 'app/shared/modules/hdp/ListingRestrictions.scss';
import Text from 'app/shared/core/Text';
import Container from 'app/shared/core/Container';
import HdpContentWrapper from 'app/shared/modules/hdp/HdpContentWrapper';

const StyledDiv = styled.div`
    ${(props) => props && props.noBorder === false && `
        ${stacked._1x};
        ${padding.top._1x};

    `};
`;
class ListingRestrictions extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { listing, noBorder, noPadding } = this.props;
        const { building, contact, details, listingType } = listing;

        let restrictionsArray = [];
        const isIncomeRestricted = !building && contact.restricted;
        const isStudentHousing = details.studentHousing;
        const isSeniorHousing = details.seniorHousing;
        const isRoom = listingType === 'room';
        const isSublet = listingType === 'sublet';
        const isFurnished = details.furnished;

        const hasRestriction =
            isIncomeRestricted || isSeniorHousing || isStudentHousing || isRoom || isSublet || isFurnished;

        if (!hasRestriction) {
            return null;
        }

        if (isRoom) {
            restrictionsArray.push('Room for rent');
        }
        if (isSublet) {
            restrictionsArray.push('Sublet');
        }

        if (isFurnished) {
            restrictionsArray.push('Furnished');
        }

        if (isIncomeRestricted) {
            restrictionsArray.push('Income restricted');
        }

        if (isSeniorHousing) {
            restrictionsArray.push('Senior housing');
        }

        if (isStudentHousing) {
            restrictionsArray.push('Student housing');
        }
        return (
            <div className="ListingRestrictions">
                <HdpContentWrapper fullContent active noBorder={noBorder} noPadding={noPadding}>
                    <Container>
                        <StyledDiv noBorder={noBorder}>
                            {hasRestriction &&
                                restrictionsArray.map((item, i) => {
                                    return (
                                        <Text size="sm" key={item + i} className="ListingRestrictions-item">
                                            {item}
                                        </Text>
                                    );
                                })}
                        </StyledDiv>
                    </Container>
                </HdpContentWrapper>
            </div>
        );
    }
}

export default ListingRestrictions;
