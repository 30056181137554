// @ts-nocheck
/* eslint-enable */
import imageUtils from 'app/shared/utils/imageUtils';
import isEmpty from 'lodash/isEmpty';
import numberUtils from 'app/shared/utils/numberUtils';

const controller = {
    checkPhotos(photos) {
        return photos.length === 0 ? imageUtils.noImageFound() : photos;
    },
    getAvailabilityDisplay({ listing = {}, unit = {} }) {
        if (!listing.active) {
            return 'N/A';
        } else if (listing.waitlisted) {
            return 'Waitlist';
        } else if (!unit.availabilityDate) {
            return 'Now';
        } else if (unit.availabilityDate) {
            const availDateArray = unit.availabilityDate.split('-');
            const month = parseInt(availDateArray[1]);
            const day = parseInt(availDateArray[2]);
            const year = parseInt(availDateArray[0]);
            return `${month}/${day}/${year}`;
        }
    },
    getPriceSummaryDisplay({ unit = {}, floorplan = {} }) {
        let low;
        let high;
        if (!isEmpty(unit)) {
            low = unit.low;
            high = unit.high;
        } else {
            low = floorplan.low;
            high = floorplan.high;
        }
        const lowDisplay = numberUtils.compact(low);
        const highDisplay = numberUtils.compact(high);
        let display = '';

        if (high > 0 && high < Infinity) {
            if (low === high) {
                display = `\$${lowDisplay}`;
            } else if (low > 0) {
                display = `\$${lowDisplay} - \$${highDisplay}`;
            } else {
                display = `Up to \$${highDisplay}`;
            }
        } else if (low > 0) {
            display = `\$${lowDisplay}+`;
        }

        return display;
    }
};

export default controller;
