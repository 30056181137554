// @ts-nocheck
/* eslint-enable */
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import Contact from 'app/shared/modules/hdp/form/Contact/Contact';
import ContactFormLite from 'app/shared/modules/hdp/form/Contact/ContactFormLite';
import Interstitial from 'app/shared/modules/hdp/ZillowApplicationsFlow/Interstitial';
import { useToolTip } from './hooks';
import { shouldShowApplyButton } from 'app/shared/utils/cometUtils';
import { TrackRentalAppPreStart } from 'app/shared/models/Clickstream/HdpClickstreamEvents';

const ZillowApplicationPopup = ({ onActionPopup, onHidePopup }) => {
    const dispatch = useDispatch();

    const listing = useSelector((state) => state.currentListingDetails.currentListing);
    const similarListings = useSelector((state) => state.fetchListing.similarListings);
    const isMobile = useSelector((state) => state.app.device.isMobile);

    const [showToolTip, toggleToolTip] = useToolTip();

    const handleTracking = useCallback(
        (event, eventOptions) => {
            dispatch(analyticsEvent(event, eventOptions));
        },
        [dispatch]
    );

    const handleInterstitialApplyClick = useCallback(() => {
        handleTracking(gaEvents.HDP_APPLICATION_INTERSTITIAL_CTA_APPLY_NOW_BY_ZILLOW, { newLaneEvent: TrackRentalAppPreStart() });
    }, [handleTracking]);

    const handleToolTipClick = useCallback(() => {
        toggleToolTip(!showToolTip);
    }, [showToolTip, toggleToolTip]);

    const handleContactFormTracking = useCallback(() => {
        handleTracking(gaEvents.HDP_APPLICATION_CTA_APPLICATION_REQUEST_SUCCESS);
    }, [handleTracking]);

    const { rentalApplicationStatus } = listing;
    const acceptsApplications = shouldShowApplyButton(rentalApplicationStatus);

    return (
        <>
            {acceptsApplications ? (
                <Interstitial
                    handleToolTipClick={handleToolTipClick}
                    showToolTip={showToolTip}
                    handleInterstitialApplyClick={handleInterstitialApplyClick}
                    propertyId={listing.aliasEncoded}
                    isMobile={isMobile}
                />
            ) : (
                <Contact
                    isPopup
                    onShowSuccessModal={onActionPopup}
                    onHidePopup={onHidePopup}
                    listing={listing}
                    similarListings={similarListings}
                    customForm={ContactFormLite}
                    customTracking={handleContactFormTracking}
                />
            )}
        </>
    );
};

export default ZillowApplicationPopup;
