// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { useSelector } from 'react-redux';
import Text from 'app/shared/core/Text';
import problemCircleFill from 'images/icons/problem-circle-fill.svg';
import styled from 'styled-components';
import { font } from 'app/shared/styles/_fontsize';
import { TOUR_TYPES } from '../../constants';

const NoToursAvailableHeader = styled.h2`
    ${font.hero}
    padding: 10px 0px;
`;

const NoToursAvailableError = () => {
    const { tourType: selectedTourType } = useSelector((state) => state.currentListingDetails.instantTourForm);
    let tourTypeToDisplay = '';
    if (selectedTourType === TOUR_TYPES.IN_PERSON) {
        tourTypeToDisplay = 'in-person';
    } else if (selectedTourType === TOUR_TYPES.SELF_GUIDED) {
        tourTypeToDisplay = 'self-guided';
    } else if (selectedTourType === TOUR_TYPES.LIVE_VIDEO) {
        tourTypeToDisplay = 'live video';
    }

    return (
        <div style={{ padding: '10px' }} aria-live="assertive">
            <img alt="" height={'35px'} width={'35px'} src={problemCircleFill} />
            <NoToursAvailableHeader>
                There are no {tourTypeToDisplay} tours available at this time.
            </NoToursAvailableHeader>
            <Text>
                New tours may become available if someone cancels their reservation, or if the property adds additional
                slots. Please check back later.
            </Text>
        </div>
    );
};

export default NoToursAvailableError;
