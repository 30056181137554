// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import filter from 'lodash/filter';

import FeaturedListingsGroup from 'app/shared/modules/hdp/FeaturedListingsGroup';
import UserItemActions from 'app/shared/flux/actions/UserItemActions';
import { impression } from 'app/client/zg-analytics';
import { analyticsEvent } from 'app/client/universal-analytics';
import { TrackImpressionForPropertyCard } from 'app/shared/models/Clickstream/ListingCardClickstreamEvents';

class RecentlyViewed extends React.Component {
    componentDidMount() {
        this.props.dispatch(UserItemActions.getUserItems('viewed', 0, 5));
    }

    trackImpression = (listing) => {
        const { currentListing, dispatch } = this.props;
        const { aliasEncoded } = listing;

        impression({
            category: 'RentalHDP',
            label: 'recentlyViewedListingsModule',
            dimension5: currentListing.aliasEncoded,
            dimension60: aliasEncoded
        });

        dispatch(analyticsEvent({ newLaneEvent: TrackImpressionForPropertyCard({ listing, triggerLocation: 'recently_viewed_listings_module' }) }));

    };

    render() {
        if (this.props.viewedListings.length === 0) {
            return null;
        }

        return (
            <div className="RecentlyViewed">
                <FeaturedListingsGroup
                    header="Recently viewed homes"
                    listings={this.props.viewedListings}
                    onListingClick={this.props.onListingClick}
                    trackImpression={this.trackImpression}
                    triggerLocation={'home_details'}
                    triggerObject={'home_details_component|recently_viewed'}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentListing: state.currentListingDetails.currentListing,
        viewedListings: filter(state.listings.listingGroups.viewed.slice(0, 5), (listing) => {
            if (
                state.location.current.pathname === listing.uriMalone ||
                state.location.current.pathname === listing.uriBuilding ||
                state.location.current.pathname === listing.uriV2
            ) {
                return false; // filter out the current listing/building
            } else {
                return true;
            }
        })
    };
};

RecentlyViewed = connect(mapStateToProps)(RecentlyViewed);
export default RecentlyViewed;
