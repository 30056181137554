// @ts-nocheck
/* eslint-enable */
import { isPasswordMixed } from 'app/shared/utils/passwordUtils';

const EMAIL_LOCAL_PATTERN = '^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))';
const DOMAIN_PATTERN = '((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])';
const DOMAIN_ALTERNATIVE_PATTERN = '(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))';
const EMAIL_TEST = new RegExp(`${EMAIL_LOCAL_PATTERN}@${DOMAIN_PATTERN}|${DOMAIN_ALTERNATIVE_PATTERN}$`);

var validator = {
    email(email) {
        if (!email || !EMAIL_TEST.test(email)) {
            return {
                message: 'Please enter a valid email address.',
                isValid: false
            };
        }
        if (email.length > 115) {
            return {
                message: 'Cannot exceed 115 characters.',
                isValid: false
            };
        }

        return {
            isValid: true
        };
    },
    httpUrl(url) {
        var re = /^https?:\/\//;

        return {
            isValid: re.test(url)
        };
    },
    firstOrLastName(name) {
        if (name.length > 20) {
            return {
                message: 'Cannot exceed 20 characters.',
                isValid: false
            };
        } else {
            return {
                isValid: true
            };
        }
    },
    password(password) {
        if (password.length > 100) {
            return {
                message: 'Cannot exceed 100 characters.',
                isValid: false
            };
        } else if (password.length < 6) {
            return {
                message: 'Must be 6 characters or longer.',
                isValid: false
            };
        } else if (/[\s]/.test(password)) {
            return {
                message: 'Cannot contain spaces.',
                isValid: false
            };
        } else {
            return {
                isValid: true
            };
        }
    },
    passwordLogin(password) {
        if (!password || password.length === 0) {
            return {
                message: 'Please enter a password.',
                isValid: false
            };
        } else {
            return {
                isValid: true
            };
        }
    },
    freeForm(password) {
        if (password.length >= 150) {
            return {
                message: 'Cannot exceed 100 characters.',
                isValid: false
            };
        } else {
            return {
                isValid: true
            };
        }
    },
    passwordMatch(pw1, pw2) {
        if (!pw1) {
            return {
                isValid: false,
                message: 'Must enter password.'
            };
        } else if (!pw2) {
            return {
                isValid: false,
                message: 'Must re-enter password.'
            };
        } else if (pw1.length < 8 || pw2.length < 8) {
            return {
                isValid: false,
                message: 'Password must be 8 characters or longer.'
            };
        } else if (pw1 !== pw2) {
            return {
                isValid: false,
                message: 'Passwords must match!'
            };
        } else {
            return {
                isValid: true
            };
        }
    },
    phoneNumber(number) {
        var re = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;

        if (re.test(number)) {
            return {
                isValid: true
            };
        } else {
            return {
                isValid: false,
                message: 'Enter a valid phone number.'
            };
        }
    },
    smallNumber(val) {
        const isNumber = typeof val === 'number' && !isNaN(val);
        if (isNumber && val < 100 && val >= 0) {
            return {
                isValid: true
            };
        } else {
            return {
                isValid: false,
                message: 'Enter a valid number.'
            };
        }
    },
    date(string) {
        var dateRegExp = /\d{1,2}\/\d{1,2}\/\d{4}/;

        if (string) {
            if (string.match(dateRegExp)) {
                return {
                    isValid: true
                };
            }
        }
        return {
            isValid: false,
            message: 'Enter a valid date - MM/DD/YYYY'
        };
    },

    strongPassword(password = '') {
        const isStrong = isPasswordMixed(password) && password.length >= 8 && password.length < 128;

        return {
            isValid: isStrong,
            message: isStrong ? '' : 'Please set a strong password'
        };
    }
};

export default validator;
