import styled from 'styled-components';
import Text from 'app/shared/core/Text';

export const SchedulingErrorStepContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    align-items: center;
`;

export const Header = styled(Text)`
    font-weight: bold;
    align-self: flex-start;
`;
