import React from 'react';
import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';

const StyledSkeletonLine = styled.div`
    width: 100%;
    background: ${colors['$hpx-grey-100']};
    height: 10px;
`;

interface SkeletonLineProps {
    width?: string;
}

const SkeletonLine: React.FC<SkeletonLineProps> = ({ width = '100%' }) => {
    return <StyledSkeletonLine style={{ width }} />;
};

export default SkeletonLine;