// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import Center from 'app/shared/modules/Center';
import Row from 'app/shared/core/Row';
import Title from 'app/shared/modules/Title';

class PromptEmailSent extends Component {
    static propTypes = {
        email: PropTypes.string
    };

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="PromptEmailSent">
                <Center>
                    <Title size="lg">Check your email!</Title>
                    <div className="modal-body">
                        <Row>We sent an email to </Row>
                        <div className="freepass-email"> {this.props.email} </div>
                        <Row>Open the message and click on the link to sign in.</Row>
                    </div>
                </Center>
            </div>
        );
    }
}

export default PromptEmailSent;
