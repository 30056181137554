// @ts-nocheck
/* eslint-enable */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Text from 'app/shared/core/Text';
import Radio from 'app/shared/core/Radio';
import DateTimePicker from '../DateTimePicker';
import TourStatusModule from 'app/shared/modules/hdp/InstantTour/components/TourStatusModule';
import ListingEngineActions from 'app/shared/flux/actions/ListingEngineActions';
import { getScheduledTour } from 'app/shared/utils/listingUtils';
import { TOUR_TYPES } from '../../constants';
import inPersonTourIcon from 'images/icons/in-person-tour.svg';
import selfGuideTourIcon from 'images/icons/self-guided-tour.svg';
import liveVideoTourIcon from 'images/icons/live-video-tour.svg';
import * as S from './styles';
import SpinnerDotsV2 from 'app/shared/modules/SpinnerDotsV2';
import { useInstantTourForm } from '../../hooks';

const ModifyTourStep = ({ setIsPrimaryBtnDisabled = () => { } }) => {
    const dispatch = useDispatch();
    const { updateInstantTourForm } = useInstantTourForm();
    const isMobile = useSelector((state) => state.app.device.isMobile);
    const currentListing = useSelector((state) => state.currentListingDetails.currentListing);
    const { availableToursMap } = useSelector((state) => state.currentListingDetails.availableToursForListing);
    const noToursAvailable = Object.keys(availableToursMap).length === 0;
    const { aliasEncoded, details } = currentListing;
    const { inPersonProvider, liveVideoProvider, selfGuidedProvider } = details.instantTourProviders ?? {};
    const { tourType: selectedTourType } = useSelector((state) => state.currentListingDetails.instantTourForm);
    const [isAvailTimesReqLoading, setIsAvailTimesReqLoading] = useState(false);
    const scheduledToursForUser = useSelector((state) => state.user.scheduledTours);
    const { startTime: scheduledDateWithOrWithoutTime, tourType: scheduledTourType } = getScheduledTour(
        aliasEncoded,
        scheduledToursForUser
    );

    const handleTourTypeSelect = useCallback(
        (newTourType) => {
            setIsAvailTimesReqLoading(true);
            if (selectedTourType !== newTourType) {
                updateInstantTourForm({
                    tourType: newTourType,
                    selectedDate: '',
                    selectedDateTime: ''
                });
                dispatch(
                    ListingEngineActions.hpTours.fetchAvailableToursForListing(
                        aliasEncoded,
                        newTourType,
                        scheduledDateWithOrWithoutTime
                    )
                ).finally(() => {
                    setIsAvailTimesReqLoading(false);
                });
            }
        },
        [aliasEncoded, dispatch, scheduledDateWithOrWithoutTime, selectedTourType, updateInstantTourForm]
    );

    useEffect(() => {
        updateInstantTourForm({
            tourType: scheduledTourType
        });

        if (noToursAvailable) {
            handleTourTypeSelect(scheduledTourType);
        }

        // We only want this useEffect to run on initial render:
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleInPersonTypeSelect = useCallback(() => {
        handleTourTypeSelect(TOUR_TYPES.IN_PERSON);
    }, [handleTourTypeSelect]);

    const handleSelfGuidedTypeSelect = useCallback(() => {
        handleTourTypeSelect(TOUR_TYPES.SELF_GUIDED);
    }, [handleTourTypeSelect]);

    const handleLiveVideoTypeSelect = useCallback(() => {
        handleTourTypeSelect(TOUR_TYPES.LIVE_VIDEO);
    }, [handleTourTypeSelect]);

    const getTourIconAndLabel = (tourTypeToLabel = TOUR_TYPES.IN_PERSON, tourTypeIsAvailable = true) => (
        <S.TourIconAndLabel onClick={tourTypeIsAvailable ? () => handleTourTypeSelect(tourTypeToLabel) : null}>
            <S.IconAndTitle>
                {tourTypeToLabel === TOUR_TYPES.IN_PERSON && <S.LabelIcon alt="" src={inPersonTourIcon} />}
                {tourTypeToLabel === TOUR_TYPES.SELF_GUIDED && <S.LabelIcon alt="" src={selfGuideTourIcon} />}
                {tourTypeToLabel === TOUR_TYPES.LIVE_VIDEO && <S.LabelIcon alt="" src={liveVideoTourIcon} />}
                <Text size="md">
                    {tourTypeToLabel === TOUR_TYPES.IN_PERSON && <>In-person tour</>}
                    {tourTypeToLabel === TOUR_TYPES.SELF_GUIDED && <>Self-guided tour</>}
                    {tourTypeToLabel === TOUR_TYPES.LIVE_VIDEO && <>Live video tour</>}
                </Text>
            </S.IconAndTitle>
            <Text style={{ maxWidth: isMobile ? '300px' : 'none' }} size="md" htmlTag="p">
                {tourTypeToLabel === TOUR_TYPES.IN_PERSON && <>Tour on site with the property manager guiding you</>}
                {tourTypeToLabel === TOUR_TYPES.SELF_GUIDED && <>Tour on site at your own pace.</>}
                {tourTypeToLabel === TOUR_TYPES.LIVE_VIDEO && (
                    <>Video chat from home with the property manager guiding you.</>
                )}
            </Text>
        </S.TourIconAndLabel>
    );

    return (
        <div>
            <TourStatusModule setIsPrimaryBtnDisabled={setIsPrimaryBtnDisabled} />
            <S.GreyDivider />
            <S.EditTourContainer>
                <S.Header>Type of Tour</S.Header>

                <S.RadiosContainer role="radiogroup">
                    {inPersonProvider && (
                        <S.ContainerItem>
                            <Radio
                                ariaLabel="In-person tour"
                                name="TourTypeOption"
                                id="InPersonTourType"
                                isChecked={selectedTourType === TOUR_TYPES.IN_PERSON}
                                onChange={handleInPersonTypeSelect}
                                value={TOUR_TYPES.IN_PERSON}
                            >
                                {getTourIconAndLabel(TOUR_TYPES.IN_PERSON)}
                            </Radio>
                        </S.ContainerItem>
                    )}
                    {selfGuidedProvider && (
                        <S.ContainerItem>
                            <Radio
                                ariaLabel="Self-guided tour"
                                name="TourTypeOption"
                                id="SelfGuidedTourType"
                                isChecked={selectedTourType === TOUR_TYPES.SELF_GUIDED}
                                onChange={handleSelfGuidedTypeSelect}
                                value={TOUR_TYPES.SELF_GUIDED}
                            >
                                {getTourIconAndLabel(TOUR_TYPES.SELF_GUIDED)}
                            </Radio>
                        </S.ContainerItem>
                    )}
                    {liveVideoProvider && (
                        <S.ContainerItem>
                            <Radio
                                ariaLabel="Live video tour"
                                name="TourTypeOption"
                                id="LiveVideoTourType"
                                isChecked={selectedTourType === TOUR_TYPES.LIVE_VIDEO}
                                onChange={handleLiveVideoTypeSelect}
                                value={TOUR_TYPES.LIVE_VIDEO}
                            >
                                {getTourIconAndLabel(TOUR_TYPES.LIVE_VIDEO)}
                            </Radio>
                        </S.ContainerItem>
                    )}
                </S.RadiosContainer>
                {isAvailTimesReqLoading ? (
                    <SpinnerDotsV2 />
                ) : (
                    <DateTimePicker setIsPrimaryBtnDisabled={setIsPrimaryBtnDisabled} />
                )}
            </S.EditTourContainer>
        </div>
    );
};

export default ModifyTourStep;
