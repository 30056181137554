// @ts-nocheck
/* eslint-enable */
// App
import React from 'react';
import styled from 'styled-components';
import { margin, stacked } from 'app/shared/styles/_spacing';

// Components
import Button from 'app/shared/core/Button';
import Popover from 'app/shared/modules/popups/Popover';
import PoweredByZillowBadge from 'app/shared/modules/PoweredByZillowBadge';
import Text from 'app/shared/core/Text';
import Title from 'app/shared/modules/Title';

// Misc / Utils
import Illustration from 'images/applications-group-illustration.svg';
import IconQuestionMark from 'images/icons/question-mark-dark.svg';
import pathsManifest from 'app/shared/utils/pathsManifest';
import { v4 as uuidv4 } from 'uuid';
import './style.scss';

const MODAL_VERBIAGE = {
    title: 'Rental applications',
    body: 'You’re heading to Zillow to complete your application. Apply to all participating rentals for 30 days with one simple form — it’s just $35.'
};
const TOOL_TIP_VERBIAGE = 'HotPads and Zillow are part of the Zillow Group.';
const APPLY_VERBIAGE = 'Apply on Zillow';

const StyledImg = styled.img`
    ${margin.left._xs};
`;
const StyledInterstitialHeader = styled.header`
    ${stacked._4x};
`;
const StyledInterstitialTextSection = styled.section`
    ${stacked._8x};
`;
const StyledIllustrationGroupSection = styled.section`
    ${stacked._3x};
`;
const StyledInterstitialTitle = styled(Title)`
    ${stacked._1x};
`;
const Interstitial = ({
    handleToolTipClick,
    showToolTip,
    handleInterstitialApplyClick,
    propertyId,
    isMobile
}) => {
    const IconQuestion = <StyledImg src={IconQuestionMark} alt="" onClick={handleToolTipClick} height="14px" width="14px" />;
    const IllustrationGroup = <img src={Illustration} alt="" />;

    return (
        <>
            {showToolTip && (
                <Popover
                    className="ZillowApplicationsFlow-interstitial-tooltip-popover"
                    onHidePopup={handleToolTipClick}
                >
                    <Text htmlTag="div" size="md" className="ZillowApplicationsFlow-interstitial-tooltip">
                        {TOOL_TIP_VERBIAGE}
                    </Text>
                </Popover>
            )}
            <article className="ZillowApplicationsFlow-interstitial-container" role="presentation">
                <StyledIllustrationGroupSection className="ZillowApplicationsFlow-interstitial-illustration-container">
                    {IllustrationGroup}
                </StyledIllustrationGroupSection>
                <StyledInterstitialHeader className="ZillowApplicationsFlow-interstitial-heading" role="presentation">
                    <StyledInterstitialTitle size="lg">
                        {MODAL_VERBIAGE.title}
                    </StyledInterstitialTitle>
                    <div>
                        <PoweredByZillowBadge />
                        {IconQuestion}
                    </div>
                </StyledInterstitialHeader>

                <StyledInterstitialTextSection className="ZillowApplicationsFlow-interstitial-body">
                    <Text size="md">{MODAL_VERBIAGE.body}</Text>
                </StyledInterstitialTextSection>
                <section className="ZillowApplicationsFlow-interstitial-button-container">
                    <Button
                        className="ZillowApplicationsFlow-interstitial-button"
                        size="lg"
                        htmlTag="a"
                        target="_blank"
                        href={pathsManifest.ZillowApplicationsFlow(propertyId, {
                            medium: isMobile ? 'mweb' : 'web',
                            appId: uuidv4()
                        })}
                        onClick={handleInterstitialApplyClick}
                        style={{ display: 'block', margin: '0 auto', padding: '15px' }}
                    >
                        {APPLY_VERBIAGE}
                    </Button>
                </section>
            </article>
        </>
    );
};

export default Interstitial;
