// @ts-nocheck
/* eslint-enable */
import React, { useEffect, useRef, useState } from 'react';

// Components
import Carousel from 'app/shared/modules/CarouselV2';
import ZrmUpsellItems from './Items';

import * as S from './styles';

const areEqual = (prevProps, nextProps) => {
    if (JSON.stringify(prevProps) === JSON.stringify(nextProps)) {
        return true;
    }

    return false;
};

const UpsellCarouselModule = () => {
    const containerRef = useRef(null);
    const [width, setWidth] = useState(0);
    const AUTO_SCROLL_SPEED = 20 * 1000;
    const AUTO_SCROLL_PAUSE_DURATION = 40 * 1000;

    const updateWidth = () => {
        const containerObj = (containerRef && containerRef.current) || {
            offsetWidth: 1220
        };

        setWidth(containerObj.offsetWidth);
    };

    useEffect(() => {
        updateWidth();
        window.addEventListener('resize', updateWidth);

        return () => {
            window.removeEventListener('resize', updateWidth);
        };
    }, []);

    return (
        <S.Container ref={containerRef}>
            <Carousel
                width={width}
                items={ZrmUpsellItems()}
                autoScrollSpeed={AUTO_SCROLL_SPEED}
                autoScrollPauseDuration={AUTO_SCROLL_PAUSE_DURATION}
            />
        </S.Container>
    );
};

export default React.memo(UpsellCarouselModule, areEqual);
