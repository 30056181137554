// @ts-nocheck
/* eslint-enable */
import StaticMap from './StaticMap';

// TODO: We should transpile theme options from gmapTheme()
// since they are formatted differently between interactive
// map and static map. For now, we have to manually transpile
// theme parameters. See: 'app/client/utils/map/gmapTheme';

/**
 * Make a simple static map for use as a background image.
 * @param {object} options - @see StaticMap
 * @returns {string} encoded static map url
 * TODO: Add encoded outline? Marker?
 */
const getMapBackground = function getMapBackground(options = {}) {
    return function(dispatch) {
        let staticMap = new StaticMap(options);

        staticMap
            .size(options.width, options.height)
            .zoom(options.zoom)
            .scale(options.scale)
            .center(options.lat, options.lon)
            .style({
                feature: 'water',
                element: 'all',
                color: '0x9dced1',
                visibility: 'on'
            })
            .style({
                feature: 'landscape',
                element: 'all',
                visibility: 'simplified'
            })
            .style({
                feature: 'poi',
                element: 'all',
                color: '0xEDEBDF',
                visibility: 'simplified'
            })
            .style({
                feature: 'road',
                element: 'all',
                saturation: '-100',
                lightness: '50'
            })
            .style({
                feature: 'road.highway',
                element: 'all',
                visibility: 'simplified'
            })
            .style({
                feature: 'road.highway.controlled_access',
                element: 'geometry.fill',
                color: '0xFFE7C5'
            })
            .style({
                feature: 'poi.park',
                element: 'all',
                color: '0xd8ebd2'
            })
            .style({
                feature: 'poi',
                element: 'labels',
                visibility: 'off'
            })
            .style({
                feature: 'transit',
                element: 'all',
                visibility: 'on'
            })
            .style({
                feature: 'administrative',
                element: 'labels.text.fill',
                color: '888888'
            });

        if (options.markers) {
            staticMap.markers(options.markers);
        }

        if (options.path) {
            staticMap.path(options.path);
        }

        if (options.polyline) {
            staticMap.polyline(options.polyline);
        }

        return dispatch(staticMap.generate());
    };
};

export default getMapBackground;
