// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import some from 'lodash/some';
import forEach from 'lodash/forEach';
import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';

import './ContactSuccess.scss';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import Animation from 'app/shared/modules/Animation';
import animationData from 'images/animations/contactSuccessAnimation.json';
import AppActions from 'app/shared/flux/actions/AppActions';
import Button from 'app/shared/core/Button';
import Center from 'app/shared/modules/Center';
import constants from 'app/shared/constants/ConstantsBundle';
import ContactSimilarListings from 'app/shared/modules/hdp/form/Contact/ContactSimilarListings';
import Container from 'app/shared/core/Container';
import IconCheckmark from 'images/icons/checkmark.svg';
import Linker from 'app/shared/modules/Linker';
import NotificationActions from 'app/shared/flux/actions/NotificationActions';
import pathsManifest from 'app/shared/utils/pathsManifest';
import Row from 'app/shared/core/Row';
import Text from 'app/shared/core/Text';
import Title from 'app/shared/modules/Title';
import userUtils from 'app/shared/utils/userUtils';

const clientToApiDevices = [
    constants.NOTIFICATION_DEVICE_EMAIL,
    constants.NOTIFICATION_DEVICE_MOBILE
];
const StyledDarkText = styled(Text)`
    color: ${colors['$hpx-grey-600']};
`;
class ContactSuccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            optInRecSearch: false
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(NotificationActions.notifications.getSettings());
        dispatch(analyticsEvent(gaEvents.CONTACT_SUCCESS));
    }

    handleOptInRecSearch = () => {
        if (this.state.optInRecSearch) {
            return null;
        }

        let { dispatch, subscriptions, userInfo } = this.props;
        let notificationOptions = [];
        let hasActiveSubscriptions = userUtils.hasActiveSubscriptions(subscriptions);
        let promises = [];

        if (!hasActiveSubscriptions) {
            let updateOptions = {
                stopListings: false,
                stopOther: false
            };
            promises.push(dispatch(NotificationActions.email.updateUserSubscriptions(updateOptions, userInfo.email)));
        }

        forEach(clientToApiDevices, (deviceConstant) => {
            notificationOptions.push({
                deviceGroup: deviceConstant,
                optedOut: false,
                typeGroup: constants.NOTIFICATION_REC_SEARCH
            });
        });
        promises.push(dispatch(NotificationActions.notifications.updateSettings(notificationOptions)));
        Promise.all(promises).then(() => {
            dispatch(analyticsEvent(gaEvents.OPT_IN_REC_SEARCH));
            this.setState({
                optInRecSearch: true
            });
        });
    };

    handleSignIn = () => {
        const { dispatch } = this.props;
        dispatch(AppActions.showPopupModal('PromptLogin'));
        this.props.onHidePopup();
    };

    render() {
        const { isPopup, recSearchPreferences, userExists } = this.props;
        const hasSimilarListings = this.props.similarListings.length > 0;

        return (
            <div className="ContactSuccessModal">
                <Row>
                    <Container>
                        <Animation className="ContactSuccess-img" animationData={animationData} playTimer={500} />
                        <Title id="dialog-title" className="ContactSuccess-title" size="lg">
                            Message sent!
                        </Title>
                    </Container>
                </Row>
                {!some(recSearchPreferences) && (
                    <div>
                        <Row>Should we email you similar listings?</Row>
                        {this.state.optInRecSearch ? (
                            <Button btnType="primary-inactive" size="lg">
                                <img src={IconCheckmark} className="ContactSuccess-check" />
                                <Text className="ContactSuccess-text">You are now subscribed.</Text>
                            </Button>
                        ) : (
                            <Button onClick={this.handleOptInRecSearch} btnType="primary-outline" size="lg">
                                <b>Yes, subscribe me!</b>
                            </Button>
                        )}

                        <Row>
                            <StyledDarkText size="sm">
                                You may{' '}
                                <Linker className="ContactSuccess-link" to={pathsManifest.UserSettingsPage}>
                                    adjust your email preferences
                                </Linker>{' '}
                                at any time.
                            </StyledDarkText>
                        </Row>
                    </div>
                )}
                {userExists && (
                    <Row>
                        <Container>
                            <Row>Sign in to add favorites, view contacted properties, and more!</Row>
                            <Button onClick={this.handleSignIn} btnType="primary-outline" size="lg">
                                <b>Sign in</b>
                            </Button>
                        </Container>
                    </Row>
                )}
                {hasSimilarListings && (
                    <ContactSimilarListings similarListings={this.props.similarListings} isPopup={isPopup} />
                )}
                {!hasSimilarListings && isPopup && (
                    <Container>
                        <Row>
                            <Center>
                                <Button full size="lg" btnType="primary" onClick={this.props.onHidePopup}>
                                    Awesome!
                                </Button>
                            </Center>
                        </Row>
                    </Container>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    userInfo: state.user.info,
    recSearchPreferences: state.user.recSearchPreferences,
    subscriptions: state.user.subscriptions
});

export default connect(mapStateToProps)(ContactSuccess);
