// @ts-nocheck
/* eslint-enable */
import React from 'react';
import cx from 'classnames';
import map from 'lodash/map';
import { useYieldCallback } from '@zillow/yield-callback';

import controller from './controller';
import DisableOverlay from 'app/shared/modules/DisableOverlay';
import './style.scss';

const INVALID_COMMUTE_TIME = '2+ hr';
const NO_COMMUTE_DATA = '—';

const CommuteTimesV2 = (props = {}) => {
    const { gmapLinks = {}, hideLinks = false, onClick = () => { }, travelTime = {}, userPoint } = props;
    const transitDetails = controller.getTransitDetails({ gmapLinks, travelTime });

    const yieldOnClick = useYieldCallback((details) => {
        onClick({ mode: details.modeTitle, focusInput: false });
    });

    const handleTransitModeClick = (details) => {
        if (details.disableClick) {
            return;
        }

        yieldOnClick(details);
    };

    return (
        <div className="CommuteTimesV2">
            {!userPoint && <DisableOverlay />}
            <ul>
                {map(transitDetails, (details = {}) => {
                    return (
                        <li
                            className={cx('CommuteTimesV2-transit-container', {
                                'CommuteTimesV2-active':
                                    details.time !== INVALID_COMMUTE_TIME && details.modeTitle === props.activeRouteMode,
                                'CommuteTimesV2-disabled': details.time === INVALID_COMMUTE_TIME
                            })}
                            key={details.title}
                        >
                            {details.time === INVALID_COMMUTE_TIME && <DisableOverlay />}
                            <button className="CommuteTimesV2-transit-info" onClick={() => handleTransitModeClick(details)}>
                                <div
                                    className={cx('CommuteTimesV2-icon-container', {
                                        'CommuteTimesV2-icon-container-active': details.modeTitle === props.activeRouteMode
                                    })}
                                >
                                    <img className="CommuteTimesV2-icon" src={details.icon} alt="" />
                                </div>
                                <div className="CommuteTimesV2-transit-mode-time">
                                    {details.time}
                                    <br />
                                    {hideLinks || details.disableClick || details.time === NO_COMMUTE_DATA ? (
                                        <span>{details.title}</span>
                                    ) : (
                                        <span>{details.title}</span>
                                    )}
                                </div>
                            </button>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default CommuteTimesV2;
