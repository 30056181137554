// @ts-nocheck
/* eslint-enable */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import constants from 'app/shared/constants/ConstantsBundle';
import UserRoleRequired from 'app/shared/modules/UserRoleRequired';
import IconReport from 'images/icons/report.svg';
import IconTrash from 'images/icons/trash.svg';
import IconTools from 'images/icons/auction.svg';
import * as S from 'app/shared/modules/hdp/ListingNavBarMenu/styles';

const ListingNavBarMenu = ({
    isListingHidden = false,
    listing = {},
    onActionClick = () => { },
    onToggleHide = () => { },
    onToggleOpen = () => { },
    isExp = false
}) => {
    // React
    const dropdownRef = useRef(null);

    const handleClickAway = (e) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(e.target) &&
            !document.getElementById('ListingNavBar-menu').contains(e.target)
        ) {
            onToggleOpen();
        }
    };

    const handleBlur = (e) => {
        // Prevent firing the onBlur event if any one of its children receives focus
        if (!e.currentTarget.contains(e.relatedTarget)) {
            onToggleOpen();
        }
    };

    const handleReportClick = () => {
        onActionClick('report');
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickAway);
        return () => {
            document.removeEventListener('mousedown', handleClickAway);
        };
    }, []);

    return (
        /* HPWEB-6117: HDP-update experiment dependant */
        <S.MenuDropdown onBlur={handleBlur} ref={dropdownRef} isExp={isExp}>
            <S.MenuItem>
                <S.Button onClick={onToggleHide}>
                    <S.Icon src={IconTrash} alt="" width="16px" height="16px" fetchpriority="low" decoding="async" />
                    <S.Text size="sm">{isListingHidden ? 'Unhide' : 'Hide'}</S.Text>
                </S.Button>
            </S.MenuItem>
            <S.MenuItem>
                <S.Button onClick={handleReportClick}>
                    <S.Icon src={IconReport} alt="" width="16px" height="16px" fetchpriority="low" decoding="async" />
                    <S.Text size="sm">Report</S.Text>
                </S.Button>
            </S.MenuItem>
            <UserRoleRequired rep>
                <S.MenuItem>
                    <S.Button onClick={() => window.open(constants.REP_HDP_URI + listing.aliasEncoded, '_blank')}>
                        <S.Icon src={IconTools} alt="" width="16px" height="16px" />
                        <S.Text size="sm">Listing tools</S.Text>
                    </S.Button>
                </S.MenuItem>
            </UserRoleRequired>
        </S.MenuDropdown>
    );
};

ListingNavBarMenu.propTypes = {
    isListingHidden: PropTypes.bool,
    onActionClick: PropTypes.func,
    onToggleHide: PropTypes.func,
    onToggleOpen: PropTypes.func
};

export default ListingNavBarMenu;
