// @ts-nocheck
/* eslint-enable */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import AppActions from 'app/shared/flux/actions/AppActions';

// Components
import ContactSuccess from 'app/shared/modules/hdp/form/Contact/ContactSuccess';
import Contact from 'app/shared/modules/hdp/form/Contact/Contact';
import ErrorModal from 'app/shared/modules/ErrorModal';
import HdpReviewsModal from 'app/shared/modules/reviews/HdpReviewsModal';
import PopupModal from 'app/shared/modules/popups/PopupModal';
import PostContactRenterProfile from 'app/shared/modules/popups/PostContactRenterProfile';
import PostContactSavedSearch from 'app/shared/modules/PostContactSavedSearch';
import Report from 'app/shared/modules/hdp/form/Report';
import WaitlistSuccessPopup from 'app/shared/modules/hdp/form/WaitlistSuccessPopup';
import InstantTour from 'app/shared/modules/hdp/InstantTour';
import ZillowApplicationPopup from 'app/shared/modules/hdp/ZillowApplicationsPopup';

// Misc
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';

const ActionPopupWrapper = (props = {}) => {
    const dispatch = useDispatch();
    const listing = useSelector((state) => state.currentListingDetails.currentListing) || null;
    const similarListings = useSelector((state) => state.fetchListing.similarListings) || [];
    const userExists = useSelector((state) => state.app.userExists);
    const isMobile = useSelector(
        (state) => state.app.device.screenWidth === 'sm' || state.app.device.screenWidth === 'xs'
    );
    const isWebView = useSelector((state) => state.location.ssrEntry.query.instantTour);

    const { componentName = '', onActionPopup = () => { }, onHidePopup = () => { }, unitDetails = {} } = props;

    const postContactRenterProfileClose = useCallback(() => {
        dispatch(analyticsEvent(gaEvents.RENTER_PROFILE_POST_CONTACT_CLOSE));
        onHidePopup();
    }, [dispatch, onHidePopup]);

    const postContactSavedSearchClose = useCallback(() => {
        dispatch(AppActions.setAppStoreBool('hasShownPostContactSavedSearch', true));
        onHidePopup();
    }, [dispatch, onHidePopup]);

    if (!listing) {
        return null;
    }

    if (componentName === 'BookTourNowButton' || componentName === 'ModifyTourCTA') {
        return (
            <PopupModal
                aria-labelledby="book-tour-now-title"
                onHidePopup={onHidePopup}
                paddingBottom="0"
                paddingTop="0"
                useCloseButton={!isWebView}
            >
                <InstantTour
                    listing={listing}
                    isModifyingTour={componentName === 'ModifyTourCTA'}
                    onActionPopup={onActionPopup}
                    onHidePopup={onHidePopup}
                />
            </PopupModal>
        );
    } else if (componentName === 'contact' || componentName === 'contactTourStatusModule') {
        return (
            <PopupModal aria-labelledby="dialog-title" onHidePopup={onHidePopup} paddingBottom="0" paddingTop="0">
                <Contact
                    isPopup
                    onShowSuccessModal={onActionPopup}
                    onHidePopup={onHidePopup}
                    listing={listing}
                    similarListings={similarListings}
                    unitDetails={unitDetails}
                />
            </PopupModal>
        );
    } else if (componentName === 'contactSuccess') {
        return (
            <PopupModal aria-labelledby="dialog-title" onHidePopup={onHidePopup} paddingTop="0">
                <ContactSuccess
                    isPopup
                    listing={listing}
                    onHidePopup={onHidePopup}
                    similarListings={similarListings}
                    userExists={userExists}
                />
            </PopupModal>
        );
    } else if (componentName === 'postContactRenterProfile') {
        return (
            <PopupModal
                aria-labelledby="dialog-title"
                onHidePopup={postContactRenterProfileClose}
                scrollContainerOverride
            >
                <PostContactRenterProfile onHidePopup={onHidePopup} />
            </PopupModal>
        );
    } else if (componentName === 'postContactSavedSearch') {
        return (
            <PopupModal
                aria-labelledby="dialog-title"
                onHidePopup={postContactSavedSearchClose}
                paddingInline={isMobile ? '2x' : '4x'}
            >
                <PostContactSavedSearch onHidePopup={onHidePopup} />
            </PopupModal>
        );
    } else if (componentName === 'waitlistSuccess') {
        return (
            <PopupModal aria-labelledby="dialog-title" onHidePopup={onHidePopup} paddingInline="4x">
                <WaitlistSuccessPopup onHidePopup={onHidePopup} similarListings={similarListings} />
            </PopupModal>
        );
    } else if (componentName === 'report') {
        return (
            <PopupModal onHidePopup={onHidePopup} aria-labelledby="dialog-title" position="top" paddingInline="2x">
                <Report onHidePopup={onHidePopup} listing={listing} />
            </PopupModal>
        );
    } else if (componentName === 'reviews') {
        return (
            <PopupModal aria-labelledby="dialog-title" onHidePopup={onHidePopup} paddingInline="3x">
                <HdpReviewsModal
                    onHidePopup={onHidePopup}
                    ratingsAndReviews={listing.ratingsAndReviews}
                    displayName={listing.displayName}
                />
            </PopupModal>
        );
    } else if (componentName === 'error') {
        return (
            <PopupModal aria-labelledby="dialog-title" onHidePopup={onHidePopup} paddingInline="3x">
                <ErrorModal onHidePopup={onHidePopup} />
            </PopupModal>
        );
    } else if (componentName === 'zillowApplications') {
        return (
            <PopupModal aria-labelledby="dialog-title" onHidePopup={onHidePopup} paddingTop="0" paddingBottom="0">
                <ZillowApplicationPopup onActionPopup={onActionPopup} onHidePopup={onHidePopup} />
            </PopupModal>
        );
    }

    return null;
};

ActionPopupWrapper.propTypes = {
    onActionPopup: PropTypes.func,
    onHidePopup: PropTypes.func,
    componentName: PropTypes.oneOf([
        'BookTourNowButton',
        'ModifyTourCTA',
        'contactTourStatusModule',
        'contact',
        'contactSuccess',
        'report',
        'waitlistSuccess',
        'reviews',
        'error',
        'postContactSavedSearch',
        'postContactRenterProfile'
    ])
};

export default ActionPopupWrapper;
