// @ts-nocheck
/* eslint-enable */
// App
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';

// Components
import ComparisonTable from 'app/shared/modules/ComparisonTable';
import HdpContentWrapper from 'app/shared/modules/hdp/HdpContentWrapper';
import Skeleton from 'app/shared/modules/skeleton/SkeletonSuggestedRentals';

// Misc
import './style.scss';
import IconLeftArrow from 'images/icons/arrow-left-dark-grey.svg';
import IconRightArrow from 'images/icons/arrow-right-dark-grey.svg';

const { array, bool, func, object, string } = PropTypes;

const TableSkeleton = (
    <Fragment>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
    </Fragment>
);

const SimilarListings = ({
    categories,
    data,
    header,
    hideTable,
    onListingsClick,
    onArrowNavClick,
    placeholder,
    scrollRef,
    showScrollButton,
    trackImpression,
    isMobile
}) => {
    const iconSize = isMobile ? '16px' : '24px';
    const leftArrowScrollButton = (
        <button
            className={cx('SimilarListings-arrow-container', 'SimilarListings-left-arrow')}
            onClick={() => onArrowNavClick('left')}
            aria-label="previous listing"
        >
            <img src={IconLeftArrow} height={iconSize} width={iconSize} className={cx('SimilarListings-arrow-left')} alt="" />
        </button>
    );

    const rightArrowScrollButton = (
        <button
            className={cx('SimilarListings-arrow-container', 'SimilarListings-right-arrow')}
            onClick={() => onArrowNavClick('right')}
            aria-label="next listing"
        >
            <img src={IconRightArrow} height={iconSize} width={iconSize} className={cx('SimilarListings-arrow-left')} alt="" />
        </button>
    );
    return (
        <HdpContentWrapper collapsable active header={header} headerHtmlTag="h2">
            <div className={cx('SimilarListings-table-wrapper')}>
                <div className={cx('SimilarListings-scroll-container')} ref={scrollRef}>
                    {data ? (
                        <Fragment>
                            {showScrollButton.left ? leftArrowScrollButton : null}
                            {showScrollButton.right ? rightArrowScrollButton : null}
                            <ComparisonTable
                                categories={categories}
                                className="SimilarListings-table"
                                data={data}
                                hideTable={hideTable}
                                lazyLoadImage
                                onClick={onListingsClick}
                                placeholder={placeholder}
                                striped
                                trackImpression={trackImpression}
                                ariaLabel="Similar listings"
                            />
                        </Fragment>
                    ) : (
                        TableSkeleton
                    )}
                </div>
            </div>
        </HdpContentWrapper>
    );
};

SimilarListings.propTypes = {
    categories: array,
    data: array,
    header: string,
    hideTable: bool,
    onArrowNavClick: func,
    onListingsClick: func,
    placeholder: string,
    scrollRef: func,
    showScrollButton: object,
    trackImpression: func,
    isMobile: bool
};

SimilarListings.defaultProps = {
    categories: [],
    data: [],
    header: '',
    hideTable: false,
    onArrowNavClick: () => { },
    onListingsClick: () => { },
    placeholder: string,
    scrollRef: () => { },
    showScrollButton: object,
    trackImpression: () => { },
    isMobile: false
};

const mapStateToProps = (state) => ({
    isMobile: state.app.device.screenWidth === 'sm' || state.app.device.screenWidth === 'xs'
});
export default connect(mapStateToProps)(SimilarListings);
