// @ts-nocheck
/* eslint-enable */
import includes from 'lodash/includes';

export const highlightKeywords = ({ filterKeywords = '', fullDescription = '' }) => {
    let keywords = filterKeywords;
    let keywordsArray = includes(keywords, ',') ? keywords.split(',') : [keywords];
    let updateDescription = fullDescription;

    if (!fullDescription) {
        return '';
    }

    if (!keywords) {
        return fullDescription;
    }

    keywordsArray.forEach((word) => {
        // Need to escape regex metachars potentially found in keyword:
        function escapeRegExp(text) {
            return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
        }

        let searchWord = escapeRegExp(word.trim());
        let searchRegEx = new RegExp(searchWord, 'gi');

        // Since we 'dangerouslySetInnerHTML', we can safely replace any matching words
        // with a relevant span element and class (note: don't need to use 'className' here)
        updateDescription = updateDescription.replace(searchRegEx, `<span class="highlighted">${word}</span>`);
    });

    return updateDescription;
};
