// @ts-nocheck
/* eslint-enable */
import styled from 'styled-components';
import Text from 'app/shared/core/Text';
import { colors } from 'app/shared/styles/_colors';
import { font } from 'app/shared/styles/_fontsize';
import { viewports } from 'app/shared/styles/_breakpoints';

export const DateTimeForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
`;

export const Header = styled.div`
    ${font.xl}
    font-weight: bold;
    align-self: flex-start;
    padding-bottom: 15px;
`;

export const Subheader = styled.span`
    ${font.md}
    align-self: flex-start;
    padding-bottom: 10px;
`;

export const DateCarouselContainer = styled.fieldset`
    display: flex;
    gap: 5px;
    justify-content: flex-start;
    align-items: stretch;
`;

export const CarouselArrowButton = styled.button`
    &:hover {
        cursor: ${(props) => (props?.disabled ? 'default' : 'pointer')};
    }

    ${(props) =>
        props?.disabled &&
        `
        visibility: hidden;
    `}
`;

export const DateButton = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    padding: 10px;

    background: ${colors['$hpx-white']};
    box-sizing: border-box;
    border: 1px solid ${colors['$hpx-grey-400']};
    border-radius: 4px;

    height: 104px;
    width: 90px;

    @media ${viewports['md-and-up']} {
        width: 104px;
    }

    &:hover {
        cursor: ${(props) => (props?.disabled ? 'default' : 'pointer')};
    }

    ${(props) =>
        props?.isPressed &&
        `
        background: ${colors['$hpx-teal-100']};
    `}

    ${(props) =>
        props?.disabled &&
        `
        background: ${colors['$hpx-grey-400']};
    `}
`;

export const ButtonText = styled(Text)`
    font-weight: bold;
`;

export const TimeButtonsContainer = styled.fieldset`
    display: grid;
    column-gap: 5px;
    row-gap: 10px;

    ${(props) =>
        `
        grid-template-columns: repeat(${props.numBtnsInCarousel}, 1fr);
    `}
`;

export const TimeButton = styled.button`
    width: 90px;
    @media ${viewports['md-and-up']} {
        width: 104px;
    }

    padding: 5px;

    background: ${colors['$hpx-white']};
    box-sizing: border-box;
    border: 1px solid ${colors['$hpx-grey-400']};
    border-radius: 4px;

    &:hover {
        cursor: pointer;
    }

    ${(props) =>
        props?.isPressed &&
        `
        background: ${colors['$hpx-teal-100']};
    `}

    ${(props) =>
        props?.disabled &&
        `
        background: ${colors['$hpx-grey-400']};
    `}
`;
