import React from 'react';

import Text from 'app/shared/core/Text';
import { TextSizeKeys } from 'app/shared/core/Text/styles';
import Linker from 'app/shared/modules/Linker';
import pathsManifest from 'app/shared/utils/pathsManifest';

interface DisclaimerProps {
    linkType?: string;
    tourProviderName?: string;
    size?: TextSizeKeys;
}

const Disclaimer = ({ size = 'tiny', linkType = 'inverted', tourProviderName = '' } = {} as DisclaimerProps) => {
    if (tourProviderName) {
        return (
            <Text size={size}>
                By clicking "Schedule tour", you authorize Zillow Group to send the information you provided to{' '}
                {tourProviderName}, a third-party tour scheduling provider. You consent to Zillow Group,{' '}
                {tourProviderName}, and the rental manager calling or texting you at the number you provided about your
                inquiry, which may involve use of automated means and prerecorded/artificial voices. You don't need to
                consent as a condition of renting any property, or buying any other goods or services. Message/data
                rates may apply. You agree to
                {' our '}
                <Linker linkType={linkType} to={pathsManifest.rentalTermsOfUse} target="_blank">
                    Terms of Use
                </Linker>
                {' and '}
                <Linker linkType={linkType} to={pathsManifest.privacy} target="_blank">
                    Privacy Policy
                </Linker>
                .
            </Text>
        );
    }

    return (
        <Text size={size}>
            By choosing to contact a property, you consent to receive calls or texts at the number you provided, which
            may involve use of automated means and prerecorded/artificial voices, from Zillow Group and the rental
            manager(s) you choose to contact about any inquiries you submit through our services. You don't need to
            consent as a condition of renting any property or buying any other goods or services. Message/data rates may
            apply. You also agree to{` Zillow's `}
            <Linker linkType={linkType} to={pathsManifest.rentalTermsOfUse} target="_blank">
                Terms of Use
            </Linker>
            {' and '}
            <Linker linkType={linkType} to={pathsManifest.privacy} target="_blank">
                Privacy Policy
            </Linker>
            .
        </Text>
    );
};

export default Disclaimer;
