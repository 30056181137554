// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useYieldCallback } from '@zillow/yield-callback';

// Actions
import RouteActions from 'app/shared/flux/actions/RouteActions';

// Components
import Container from 'app/shared/core/Container';
import Linker from 'app/shared/modules/Linker';
import Row from 'app/shared/core/Row';
import Text from 'app/shared/core/Text';

// Icons
import IconCuckoo from 'images/icons/cuckoo.svg';

// Misc / utils
import dateUtils from 'app/shared/utils/dateUtils';
import 'app/shared/modules/hdp/ExpiredListingHeader.scss';

const ExpiredListingHeader = () => {
    // Redux
    const dispatch = useDispatch();
    const listing = useSelector((state) => state.currentListingDetails.currentListing);

    const areaPath = dispatch(RouteActions.getAreaPath());

    const { history } = listing;

    const yieldTransitionTo = useYieldCallback((path) => {
        window.router.transitionTo(path);
    });

    const handleLinkClick = (e, path) => {
        // Prevent the browser from going through with its default <a> behavior
        e.preventDefault();

        yieldTransitionTo(path);
    };

    let deactivated;

    if (history && history.deactivated.ago) {
        deactivated = dateUtils.formatDateMmddyy(history.deactivated.date);
    }

    return (
        <div className="ExpiredListingHeader">
            <Container>
                <Row>
                    <div className="ExpiredListingHeader-image">
                        <img src={IconCuckoo} className="ExpiredListingHeader-cuckoo-svg" alt="" />
                    </div>
                    <div className="ExpiredListingHeader-content">
                        <Text size="sm">
                            {deactivated ? (
                                <div>This pad was taken off the market on {deactivated}.</div>
                            ) : (
                                <div>This pad is no longer available.</div>
                            )}
                            <div>
                                <Linker to={areaPath} onClick={(e) => handleLinkClick(e, areaPath)}>
                                    Explore listings in the same area.
                                </Linker>
                            </div>
                        </Text>
                    </div>
                </Row>
            </Container>
        </div>
    );
};

export { ExpiredListingHeader };
export default ExpiredListingHeader;
