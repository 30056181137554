import React from 'react';
import loadable from '@loadable/component';

const Ad = loadable(() => import(/* webpackChunkName: "Ad.loadable" */ 'app/shared/modules/Ad/component'), {});

class LoadableAd extends React.Component {
    render() {
        return <Ad {...this.props} />;
    }
}

export default LoadableAd;
