// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { yieldCallback } from '@zillow/yield-callback';
import Linker from 'app/shared/modules/Linker';
import Row from 'app/shared/core/Row';
import SchoolRatingIcon from 'app/shared/modules/hdp/SchoolRatingIcon';
import 'app/shared/modules/hdp/SchoolItem.scss';
import cx from 'classnames';
class SchoolItem extends React.Component {
    constructor(props) {
        super(props);
    }

    getSchoolType = () => {
        let { school } = this.props;
        let { lowGrade, highGrade, isPrivate, type } = school;
        let grades;
        let schoolType = 'Public';

        if (type === 'university') {
            schoolType = 'College';
        } else if (isPrivate) {
            schoolType = 'Private';
        }

        if (lowGrade === 'KG') {
            lowGrade = 'K';
        }

        if (lowGrade !== 'M' && typeof lowGrade !== 'undefined') {
            grades = `Grades ${lowGrade} - ${highGrade}`;
        }

        return (
            <Row size="sm">
                <div className="SchoolItem-type-container">
                    <div className="SchoolItem-type">{schoolType}</div>
                    <div className="SchoolItem-grades">{grades}</div>
                </div>
            </Row>
        );
    };

    getSchoolRating = () => {
        let { school } = this.props;
        let { rating, type } = school;

        if (type === 'university') {
            return null;
        }

        return (
            <div className="SchoolItem-rating-container">
                <SchoolRatingIcon rating={rating} />
            </div>
        );
    };

    yieldTransitionToSchoolAreaClick = yieldCallback(() => {
        const { school } = this.props;
        window.router.transitionTo(school.uriV2);
    });

    handleSchoolLinkClick = (e) => {
        // Prevent the browser from going through with its default <a> behavior
        e.preventDefault();

        this.yieldTransitionToSchoolAreaClick();
    };

    render() {
        let { school, hidden } = this.props;
        let { distance, name } = school;

        return (
            <li
                className={cx('SchoolItem', {
                    'SchoolItem-ssr-only': hidden
                })}
            >
                <Row>
                    <h3 className="SchoolItem-name">
                        <Linker to={school.uriV2} onClick={this.handleSchoolLinkClick} ref={this.props.innerRef} tabIndex="0">{name}</Linker>
                    </h3>
                    {this.getSchoolRating()}
                    {this.getSchoolType()}
                    {distance && <div className="SchoolItem-distance">{`${distance} miles`}</div>}
                </Row>
            </li>
        );
    }
}

// eslint-disable-next-line react/no-multi-comp
export default React.forwardRef((props, ref) => <SchoolItem innerRef={ref} {...props} />);
