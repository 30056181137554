import React from 'react';
import loadable from '@loadable/component';

interface PromptEmailProps {
    email?: string;
    header?: React.ReactNode | null;
    handleLoginSuccess?: () => void;
    makeVisible?: () => void;
    setEmail?: (email: string) => void;
    showDescription?: boolean;
    showTitle?: boolean;
    title?: string;
}


const PromptEmail = loadable(
    () => import(/* webpackChunkName: "promptEmail.loadable" */ 'app/shared/modules/auth/PromptEmail/component'),
);

const LoadablePromptEmail = (props: PromptEmailProps) => {
    return <PromptEmail { ...props } />
};

export default LoadablePromptEmail;
