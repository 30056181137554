// @ts-nocheck
/* eslint-enable */
// App
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { yieldCallback } from '@zillow/yield-callback';

// Actions
import ListingEngineActions from 'app/shared/flux/actions/ListingEngineActions';
import RouteActions from 'app/shared/flux/actions/RouteActions';

// Components
import SimilarListings from 'app/shared/modules/hdp/SimilarListings';

// Misc / Utils
import controller from './controller';
import { impression } from 'app/client/zg-analytics';

// Lodash
import isEmpty from 'lodash/isEmpty';
import throttle from 'lodash/throttle';
import { analyticsEvent } from 'app/client/universal-analytics';
import { TrackImpressionForPropertyCard } from 'app/shared/models/Clickstream/ListingCardClickstreamEvents';

const { array, bool, func, object, string } = PropTypes;
const HEADER = 'Find similar homes';
const PLACEHOLDER = '—';

class SimilarListingsContainer extends React.Component {
    static propTypes = {
        currentListing: object,
        keepMapLocation: bool,
        screenWidth: string,
        onClick: func,
        similarListings: array,
        hideTable: bool
    };

    static defaultProps = {
        currentListing: [],
        keepMapLocation: false,
        screenWidth: '',
        onClick: () => { },
        similarListings: {},
        hideTable: false
    };

    constructor(props) {
        super(props);

        this.state = {
            data: null,
            categories: null,
            showLeftScrollButton: false,
            showRightScrollButton: false
        };
        this.horizontalScrollerRef = null;
        this.throttledScroller = throttle(this.handleScroll, 100, { leading: false, trailing: true });
        this.setHorizontalScrollerRef = (element) => {
            this.horizontalScrollerRef = element;
            if (this.horizontalScrollerRef) {
                this.horizontalScrollerRef.addEventListener('scroll', this.throttledScroller);
            }
        };
    }

    componentDidMount() {
        this.parseData();
    }

    /**
     * Used to get the comparison table width when initialized and comparing it after the DOM updates once the
     * similar listings "fill out" the comparison table
     *
     * "getSnapshotBeforeUpdate() is invoked right before the most recently rendered output is committed to e.g. the DOM.
     * It enables your component to capture some information from the DOM (e.g. scroll position) before it is potentially
     * changed. Any value returned by this lifecycle will be passed as a parameter to componentDidUpdate()."
     *
     * @see https://reactjs.org/docs/react-component.html#getsnapshotbeforeupdate
     */
    getSnapshotBeforeUpdate() {
        const fullWidthBeforeDOMUpdate = this.horizontalScrollerRef && this.horizontalScrollerRef.scrollWidth;

        return fullWidthBeforeDOMUpdate;
    }

    componentDidUpdate(prevProps, _prevState, containerFullWidthSnapshot) {
        const { similarListings, screenWidth } = this.props;
        const horizontalScrollerRef = this.horizontalScrollerRef;
        const containerFullWidth = horizontalScrollerRef && horizontalScrollerRef.scrollWidth;
        const containerVisibleWidth = horizontalScrollerRef && horizontalScrollerRef.offsetWidth;
        const scrollLeftPosition = horizontalScrollerRef && horizontalScrollerRef.scrollLeft;

        if (similarListings.length !== prevProps.similarListings.length) {
            this.parseData();
        }

        if (containerFullWidthSnapshot !== containerFullWidth) {
            if (containerFullWidth > containerVisibleWidth) {
                this.handleScrollButtonState({ direction: 'right', shouldShow: true });
            }
        }

        // Check if scroll buttons should render if user starts resizing their browser window
        if (screenWidth !== prevProps.screenWidth && horizontalScrollerRef) {
            if (containerFullWidth === containerVisibleWidth) {
                this.handleScrollButtonState({ direction: 'right', shouldShow: false });
                this.handleScrollButtonState({ direction: 'left', shouldShow: false });
            }

            if (containerFullWidth > containerVisibleWidth && scrollLeftPosition <= 0) {
                this.handleScrollButtonState({ direction: 'right', shouldShow: true });
            }

            if (containerFullWidth > containerVisibleWidth && scrollLeftPosition > 0) {
                this.handleScrollButtonState({ direction: 'right', shouldShow: true });
                this.handleScrollButtonState({ direction: 'left', shouldShow: true });
            }
        }
    }

    componentWillUnmount() {
        if (this.horizontalScrollerRef) {
            this.horizontalScrollerRef.removeEventListener('scroll', this.throttledScroller);
        }
    }

    parseData = () => {
        const { similarListings, currentListing } = this.props;
        const newListingsArray = [currentListing, ...similarListings];
        const data = controller.getFormattedTableData(currentListing, newListingsArray);
        const categories = controller.getComparisonCategories(newListingsArray);

        this.setState({
            data,
            categories
        });
    };

    yieldTrackingAndTransitionToClick = yieldCallback((listing) => {
        const { dispatch, onClick, keepMapLocation } = this.props;
        onClick();
        dispatch(ListingEngineActions.setPreviewListing(listing));
        dispatch(ListingEngineActions.setActiveMarkerMaloneLotId(listing.maloneLotIdEncoded));
        dispatch(RouteActions.transitionToListing({ listingUri: listing.uriV2, keepMapLocation }));
    });

    handleListingClick = (e, listing) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
        }

        this.yieldTrackingAndTransitionToClick(listing);
    };

    handleScroll = () => {
        const containerFullWidth = this.horizontalScrollerRef.scrollWidth;
        const containerVisibleWidth = this.horizontalScrollerRef.offsetWidth;
        const scrollLeftPosition = this.horizontalScrollerRef.scrollLeft;
        const containerLeftEdge = containerFullWidth - containerVisibleWidth;

        // Show left arrow if we scroll in the positive X direction, greater than threshold
        if (scrollLeftPosition > 0) {
            this.handleScrollButtonState({ direction: 'left', shouldShow: true });
        }

        // Hide left arrow if we scroll in the negative X direction, less than/equal to threhold
        if (scrollLeftPosition <= 0) {
            this.handleScrollButtonState({ direction: 'left', shouldShow: false });
        }

        // Hide right arrow if we scroll in the positive X direction, to the container end
        if (containerLeftEdge === scrollLeftPosition) {
            this.handleScrollButtonState({ direction: 'right', shouldShow: false });
        }

        // Show right arrow if we scroll in the negative X direction, away from container end
        if (scrollLeftPosition < containerLeftEdge) {
            this.handleScrollButtonState({ direction: 'right', shouldShow: true });
        }
    };

    handleScrollButtonState = ({ direction, shouldShow }) => {
        const directionMap = {
            left: 'showLeftScrollButton',
            right: 'showRightScrollButton'
        };

        this.setState({
            [directionMap[direction]]: shouldShow
        });
    };

    trackImpression = (listing) => {
        const { currentListing, dispatch } = this.props;
        const { aliasEncoded } = listing;

        impression({
            category: 'RentalHDP',
            label: 'similarListingsForRentModule',
            dimension5: currentListing.aliasEncoded,
            dimension60: aliasEncoded
        });

        dispatch(analyticsEvent({ newLaneEvent: TrackImpressionForPropertyCard({ listing, triggerLocation: 'similar_listings_module' }) }));

    };

    render() {
        const { similarListings, screenWidth, hideTable } = this.props;
        const { categories, data, showLeftScrollButton, showRightScrollButton } = this.state;

        if (isEmpty(similarListings)) {
            return null;
        }

        return (
            <SimilarListings
                data={data}
                categories={categories}
                header={HEADER}
                hideTable={hideTable}
                onListingsClick={this.handleListingClick}
                onArrowNavClick={(direction) =>
                    controller.handleArrowNavClick(direction, screenWidth === 'xl', this.horizontalScrollerRef)
                }
                placeholder={PLACEHOLDER}
                scrollRef={this.setHorizontalScrollerRef}
                showScrollButton={{ left: showLeftScrollButton, right: showRightScrollButton }}
                trackImpression={this.trackImpression}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentListing: state.currentListingDetails.currentListing,
        screenWidth: state.app.device.screenWidth,
        similarListings: state.fetchListing.similarListings
    };
};

export default connect(mapStateToProps)(SimilarListingsContainer);
