// @ts-nocheck
/* eslint-enable */
import styled from 'styled-components';
import { inline } from 'app/shared/styles/_spacing';
import { fontSize } from 'app/shared/styles/_fontsize';
import { colors } from 'app/shared/styles/_colors';
import { smoothTransition, transform } from 'app/shared/styles/_mixins';

export const ListingsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    margin-bottom: 24px;
    padding: 0 8px;
`;

export const ButtonContainer = styled.section`
    display: flex;
    justify-content: center;
`;

export const ExpandButton = styled.button`
    cursor: pointer;
    background: none;
    font-size: ${fontSize['$font-size-md']};
    font-weight: bold;
    color: ${colors['$hpx-teal-500']};

    &:hover {
        text-decoration: underline;
    }
`;

export const CollapseArrow = styled.img`
    ${smoothTransition};
    ${inline._1x};

    ${(props) =>
        !props.isCollapsed &&
        `
        ${transform('rotate(90deg)')}
    `}

    ${(props) =>
        props.isCollapsed &&
        `
        ${transform('rotate(-90deg)')}
    `}
`;
