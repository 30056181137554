// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import styled from 'styled-components';
import { stacked, padding } from 'app/shared/styles/_spacing';
import { connect } from 'react-redux';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import some from 'lodash/some';
import cx from 'classnames';

import './ContactListingRestrictions.scss';
import Checkbox from 'app/shared/core/Checkbox';
import ListingRestrictions from 'app/shared/modules/hdp/ListingRestrictions';
import ListingRestrictionsInline from 'app/shared/modules/hdp/ListingRestrictionsInline';
import Select from 'app/shared/core/Select';

const propTypes = {
    listing: PropTypes.object.isRequired,
    onSelect: PropTypes.func.isRequired,
    onConfirmation: PropTypes.func.isRequired,
    className: PropTypes.string
};

const StyledConfirmationContainer = styled.div`
    ${stacked._2x};
`;

const StyledWarningContainer = styled.div`
    ${stacked._1x};
    ${padding.top._2x};
`;
const StyledBoldCheckbox = styled(Checkbox)`
    font-weight: 700;
`;
class ContactListingRestrictions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            meetRequirements: false
        };

        this.getMessage = this.getMessage.bind(this);
        this.requiresIncomeRange = this.requiresIncomeRange.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.renderConfirmation = this.renderConfirmation.bind(this);
    }
    getMessage(persons, restriction) {
        if (Number(persons) > 1) {
            return 'We are a ' + persons + ' person household with a combined income below ' + restriction + '.';
        } else {
            return 'I am a single person with an annual income below ' + restriction + '.';
        }
    }
    requiresIncomeRange() {
        const { listing } = this.props;

        if (listing.contact.restricted && some(listing.contact.restrictedIncomeByOccupants)) {
            return true;
        }

        return false;
    }
    handleOnChange(e) {
        const { onSelect, onConfirmation } = this.props;
        const { meetRequirements } = this.state;

        if (e) {
            e.preventDefault();
        }

        if (this.requiresIncomeRange() && e.target.value !== 'default value') {
            onSelect(e.target.value);
        } else if (!this.requiresIncomeRange()) {
            let newValue = !meetRequirements;
            this.setState({ meetRequirements: newValue });
            onConfirmation(newValue);
        }
    }
    renderConfirmation() {
        const { listing } = this.props;
        const { meetRequirements } = this.state;
        const contact = listing.contact;

        if (this.requiresIncomeRange()) {
            return (
                <Select onChange={this.handleOnChange} aria-label="income range">
                    <option value="default value" selected disabled>
                        Select residents and income range
                    </option>
                    {map(contact.restrictedIncomeByOccupants, (restriction, persons) => {
                        return (
                            <option value={this.getMessage(persons, restriction)} key={`${restriction}${persons}`}>
                                {Number(persons) > 1 ? `${persons} residents` : `${persons} resident`}
                                {`, income below ${restriction}`}
                            </option>
                        );
                    })}
                </Select>
            );
        } else {
            return (
                <StyledBoldCheckbox
                    checked={meetRequirements}
                    label="Yes, I meet the requirements!"
                    onChange={this.handleOnChange}
                    type="checkbox"
                />
            );
        }
    }
    render() {
        const { listing, inline, className } = this.props;
        const isStudentHousing = listing.details.studentHousing;
        const isSeniorHousing = listing.details.seniorHousing;
        const isLowIncome = listing.contact.restricted;
        let warningMessage = `To rent this property, you must meet the requirements for
            income-restricted affordable housing.`;

        let showRestrictions = isStudentHousing || isSeniorHousing || isLowIncome;

        if (!showRestrictions) {
            return null;
        }

        if (isSeniorHousing) {
            warningMessage = 'To rent this property, you must meet the requirements to qualify for senior housing.';
        } else if (isStudentHousing) {
            warningMessage = 'To rent this property, you must meet the requirements to qualify for student housing.';
        }

        return (
            <div className={cx('ContactListingRestrictions', className)}>
                <div className="ContactListingRestrictions-banner">
                    {inline ? (
                        <ListingRestrictionsInline listing={listing} />
                    ) : (
                        <ListingRestrictions listing={listing} noBorder noPadding />
                    )}
                </div>
                <StyledWarningContainer>{warningMessage}</StyledWarningContainer>
                <StyledConfirmationContainer>{this.renderConfirmation()}</StyledConfirmationContainer>
            </div>
        );
    }
}

ContactListingRestrictions.propTypes = propTypes;
export default connect()(ContactListingRestrictions);
