/**
 * intersectObserveWrapper is an intersectionObserver helper that can be
 * used in our app to easily add a way to execute callbacks when the intersection
 * observer threshold parameters are met.
 *
 * In simple terms, if threshold parameters are met -> execute the callback that
 * is passed into the wrapper.
 *
 * NOTE: Do not forget to cleanup the observer on component unmount!
 *
 * Class based:
 * componentWillUnmount() {
 *      if (this.observer) {
 *          this.observer.disconnect()
 *      }
 * }
 *
 * Functional component based:
 * useEffect(() => {
 *      return () => {
 *          observer.disconnect();
 *     }
 * })
 *
 * @see app/shared/modules/hdp/Local/index.js for a usage example.
 */

interface IntersectionObserverOptions {
    root: Element | null;
    rootMargin: string;
    threshold: number;
}

export const intersectionObserverWrapper = (
    target: HTMLElement,
    callback: () => void,
    options: IntersectionObserverOptions
) => {
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                callback();
                observer.unobserve(entry.target);
            }
        });
    }, options);

    observer.observe(target);

    return observer;
};

export const disconnectIntersectionObserver = (observer: IntersectionObserver) => {
    if (observer) {
        observer.disconnect();
    }
};
