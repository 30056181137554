// @ts-nocheck
/* eslint-enable */
// App
import React from 'react';
import styled from 'styled-components';

// Components
import HdpContentWrapper from 'app/shared/modules/hdp/HdpContentWrapper';

// Misc / utils
import { font } from 'app/shared/styles/_fontsize';

// Styled component
const BrokerText = styled.p`
    ${font.md};
`;

const areEqual = (prevProps, nextProps) => {
    if (
        prevProps.broker.contactName === nextProps.broker.contactName &&
        prevProps.broker.companyName === nextProps.broker.companyName
    ) {
        return true;
    }

    return false;
};

const BrokerInfoTop = ({ broker, isListingActive }) => {
    return (
        <HdpContentWrapper header="Listing provided by" headerHtmlTag="h2" active>
            <address>
                <BrokerText>{broker.companyName}</BrokerText>
                <BrokerText>{broker.contactName}</BrokerText>
                {isListingActive && <BrokerText>{broker.companyPhoneNumber}</BrokerText>}
            </address>
        </HdpContentWrapper>
    );
};

export default React.memo(BrokerInfoTop, areEqual);
