// @ts-nocheck
/* eslint-enable */
import React from 'react';
import * as S from './styles';
import IconContact from 'images/icons/contact-white.svg';
import { listingUtils_userRecentlyContactedListing } from 'app/shared/utils/listingUtils';

// Hook
import useContactButton from './useContactButton';

const ContactButtonV2 = ({ listing, onClick = () => { }, contactButtonOffsetTop = 0 } = {}) => {
    const hideListing = !listing.contact.displayContactBox;

    // Hook
    const [handleContactButtonClick] = useContactButton({ onClick });

    const recentlyContacted =
        listing.userItemTypes && listing.userItemDates
            ? listingUtils_userRecentlyContactedListing(listing.userItemTypes, listing.userItemDates)
            : false;

    const labelMap = {
        buttonLabel: 'Check availability',
        gaLabel: 'defaultCTA'
    };

    if (listing.waitlisted) {
        labelMap.buttonLabel = 'Add me to the waitlist';
        labelMap.gaLabel = 'waitlistedCTA';
    } else if (listing.popularity && listing.popularity.summary.inquiries === 0) {
        labelMap.buttonLabel = 'Be the first to contact!';
        labelMap.gaLabel = 'firstToContactCTA';
    }
    if (recentlyContacted) {
        labelMap.buttonLabel = 'Contact again?';
        labelMap.gaLabel = 'contactAgainCTA';
    }

    if (
        !listing ||
        hideListing ||
        !listing.active ||
        !listing.userItemDates ||
        !listing.userItemTypes ||
        listing.userOnWaitlist
    ) {
        return null;
    }

    // id="ContactButtonV2Sticky is tied to a Sonar test; update the test if this changes!
    return (
        <S.HdpContactButtonV2Layout id="ContactButtonV2Sticky">
            <S.HdpContactButtonV2Container contactButtonOffsetTop={contactButtonOffsetTop}>
                <S.StyledContactButtonV2
                    aria-label={labelMap.buttonLabel}
                    className={`ContactButtonV2`}
                    data-contact-label={labelMap.gaLabel}
                    btnType={'primary'}
                    full
                    bold
                    size={'lg'}
                    onClick={handleContactButtonClick}
                >
                    <S.StyledContactIcon src={IconContact} height="20px" width="22px" alt="" />
                    {labelMap.buttonLabel}
                </S.StyledContactButtonV2>
            </S.HdpContactButtonV2Container>
        </S.HdpContactButtonV2Layout>
    );
};

export default ContactButtonV2;
