// @ts-nocheck
/* eslint-enable */
import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';
import { variables } from 'app/shared/styles/_variables';
import { viewports } from 'app/shared/styles/_breakpoints';

export const TourStatusContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    padding: 25px;

    width: 100%;

    ${(props) =>
        props?.showBorder &&
        `
        border: 1px solid ${colors['$hpx-teal-600']};
    `};

    ${(props) =>
        props?.noCenter &&
        `
        align-items: flex-start;
    `};

    ${(props) =>
        props?.errorBoarder &&
        `
        background-color: ${colors['$hpx-red-100']}
        border-color: ${colors['$hpx-red-400']};
    `};

    ${(props) =>
        props?.isCancel &&
        `
        padding: 16px;
    `};
`;

export const Icon = styled.img`
    width: 22px;
    height: 22px;

    ${(props) =>
        props?.isRenderedOnHdp &&
        `
        width: 28px;
        height: 28px;
    `};
`;

export const IconAndTextContainer = styled.span`
    display: flex;
    gap: 10px;
`;

export const CTAContainer = styled.div`
    padding-top: 25px;
    display: flex;
    flex-direction: column;

    @media ${viewports['md-and-up']} {
        flex-direction: row;
        gap: 24px;
    }
`;

export const TextButton = styled.button`
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: bold;

    &:hover {
        cursor: pointer;
    }

    ${(props) =>
        props?.isCancel &&
        `
        margin-top: 8px;
    `};
`;
