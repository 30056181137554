// @ts-nocheck
/* eslint-enable */
import React from 'react';
import './style.scss';
import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';

import ReviewItem from 'app/shared/modules/reviews/ReviewItem';
import RatingsAndReviewStats from 'app/shared/modules/RatingsAndReviewStats';
import Row from 'app/shared/core/Row';
import Divider from 'app/shared/modules/Divider';
import Text from 'app/shared/core/Text';

const StyledDarkText = styled(Text)`
    color: ${colors['$hpx-grey-600']};
`;
const StyledBoldText = styled(Text)`
    font-weight: 700;
`;
const ReviewsModal = ({ ratingsAndReviews, displayName }) => {
    const { reviews } = ratingsAndReviews;

    return (
        <div className="ReviewsModal">
            <Row size="sm">
                <StyledBoldText id="dialog-title" size="lg">
                    {displayName} Reviews
                </StyledBoldText>
            </Row>
            <Divider />
            <StyledDarkText size="sm">
                Property rating
            </StyledDarkText>
            <RatingsAndReviewStats ratingsAndReviews={ratingsAndReviews} />
            <Divider />
            <Text size="sm">Displaying {reviews.length} written reviews.</Text>
            <Divider />
            {reviews.map((review, i) => {
                return (
                    <Row key={`row-${i}`}>
                        <ReviewItem review={review} />
                    </Row>
                );
            })}
        </div>
    );
};

export default ReviewsModal;
