import styled from 'styled-components';
import { viewports } from 'app/shared/styles/_breakpoints';

import RenterProfileForm from 'app/shared/modules/RenterProfileForm';
import Text from 'app/shared/core/Text';

export const StyledRenterProfilePopup = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;

    height: 100%;
    padding: 0px 16px;
    width: 100%;

    @media ${viewports['md-and-up']} {
        height: 500px;
        margin: 0 auto;
        width: 420px;
    }
`;

export const ModalTitle = styled(Text)`
    padding-bottom: 8px;
    font-weight: 700;
    text-align: center
`;

export const ScrollWrapper = styled.div`
    overflow-y: scroll;
`;

export const StyledRenterProfileForm = styled(RenterProfileForm)`
    padding-bottom: 8px;
`;

export const ButtonFooter = styled.footer`
    display: flex;
    flex-direction: row;
    gap: 8px;
`;
