// @ts-nocheck
/* eslint-enable */
import some from 'lodash/some';
import uniqBy from 'lodash/uniqBy';

import { listingUtils_modelBaths, listingUtils_modelBeds } from 'app/shared/utils/listingUtils';
import validator from 'app/shared/utils/validator';

const controller = {
    userHasMaskedEmail(emailAddress = '') {
        if (emailAddress.indexOf('privaterelay.appleid.com') > -1) {
            return true;
        }

        return false;
    },
    hasSentContact({ email, name, phone, text }) {
        return (email !== null && name !== null && phone !== null) || text !== null;
    },
    shouldRequireRestrictedIncomeChoice(listing) {
        return listing.contact.restricted && some(listing.contact.restrictedIncomeByOccupants);
    },
    getInquiryText({ userMessage, lowIncomeMessage, listing }) {
        if (controller.shouldRequireRestrictedIncomeChoice(listing)) {
            return `${userMessage} ${lowIncomeMessage}`;
        } else {
            return userMessage;
        }
    },
    dedupeBedsBathsOptions(floorplans) {
        let newFloorplans = [].concat(floorplans);
        const sortedFloorplans = newFloorplans.sort((floorPlan1, floorPlan2) => {
            if (floorPlan2.beds === '8plus') {
                return -1;
            }

            if (floorPlan1.beds === floorPlan2.beds && floorPlan1.baths && floorPlan2.baths) {
                return floorPlan1.baths - floorPlan2.baths;
            }

            return floorPlan1.beds - floorPlan2.beds;
        });

        const simplifiedFloorplans = sortedFloorplans.map((floorPlan) => {
            return { beds: floorPlan.beds, baths: floorPlan.baths, key: controller.getBedsBathsText(floorPlan) };
        });

        return uniqBy(simplifiedFloorplans, 'key');
    },
    getBedsBathsText(floorPlan) {
        return `${listingUtils_modelBeds(floorPlan.beds)}, ${listingUtils_modelBaths(floorPlan.baths)}`;
    },
    validateContactFormInput({ inputValues, listing, restrictedConfirmation = false }) {
        const contact = listing.contact;
        const isStudentHousing = listing.details && listing.details.studentHousing;
        const isSeniorHousing = listing.details && listing.details.seniorHousing;
        const isLowIncome = listing.contact.restricted;
        const parsedNumBaths = parseInt(inputValues.numBaths);
        const parsedNumBeds = parseInt(inputValues.numBeds);

        let isValid = true;
        let newErrorsObj = {
            numBeds: null,
            numBaths: null,
            moveDate: null,
            userMessage: null,
            userEmail: null,
            userName: null,
            userPhoneNumber: null
        };
        let newFormErrorVal = null;

        // check if contact requires a message
        if (contact.requiresMessage && inputValues.userMessage.length === 0) {
            newErrorsObj.userMessage = 'Please enter a message';
            isValid = false;
        }

        // check if email required or user started typing something.
        if (contact.requiresEmail || inputValues.userEmail.length > 0) {
            if (!validator.email(inputValues.userEmail).isValid) {
                newErrorsObj.userEmail = validator.email(inputValues.userEmail).message;
                isValid = false;
            }
        }

        // check if user email contains masked address domain.
        if (controller.userHasMaskedEmail(inputValues.userEmail)) {
            newErrorsObj.userEmail = 'Please update your email address.';
            isValid = false;
        }

        // check if user name required
        if (contact.requiresName) {
            if (inputValues.userName.trim().length === 0) {
                newErrorsObj.userName = 'Please enter your name';
                isValid = false;
            } else if (inputValues.userName.trim().length > 0 && inputValues.userName.trim().split(' ').length <= 1) {
                newErrorsObj.userName = 'Please enter your full name';
                isValid = false;
            }
        }

        // check if phone number required or user has entered data into phone number input
        if (contact.requiresPhone || inputValues.userPhoneNumber.length > 0) {
            if (!validator.phoneNumber(inputValues.userPhoneNumber).isValid) {
                newErrorsObj.userPhoneNumber = validator.phoneNumber(inputValues.userPhoneNumber).message;
                isValid = false;
            }
        }

        // check if # of beds / baths required
        if (contact.requiresNumBeds) {
            if (!validator.smallNumber(parsedNumBeds).isValid) {
                newErrorsObj.numBeds = validator.smallNumber(parsedNumBeds).message;
                isValid = false;
            }

            if (!validator.smallNumber(parsedNumBaths).isValid) {
                newErrorsObj.numBaths = validator.smallNumber(parsedNumBaths).message;
                isValid = false;
            }
        }

        // Listing requires income verification and number of residents.
        if (controller.shouldRequireRestrictedIncomeChoice(listing) && !inputValues.lowIncomeMessage) {
            newFormErrorVal = 'Please select resident and income range.';
            isValid = false;
        }

        // Listing is restricted and requires additional confirmation by user.
        if (
            !controller.shouldRequireRestrictedIncomeChoice(listing) &&
            !restrictedConfirmation &&
            (isLowIncome || isSeniorHousing || isStudentHousing)
        ) {
            newFormErrorVal = 'Please confirm you meet the housing requirements for this listing.';
            isValid = false;
        }

        return {
            errorObj: {
                inputErrors: newErrorsObj,
                formError: newFormErrorVal
            },
            isValid
        };
    }
};

export default controller;
