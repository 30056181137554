// @ts-nocheck
/* eslint-enable */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';
import './style.scss';
import dateUtils from 'app/shared/utils/dateUtils';
import Divider from 'app/shared/modules/Divider';
import LinkToggle from 'app/shared/modules/LinkToggle';
import ReviewFeedback from 'app/shared/modules/reviews/ReviewFeedback';
import ReviewResponse from 'app/shared/modules/reviews/ReviewResponse';
import ReviewResponseMessage from 'app/shared/modules/reviews/ReviewResponseMessage';
import Row from 'app/shared/core/Row';
import StaticStarRating from 'app/shared/modules/StaticStarRating';
import Text from 'app/shared/core/Text';

const MAX_CONTENT_HEIGHT = 98;
const StyledHalfDiv = styled.div`
    display: inline-block;
    width: 50%;
    vertical-align: top;
`;
const StyledHalfDivDark = styled(StyledHalfDiv)`
    color: ${colors['$hpx-grey-600']};
    text-align: right;
`;
const StyledTitleText = styled(Text)`
    color: ${colors['$hpx-grey-600']};
`;
const StyledShowMoreSpan = styled.span`
    text-align: right;
`;
class ReviewItem extends Component {
    static defaultProps = {
        disableResponse: false
    };

    constructor(props) {
        super(props);

        this.state = {
            hasTruncatedContent: false,
            showMore: false
        };
    }

    componentDidMount() {
        if (this.messageContainer) {
            const height = ReactDOM.findDOMNode(this.messageContainer).clientHeight;
            this.setState(() => {
                return {
                    hasTruncatedContent: height > MAX_CONTENT_HEIGHT,
                    showMore: height > MAX_CONTENT_HEIGHT
                };
            });
        }
    }

    handleToggleReview = () => {
        this.setState((state) => {
            return {
                showMore: !state.showMore
            };
        });
    };

    render() {
        const { showMore, hasTruncatedContent } = this.state;
        const { review, disableResponse } = this.props;
        const {
            starLevel,
            reviewMessage,
            created,
            reviewId,
            reviewType,
            userVote,
            netUpvotes,
            reviewResponseStatus,
            responseMessage,
            title
        } = review;
        const hasResponse = Boolean(responseMessage) && responseMessage.length > 0;
        const validResponse = hasResponse && reviewResponseStatus === 'OK';

        return (
            <div className="ReviewItem">
                <StyledHalfDiv>
                    <Fragment>
                        <StaticStarRating filledStars={starLevel} totalStars={5} size="md" />
                    </Fragment>
                    <Fragment>
                        <StyledTitleText size="sm">{title}</StyledTitleText>
                    </Fragment>
                </StyledHalfDiv>
                <StyledHalfDivDark as="span">
                    <Text size="sm">{dateUtils.formatDateToString(created)}</Text>
                </StyledHalfDivDark>
                <Row>
                    <div
                        ref={(el) => (this.messageContainer = el)}
                        className={cx('ReviewItem-message', { 'ReviewItem-review-truncated': showMore })}
                    >
                        <Text size="sm">{reviewMessage}</Text>
                    </div>
                </Row>
                {hasTruncatedContent && (
                    <StyledShowMoreSpan>
                        <LinkToggle onClick={this.handleToggleReview}>
                            <Text size="sm">{showMore ? 'More' : 'Less'}</Text>
                        </LinkToggle>
                    </StyledShowMoreSpan>
                )}
                {validResponse && !disableResponse && <ReviewResponseMessage responseMessage={responseMessage} />}
                {!disableResponse && (
                    <ReviewResponse
                        reviewId={reviewId}
                        reviewResponseStatus={reviewResponseStatus}
                        hasResponse={hasResponse}
                    />
                )}
                <ReviewFeedback
                    reviewId={reviewId}
                    userVote={userVote}
                    netUpvotes={netUpvotes}
                    reviewType={reviewType}
                />
                <Divider />
            </div>
        );
    }
}

export default connect()(ReviewItem);
