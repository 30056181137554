// @ts-nocheck
/* eslint-enable */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from 'app/shared/core/Button';
import Row from 'app/shared/core/Row';
import Container from 'app/shared/core/Container';
import 'app/shared/modules/hdp/ChatButton.scss';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import { analyticsEvent } from 'app/client/universal-analytics';
import HdpContentWrapper from 'app/shared/modules/hdp/HdpContentWrapper';

class ChatButton extends React.Component {
    static propTypes = {
        listing: PropTypes.object.isRequired,
        showCopy: PropTypes.bool
    };

    lotIdsToWidgetUrls = {
        lotId: 'wid' //this contains lotIdEncoded as key and wid as the value.
    };

    static defaultProps = {
        listing: {},
        showCopy: false
    };

    constructor(props) {
        super(props);
        this.displayName = 'ChatButton';
        this.state = {
            chatWindow: null
        };
    }

    componentDidMount() {
        const { listing, showCopy } = this.props;
        if (this.shouldShowChatWidget(listing) && !showCopy) {
            this.props.dispatch(analyticsEvent(gaEvents.SHOW_CHAT_BUTTON));
        }
    }

    componentWillUnmount() {
        const { chatWindow } = this.state;

        /*
         * If we want to close the chat window when changing pages, uncomment
        if (chatWindow && !chatWindow.closed && typeof(window) !== "undefined") {
            var shouldCloseChatWindow = window.confirm("You have a chat open with this property. Do you also want to close the chat?");
            if (shouldCloseChatWindow) {
                chatWindow.close();
            }
        }*/
    }

    shouldShowChatWidget = (listing) => {
        if (!listing) {
            return false;
        }

        return Object.keys(this.lotIdsToWidgetUrls).includes(listing.maloneLotIdEncoded);
    };

    openChatWidget = () => {
        const { listing } = this.props;

        this.props.dispatch(analyticsEvent(gaEvents.SHOW_CHAT_WIDGET));
        var url =
            'https://uc-widget.realpageuc.com/widget/window?pnw=true&wid=' +
            this.lotIdsToWidgetUrls[listing.maloneLotIdEncoded];
        var thisChatWindow = window.open(url, '_blank', 'location=yes,height=530,width=375,scrollbars=yes,status=yes');

        this.setState({
            chatWindow: thisChatWindow
        });
    };

    render() {
        const { listing, showCopy, inContactBox } = this.props;
        if (!this.shouldShowChatWidget(listing)) {
            return null;
        }

        if (inContactBox) {
            return (
                <Row size="md" className="Chat-Row">
                    {showCopy && <div className="Chat-Copy">Have questions? Try chatting with the property!</div>}
                    <Button full size="md" btnType="tertiary" onClick={this.openChatWidget}>
                        Start a Live Chat
                    </Button>
                </Row>
            );
        } else {
            return (
                <HdpContentWrapper fullContent active>
                    <Container size="md">
                        <Button full size="md" btnType="tertiary" onClick={this.openChatWidget}>
                            Start a Live Chat
                        </Button>
                    </Container>
                </HdpContentWrapper>
            );
        }
    }
}

export default connect()(ChatButton);
