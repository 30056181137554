// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Input from 'app/shared/core/Input';
import numberUtils from 'app/shared/utils/numberUtils';

class InputPhoneNumber extends React.Component {
    static displayName = 'InputPhoneNumber';
    static propTypes = {
        onChange: PropTypes.func,
        value: PropTypes.string
    };

    handleTextInput = (e) => {
        e.target.value = numberUtils.cleanPhoneNumber(e.target.value);
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    };

    render() {
        return (
            <Input
                {...this.props}
                onChange={(e) => this.handleTextInput(e)}
                type="tel"
                value={numberUtils.formatPhoneNumber(this.props.value)}
            />
        );
    }
}

export default connect()(InputPhoneNumber);
