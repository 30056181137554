// @ts-nocheck
/* eslint-enable */
// App
import React, { Fragment } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { inline } from 'app/shared/styles/_spacing';

// Components
import ImageLoader from 'app/shared/modules/photo-gallery/ImageLoader';
import LazyLoader from 'app/shared/modules/LazyLoader';
import LinkController from 'app/shared/modules/LinkController';
import Text from 'app/shared/core/Text';
import ImpressionTracking from 'app/shared/modules/ImpressionTracking';

// Misc / Utils
import './style.scss';
import { NYDOSText } from 'app/shared/modules/listing-cards/ListingCard/styles';
import IconCheckmark from 'images/icons/checkmark-green.svg';

// Lodash
import map from 'lodash/map';
import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import isNull from 'lodash/isNull';

const { array, bool, func, string } = PropTypes;

const StyledDiv = styled.div`
    ${inline._1x};
`;

const renderCheckmark = (
    <img src={IconCheckmark} height={'16px'} width={'16px'} className="ComparisonTable-checkmark" alt="included" />
);

const sharedNonLinkedHeaderMarkup = ({ lazyLoadImage, title, image, rawListingDetails }) => {
    const shouldDisplayBrokerInfo = Boolean(
        rawListingDetails.broker.isBrokerExclusiveListing && rawListingDetails.broker.companyName
    );
    return (
        <Fragment>
            <div className={cx('ComparisonTable-header-photo-container')}>
                {lazyLoadImage ? (
                    <LazyLoader placeholderClassname="ComparisonTable-header-photo-placeholder">
                        <ImageLoader
                            className={cx('ComparisonTable-header-photo')}
                            altText=""
                            titleText={null}
                            image={image}
                        />
                    </LazyLoader>
                ) : (
                    <ImageLoader
                        className={cx('ComparisonTable-header-photo')}
                        altText=""
                        titleText={null}
                        image={image}
                    />
                )}
            </div>
            <div className={cx('ComparisonTable-header-title-container')}>
                <Text htmlTag="label" className={cx('ComparisonTable-header-title-secondary')}>
                    {'This listing\n'}
                </Text>
                <Text htmlTag="h3" className="ComparisonTable-header-title-non-linked">
                    {title}
                </Text>
                {shouldDisplayBrokerInfo && (
                    <>
                        <NYDOSText>{rawListingDetails.broker.companyName}</NYDOSText>
                        {rawListingDetails.broker.companyPhoneNumber && (
                            <NYDOSText>{rawListingDetails.broker.companyPhoneNumber}</NYDOSText>
                        )}
                    </>
                )}
            </div>
        </Fragment>
    );
};

const sharedLinkedHeaderMarkup = ({ onClick, rawListingDetails, link, title, image, trackImpression }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks

    const shouldDisplayBrokerInfo = Boolean(
        rawListingDetails.broker.isBrokerExclusiveListing && rawListingDetails.broker.companyName
    );
    return (
        <Fragment>
            <div onClick={(e) => onClick(e, rawListingDetails)}>
                <LinkController
                    onClick={(e) => {
                        e.preventDefault();
                    }}
                    to={link}
                >
                    <div className={cx('ComparisonTable-header-photo-container')}>
                        {LazyLoader ? (
                            <LazyLoader placeholderClassname="ComparisonTable-header-photo-placeholder">
                                <ImageLoader
                                    className={cx('ComparisonTable-header-photo')}
                                    altText=""
                                    titleText={null}
                                    image={image}
                                />
                            </LazyLoader>
                        ) : (
                            <ImageLoader
                                className={cx('ComparisonTable-header-photo')}
                                altText=""
                                titleText={null}
                                image={image}
                            />
                        )}
                    </div>
                    <div className={cx('ComparisonTable-header-title-container')}>
                        <Text className={cx('ComparisonTable-header-title')} htmlTag="h3">
                            {title}
                        </Text>
                    </div>
                    <ImpressionTracking analyticsCallback={() => trackImpression(rawListingDetails)} />
                </LinkController>
                {shouldDisplayBrokerInfo && (
                    <>
                        <NYDOSText>{rawListingDetails.broker.companyName}</NYDOSText>
                        {rawListingDetails.broker.companyPhoneNumber && (
                            <NYDOSText>{rawListingDetails.broker.companyPhoneNumber}</NYDOSText>
                        )}
                    </>
                )}
            </div>
        </Fragment>
    );
};

const header = (listing, colIdx, onClick, lazyLoadImage, hideTable, trackImpression) => {
    const { rawListingDetails = {}, link, image, title } = listing;
    const { aliasEncoded } = rawListingDetails;

    const nonLinkedHeaderItem = hideTable ? (
        <StyledDiv key={`div-${aliasEncoded}-${link}`}>
            {sharedNonLinkedHeaderMarkup({ lazyLoadImage, title, image, rawListingDetails })}
        </StyledDiv>
    ) : (
        <th className={cx('ComparisonTable-header-first-item')} key={`th-${aliasEncoded}-${link}`}>
            {sharedNonLinkedHeaderMarkup({ lazyLoadImage, title, image, rawListingDetails })}
        </th>
    );

    const linkedHeaderItem = hideTable ? (
        <div className={cx('ComparisonTable-header-standard-item')} key={`div-${aliasEncoded}-${link}`}>
            {sharedLinkedHeaderMarkup({ onClick, rawListingDetails, link, title, image, trackImpression })}
        </div>
    ) : (
        <th className={cx('ComparisonTable-header-standard-item')} key={`th-${aliasEncoded}-${link}`}>
            {sharedLinkedHeaderMarkup({ onClick, rawListingDetails, link, title, image, trackImpression })}
        </th>
    );

    if (colIdx === 0) {
        return nonLinkedHeaderItem;
    }

    return linkedHeaderItem;
};

const bodyCategoryCell = (category, rowIdx) => {
    const standardCategoryCell = <div>{category}</div>;

    const categoryWithSubArrayCell = map(category, (subCat, catIdx) => {
        const len = category.length - 1;
        return (
            <div
                className={cx({
                    'ComparisonTable-body-category-subcat-first-cell': catIdx === 0,
                    'ComparisonTable-body-category-subcat-cell': catIdx > 0 && catIdx < len,
                    'ComparisonTable-body-category-subcat-last-cell': catIdx === len
                })}
                key={`div-${catIdx}-${subCat}`}
            >
                {subCat}
            </div>
        );
    });

    return (
        <td
            className={cx('ComparisonTable-body-category-cell', 'ComparisonTable-table-divider', {
                'ComparisonTable-body-category-first-row': rowIdx === 0
            })}
        >
            {isArray(category) && categoryWithSubArrayCell}
            {!isArray(category) && standardCategoryCell}
        </td>
    );
};

const bodyCell = (category, item, placeholder, rowIdx, colIdx) => {
    const standardItemBodyCell = () => {
        const data = item[category];
        const hasNoDataPoint = isNull(data) || data === false;

        if (isBoolean(data) && data === true) {
            return renderCheckmark;
        }

        return (
            <div
                className={cx({
                    'ComparisonTable-body-placeholder-cell': hasNoDataPoint
                })}
            >
                {!hasNoDataPoint ? data : placeholder}
            </div>
        );
    };

    const itemWithSubArrayCell = () => {
        return map(category, (subCat, catIdx) => {
            const len = category.length - 1;
            const data = item[subCat];
            const hasNoDataPoint = isNull(data) || data === false;

            if (isBoolean(data) && data === true) {
                return renderCheckmark;
            }

            return (
                <div
                    key={`div-${rowIdx}-${colIdx}-${catIdx}-${subCat}-${data}`}
                    className={cx({
                        'ComparisonTable-body-category-subcat-first-cell': catIdx === 0,
                        'ComparisonTable-body-category-subcat-cell': catIdx > 0 && catIdx < len,
                        'ComparisonTable-body-category-subcat-last-cell': catIdx === len,
                        'ComparisonTable-body-placeholder-cell': hasNoDataPoint
                    })}
                >
                    {!hasNoDataPoint ? data : placeholder}
                </div>
            );
        });
    };

    return (
        <td
            className={cx('ComparisonTable-body-data-cell', {
                'ComparisonTable-body-data-first-row': rowIdx === 0,
                'ComparisonTable-body-data-first-col': colIdx === 0
            })}
            key={`td-${rowIdx}-${colIdx}-${category}-${item[category]}`}
        >
            {isArray(category) && itemWithSubArrayCell()}
            {!isArray(category) && standardItemBodyCell()}
        </td>
    );
};

const tableRow = (category, data, placeholder, rowIdx) => {
    return (
        <tr key={`tr-${rowIdx}-${category}`}>
            {bodyCategoryCell(category, rowIdx)}
            {map(data, (item, colIdx) => {
                return bodyCell(category, item, placeholder, rowIdx, colIdx);
            })}
        </tr>
    );
};

const ComparisonTable = ({
    categories,
    className,
    data,
    hideTable,
    lazyLoadImage,
    onClick,
    placeholder,
    striped,
    trackImpression,
    ariaLabel = null
}) => {
    const generateColgroup = (
        <colgroup>
            <col className={cx('ComparisonTable-colgroup-category')} />
            {map(data, (_, colIdx) => {
                return (
                    <col
                        className={cx(
                            colIdx === 0
                                ? 'ComparisonTable-colgroup-first-col'
                                : 'ComparisonTable-colgroup-standard-col'
                        )}
                        key={`col-${colIdx}`}
                    />
                );
            })}
        </colgroup>
    );

    const generateHeader = (
        <thead className={cx('ComparisonTable-header')}>
            <tr>
                <th className={cx('ComparisonTable-table-divider')}>{/* INTENTIONALLY BLANK */}</th>
                {map(data, (listing, colIdx) => {
                    return header(listing, colIdx, onClick, lazyLoadImage, hideTable, trackImpression);
                })}
            </tr>
        </thead>
    );

    const generateBody = (
        <tbody
            className={cx('ComparisonTable-body', {
                'ComparisonTable-body-striped': striped
            })}
        >
            {map(categories, (category, rowIdx) => {
                return tableRow(category, data, placeholder, rowIdx);
            })}
        </tbody>
    );

    return (
        <Fragment>
            {hideTable ? (
                <div className="ComparisonTable-tableless-header">
                    {map(data, (listing, colIdx) => {
                        return header(listing, colIdx, onClick, lazyLoadImage, hideTable);
                    })}
                </div>
            ) : (
                <table className={cx('ComparisonTable', className)} aria-label={ariaLabel}>
                    {generateColgroup}
                    {generateHeader}
                    {generateBody}
                </table>
            )}
        </Fragment>
    );
};

export default ComparisonTable;

ComparisonTable.propTypes = {
    categories: array,
    className: string,
    data: array,
    lazyLoadImage: bool,
    onClick: func,
    placeholder: string,
    striped: bool,
    trackImpression: func
};

ComparisonTable.defaultProps = {
    categories: [],
    className: '',
    data: [],
    lazyLoadImage: false,
    onClick: () => { },
    placeholder: '',
    striped: false,
    trackImpression: () => { }
};
