// @ts-nocheck
/* eslint-enable */
export const getPasswordStrength = (password) => {
    const numberRegex = new RegExp(/\d/gi);
    const upperRegex = new RegExp(/[A-Z]/g);
    const lowerRegex = new RegExp(/[a-z]/g);

    const hasNumber = numberRegex.test(password);
    const hasUpperCase = upperRegex.test(password);
    const hasLowerCase = lowerRegex.test(password);
    const hasGoodLength = password.length >= 8;

    //weak, fair, good, great

    if (!hasNumber || !hasGoodLength || (!hasLowerCase && !hasUpperCase)) {
        return 'weak';
    } else if (password.length > 10) {
        return 'great';
    } else if (hasUpperCase && !hasLowerCase) {
        return 'fair';
    } else if (hasLowerCase && !hasUpperCase) {
        return 'fair';
    } else {
        // has number, upper and lower, but less than 10 digits
        return 'good';
    }
};

export const isPasswordMixed = (password) => {
    const numberRegex = new RegExp(/\d/gi);
    const charRegex = new RegExp(/[a-z]/gi);

    return numberRegex.exec(password) !== null && charRegex.exec(password) !== null;
};
