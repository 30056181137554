// @ts-nocheck
/* eslint-enable */
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import HdpContentWrapper from 'app/shared/modules/hdp/HdpContentWrapper';
import { highlightKeywords } from './controller';
import { DescriptionText } from './styles';

const HdpDescription = React.memo(() => {
    const filterKeywords = useSelector((state) => state.filter.keywords);
    const listing = useSelector((state) => state.currentListingDetails.currentListing);
    const { active, address = {}, details = {}, displayName } = listing;
    const { fullDescription } = details;
    const { hideStreet } = address;
    const isExpired = !active;

    const descriptionHtml = useCallback(() => {
        return highlightKeywords({
            filterKeywords,
            fullDescription
        });
    }, [filterKeywords, fullDescription]);

    if (!fullDescription) {
        return null;
    }

    return (
        <HdpContentWrapper
            active={isExpired}
            collapsable
            header={`About ${hideStreet ? 'this listing' : displayName}`}
            headerHtmlTag="h2"
            style={{ whitespace: 'pre-wrap' }}
        >
            <DescriptionText
                dangerouslySetInnerHTML={{
                    __html: descriptionHtml()
                }}
                id="HdpDescriptionContent"
            />
        </HdpContentWrapper>
    );
});

export default HdpDescription;
