// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './style.scss';
import styled from 'styled-components';
import AppActions from 'app/shared/flux/actions/AppActions';
import Text from 'app/shared/core/Text';
import Row from 'app/shared/core/Row';
import Linker from 'app/shared/modules/Linker';
import ReportReview from 'app/shared/modules/ReportReview';
import IconReport from 'images/icons/report-grey.svg';
import IconUpvote from 'images/icons/upvote-star.svg';


const logger = getLogger('modules/reviews');

const propTypes = {
    userVote: PropTypes.string,
    reviewId: PropTypes.string,
    netUpvotes: PropTypes.number
};
const StyledReviewFeedbackDiv = styled.div`
    text-align: right;
`;
class ReviewFeedback extends Component {
    constructor(props) {
        super(props);
        const { userVote, netUpvotes } = props;

        this.state = {
            reportFormOpen: false,
            userVote,
            reported: false,
            upvotes: netUpvotes
        };
    }
    onReviewVoteClick = (action) => {
        const { reviewId, dispatch, loggedIn, reviewType } = this.props;
        const { userVote } = this.state;
        const resetUpvote = action === 'upvote' && userVote === 'UPVOTED';

        if (!loggedIn) {
            dispatch(
                AppActions.showPopupModal('PromptLogin', {
                    caption: "You'll need to sign in or register to upvote a review."
                })
            );

            return;
        }

        if (action === 'upvote' && userVote !== 'UPVOTED') {
            return dispatch(AppActions.voteReview({ reviewId, vote: 'UPVOTED', reviewType }))
                .then((data) => {
                    if (!data.success) {
                        dispatch(
                            AppActions.toggleNotificationMessage({
                                content: 'Sorry! We encountered an error.',
                                notificationStyle: 'alert'
                            })
                        );

                        return;
                    }
                    this.setState((state) => {
                        return {
                            userVote: 'UPVOTED',
                            upvotes: state.upvotes + 1
                        };
                    });
                })
                .catch((err) => {
                    dispatch(
                        AppActions.toggleNotificationMessage({
                            content: 'Sorry! We encountered an error.',
                            notificationStyle: 'alert'
                        })
                    );
                    logger.warn(err, 'Error submitting user vote for listing review.');
                });
        } else if (resetUpvote) {
            const updateVoteCount = resetUpvote ? -1 : 1;
            return dispatch(AppActions.voteReview({ reviewId, vote: 'RESET', reviewType }))
                .then((data) => {
                    if (!data.success) {
                        dispatch(
                            AppActions.toggleNotificationMessage({
                                content: 'Sorry! We encountered an error.',
                                notificationStyle: 'alert'
                            })
                        );

                        return;
                    }
                    this.setState((state) => {
                        return {
                            userVote: 'RESET',
                            upvotes: state.upvotes + updateVoteCount
                        };
                    });
                })
                .catch((err) => {
                    dispatch(
                        AppActions.toggleNotificationMessage({
                            content: 'Sorry! We encountered an error.',
                            notificationStyle: 'alert'
                        })
                    );
                    logger.error(err.stack, 'Error resetting user vote for listing review.');
                });
        }
    };
    handleReportToggle = () => {
        this.setState((state) => {
            return {
                reportFormOpen: !state.reportFormOpen
            };
        });
    };
    handleReportSubmit = (comment) => {
        const { reviewId, dispatch, loggedIn, reviewType } = this.props;

        if (!loggedIn) {
            dispatch(
                AppActions.showPopupModal('PromptLogin', {
                    caption: 'Sign in or register to report this review.'
                })
            );

            return false;
        }

        return dispatch(AppActions.reportReview({ reviewId, comment, reviewType }))
            .then((data) => {
                if (!data.success) {
                    dispatch(
                        AppActions.toggleNotificationMessage({
                            content: 'Sorry! We encountered an error.',
                            notificationStyle: 'alert'
                        })
                    );

                    return false;
                }
                this.setState(() => {
                    return {
                        reportFormOpen: false,
                        reported: true
                    };
                });

                dispatch(
                    AppActions.toggleNotificationMessage({
                        content: 'Your report has been submitted.'
                    })
                );
            })
            .catch((err) => {
                dispatch(
                    AppActions.toggleNotificationMessage({
                        content: 'Sorry! We encountered an error.',
                        notificationStyle: 'alert'
                    })
                );
                logger.warn(err, 'Error submitting user report for listing review.');
            });
    };
    render() {
        const { reportFormOpen, reported, upvotes } = this.state;
        const hasUpvotes = upvotes > 0;

        return (
            <div className="ReviewFeedback">
                <StyledReviewFeedbackDiv>
                    <div className="ReviewFeedback-item">
                        <Linker
                            linkType="secondary"
                            onClick={() => this.onReviewVoteClick('upvote')}
                            useButton
                        >
                            <Text size="sm">
                                <img
                                    className="ReviewFeedback-icon"
                                    src={IconUpvote}
                                    height={16}
                                    width={16}
                                    alt="Upvote review"
                                />
                                Helpful
                                {hasUpvotes && <span> ({upvotes})</span>}
                            </Text>
                        </Linker>
                    </div>
                    {'  '}
                    <div className="ReviewFeedback-item">
                        <Text size="sm">
                            <Linker
                                disabled={reported}
                                linkType="secondary"
                                onClick={this.handleReportToggle}
                                useButton
                            >
                                <Text size="sm">
                                    <img
                                        className="ReviewFeedback-icon"
                                        src={IconReport}
                                        height={16}
                                        width={16}
                                        alt="Report review"
                                    />
                                    <span>{reported ? 'Reported' : 'Report'}</span>
                                </Text>
                            </Linker>
                        </Text>
                    </div>
                </StyledReviewFeedbackDiv>
                {reportFormOpen && !reported && (
                    <Row>
                        <ReportReview onReportSubmit={this.handleReportSubmit} />
                    </Row>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.user.loggedIn
    };
};

ReviewFeedback.propTypes = propTypes;
export default connect(mapStateToProps)(ReviewFeedback);
