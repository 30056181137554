// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import { array, func, object, number, string } from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';

import BackgroundMap from 'app/shared/modules/map/BackgroundMap';
import gmapUtils from 'app/client/utils/map/gmapUtils';
import './style.scss';

class MiniMap extends Component {
    static propTypes = {
        area: object,
        className: string,
        handleMapClick: func,
        height: number,
        markers: array,
        scale: number,
        width: number,
        zoom: number
    };

    static defaultProps = {
        handleMapClick: () => { },
        width: 2048,
        height: 2048,
        markers: [],
        scale: 2
    };

    constructor(props) {
        super(props);

        this.state = {
            mapUrl: null
        };
    }

    componentDidMount() {
        this.setMapUrl();
    }

    componentDidUpdate(prevProps) {
        for (const key in this.props) {
            if (this.props[key] !== prevProps[key]) {
                this.setMapUrl();
                break;
            }
        }
    }

    setMapUrl() {
        const { area, autoZoom, height, lat, lon, markers, path, polyline, scale, width, zoom } = this.props;

        const mapDimensions = {
            width: width || 234,
            height: height || 176
        };
        let displayLat, displayLon;

        if (!area) {
            displayLat = lat;
            displayLon = lon;
        } else {
            displayLat = (Number(area.maxLat) + Number(area.minLat)) / 2;
            displayLon = (Number(area.maxLon) + Number(area.minLon)) / 2;
        }

        if (!displayLat || !displayLon) {
            return;
        }

        let bgMapOptions = {
            width: Math.floor(mapDimensions.width),
            height: Math.floor(mapDimensions.height),
            lat: displayLat,
            lon: displayLon,
            scale,
            markers,
            path,
            polyline
        };

        // Google Maps can automatically determine correct zoom for
        // all markers if no zoom level is provided. Otherwise, we
        // calculate a desired zoom level.
        if (!autoZoom) {
            bgMapOptions.zoom =
                zoom ||
                gmapUtils.getZoomForBoundingBox({
                    maxLon: area.maxLon,
                    minLon: area.minLon,
                    maxLat: area.maxLat,
                    minLat: area.minLat,
                    mapDimensions
                });
        }

        this.props.dispatch(BackgroundMap(bgMapOptions)).then((result = {}) => {
            const { data } = result;
            this.setState({ mapUrl: data.signedUrl });
        });
    }

    render() {
        let bgStyle = {};
        const { className } = this.props;

        if (this.state.mapUrl) {
            bgStyle.backgroundImage = `url(${this.state.mapUrl})`;
            bgStyle.height = `${this.props.height}px`;
            bgStyle.backgroundSize = 'cover';
        }

        return <div className={cx('MiniMap', className)} onClick={this.props.handleMapClick} style={bgStyle} />;
    }
}

export default connect()(MiniMap);
