import styled from 'styled-components';
import TooltipBase from 'app/shared/modules/Tooltip';

export const HighlightsList = styled.ul`
    width: 100%;
    position: relative;
`;

export const HighlightsListItem = styled.li`
    padding-bottom: 16px;
    padding-right: 8px;
    min-width: 0;
`;

export const Tooltip = styled(TooltipBase)`
    width: 100%;
`;

export const HighlightsText = styled.span`
    display: block;
    font-size: 16px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const HighlightsIcon = styled.img`
    float: left;
    width: 24px;
    text-align: center;
    margin-right: 16px;
`;
