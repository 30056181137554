// @ts-nocheck
/* eslint-enable */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { yieldCallback } from '@zillow/yield-callback';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styled, { css } from 'styled-components';
import { stacked, padding } from 'app/shared/styles/_spacing';
import { colors } from 'app/shared/styles/_colors';
import './style.scss';
import ContactPhone from 'app/shared/modules/hdp/form/Contact/ContactPhone';
import IconArrowDown from 'images/icons/arrow-down.svg';
import IconVerified from 'images/verified.svg';
import SkeletonContactListedBy from 'app/shared/modules/skeleton/SkeletonContactListedBy';
import Text from 'app/shared/core/Text';

const StyledContactPhone = styled(ContactPhone)`
    ${stacked._2x};
    ${padding.top._2x};
    ${(props) =>
        props &&
        props.isPopup &&
        css`
            ${stacked._1x};
        `};
    font-weight: 700;
`;

const StyledText = styled(Text)`
    ${stacked._1x};
    color: ${colors['$hpx-grey-600']};
`;
class ContactListedBy extends Component {
    static propTypes = {
        details: PropTypes.object,
        listedBy: PropTypes.object.isRequired,
        onClickPhoneContact: PropTypes.func,
        trusted: PropTypes.bool,
        waitlisted: PropTypes.bool,
        isPopup: PropTypes.bool
    };

    handleClick = yieldCallback(() => {
        const { onSetActiveModal } = this.props;
        onSetActiveModal('landlordInfo');
    });

    render() {
        const { details, listedBy, onClickPhoneContact, trusted, waitlisted, isPopup, isXlScreen } = this.props;
        const logoUrl = listedBy.logoUrlCdn || details.logoUrlCdn;
        const inlineMessage = isXlScreen && !isPopup;
        if (!listedBy) {
            return <SkeletonContactListedBy />;
        }
        const displayName =
            listedBy.contactName && listedBy.contactName !== 'Leasing Agent' // default contact name
                ? listedBy.contactName
                : 'Contact Manager';

        return (
            <div className={cx({ 'ContactListedBy-popup': isPopup })}>
                <div className="ContactListedBy">
                    <div className="ContactListedBy-left">
                        <button
                            className="ContactListedBy-info"
                            onClick={this.handleClick}
                            onTouchStart={this.handleClick}
                        >
                            {!inlineMessage && (
                                <StyledText size="sm" htmlTag="h2">
                                    Message
                                </StyledText>
                            )}
                            <div className="ContactListedBy-details">
                                <div className="ContactListedBy-name">
                                    <Text
                                        id="dialog-title"
                                        className="ContactListedBy-name-text"
                                        size="lg"
                                        htmlTag={inlineMessage ? 'h2' : null}
                                    >
                                        {inlineMessage ? 'Message ' : ''}
                                        {displayName}
                                    </Text>
                                    <img className="ContactListedBy-caret" src={IconArrowDown} alt="" />
                                </div>
                                {listedBy.companyName && <Text size="sm">{listedBy.companyName}</Text>}
                                {trusted && (
                                    <div className="ContactListedBy-verified">
                                        <img src={IconVerified} className="ContactListedBy-verified-icon" />
                                        Verified listing
                                    </div>
                                )}
                            </div>
                        </button>
                        {!waitlisted && <StyledContactPhone isPopup={isPopup} onClick={onClickPhoneContact} />}
                    </div>
                    {logoUrl && (
                        <div className="ContactListedBy-logo-cont">
                            <div
                                className={cx('ContactListedBy-logo', { 'ContactListedBy-logo-popup': isPopup })}
                                onClick={this.handleClick}
                            >
                                {logoUrl && (
                                    <img
                                        className="ContactListedBy-listedby-logo"
                                        src={logoUrl}
                                        height="64"
                                        width="64"
                                        alt=""
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
                {waitlisted && (
                    <Fragment>
                        <div className="ContactForm-waitlist-header">Join the waitlist</div>
                        <div
                            className={cx('ContactForm-waitlist-text', {
                                'ContactForm-waitlist-text-popup': isPopup
                            })}
                        >
                            This listing is in high demand. Don’t fret! Get on the waitlist and we’ll notify you and
                            send your contact information to the property once there’s an opening. Happy house hunting!
                        </div>
                    </Fragment>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isXlScreen: state.app.device.screenWidth === 'xl'
    };
};

export default connect(mapStateToProps)(ContactListedBy);
