// @ts-nocheck
/* eslint-enable */
import IconBike from 'images/icons/bike.svg';
import IconBus from 'images/icons/bus.svg';
import IconCar from 'images/icons/car.svg';
import IconFeet from 'images/icons/feet.svg';

const INVALID_COMMUTE_TIME = '2+ hr';
const NO_COMMUTE_DATA = '—';

const controller = {
    formatTime(mins) {
        if (mins === 120 || mins === '>120') {
            return INVALID_COMMUTE_TIME;
        } else if (mins > 60) {
            return `1h ${mins % 60}m`;
        } else if (mins === 60) {
            return `1 h`;
        } else if (mins < 60) {
            return `${mins} min`;
        } else {
            return NO_COMMUTE_DATA;
        }
    },

    getTransitDetails({ gmapLinks, travelTime }) {
        return {
            byCar: {
                disableClick: controller.formatTime(travelTime.driving) === INVALID_COMMUTE_TIME,
                icon: IconCar,
                link: gmapLinks.driving,
                time: controller.formatTime(travelTime.driving),
                title: 'by car',
                modeTitle: 'driving'
            },
            byTransit: {
                disableClick: controller.formatTime(travelTime.transit) === INVALID_COMMUTE_TIME,
                icon: IconBus,
                link: gmapLinks.transit,
                time: controller.formatTime(travelTime.transit),
                title: 'by transit',
                modeTitle: 'transit'
            },
            byFoot: {
                disableClick: controller.formatTime(travelTime.walking) === INVALID_COMMUTE_TIME,
                icon: IconFeet,
                link: gmapLinks.walking,
                time: controller.formatTime(travelTime.walking),
                title: 'on foot',
                modeTitle: 'walking'
            },
            byBike: {
                disableClick: controller.formatTime(travelTime.cycling) === INVALID_COMMUTE_TIME,
                icon: IconBike,
                link: gmapLinks.cycling,
                time: controller.formatTime(travelTime.cycling),
                title: 'by bike',
                modeTitle: 'cycling'
            }
        };
    }
};

export default controller;
