import React from 'react';

// Styles
import * as S from './styles';

interface Props {
    index: number
    count: number
    onGoToItem: (i: number) => void
}

const Dots = ({ index, count, onGoToItem }: Props) => {
    let dots = [];
    for (let i = 0; i < count; i++) {
        dots.push(
            <S.Dot
                key={i}
                isActive={i === index}
                onClick={(e) => {
                    e.stopPropagation();
                    onGoToItem(i);
                }}
            />
        );
    }

    return <S.Container>{dots}</S.Container>;
};

export default Dots;
