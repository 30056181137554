// @ts-nocheck
/* eslint-enable */
// App
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// Lodash
import map from 'lodash/map';

// Misc / utils
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import * as S from 'app/shared/modules/hdp/form/Contact/ContactSimilarListingsStyles';

const ContactSimilarListings = ({ similarListings }) => {
    // Redux
    const dispatch = useDispatch();

    const shownSimilarListings = similarListings.slice(0, 3);

    useEffect(() => {
        if (shownSimilarListings.length > 0) {
            dispatch(
                analyticsEvent(gaEvents.SIMILAR_LISTING_DISPLAYED, {
                    label: shownSimilarListings.length
                })
            );
        }
    }, []);

    const handleSimilarListingsClick = (listing) => {
        dispatch(
            analyticsEvent(gaEvents.SIMILAR_LISTING_CLICK, {
                label: listing.aliasEncoded
            })
        );
    };

    if (shownSimilarListings.length === 0) {
        return null;
    }

    return (
        <article>
            <S.RecommendedText>Recommended for you</S.RecommendedText>
            <S.Divider />
            <S.ListContainer>
                {map(shownSimilarListings, (similarListing) => {
                    return (
                        <S.ListingWrapper
                            key={similarListing.aliasEncoded}
                            listing={similarListing}
                            clickHandler={handleSimilarListingsClick}
                        />
                    );
                })}
            </S.ListContainer>
        </article>
    );
};

ContactSimilarListings.propTypes = {
    similarListings: PropTypes.array
};

export default ContactSimilarListings;
