// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { useSelector } from 'react-redux';
import { css } from 'app/styled-system/css';
import Container from 'app/shared/core/Container';
import Text from 'app/shared/core/Text';
import {
    listingUtils_summaryPriceText,
    listingUtils_bedRange,
    listingUtils_bathSqFtRange
} from 'app/shared/utils/listingUtils';

const getDetailedRowCSS = (props) => css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: props?.isRoomForRent ? 'flex-start' : 'center',
    margin: '0px 0px 16px 0px',
    paddingTop: '16px'
});

const getDetailItemCSS = (props) => css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    color: 'hpxBlue600',
    marginRight: '32px',
    md: props.isExtraCompact ? {} : {
        marginRight: props.isCompact ? '50px' : '60px'
    }
});

const detailValueCSS = css({
    fontWeight: '600'
});


const detailLabelCSS = css({
    color: 'hpxGrey500'
});

const HdpSummaryDetails = ({ listing }) => {
    const { details = {}, listingMinMaxPriceBeds = {}, pricing = {}, listingType } = listing;
    const { summary = {} } = pricing;
    const { bedsLow, bedsHigh, bathsLow, bathsHigh, sqFtLow, sqFtHigh } = summary;
    const { minBeds, minBaths, minSqft, maxBeds, maxBaths, maxSqft } = listingMinMaxPriceBeds;
    const { roomSqFt } = details.roomForRent.roomDetails;
    const { roommatesCount } = details.roomForRent.roommateDetails;
    const screenWidth = useSelector((state) => state.app.device.screenWidth);
    const priceText = listingUtils_summaryPriceText(summary);
    const bedText = listingUtils_bedRange(bedsLow || minBeds, bedsHigh || maxBeds);
    const bathText = listingUtils_bathSqFtRange(bathsLow || minBaths, bathsHigh || maxBaths);
    const sqftText = listingUtils_bathSqFtRange(sqFtLow || minSqft, sqFtHigh || maxSqft);
    const showSqft = !sqftText.toString()?.includes('-') && (!roomSqFt || screenWidth === 'xl');
    let roommatesCountLabel = '';
    if (roommatesCount) {
        const isSingular = Number(roommatesCount) === 1;

        if (screenWidth === 'xl') {
            roommatesCountLabel = isSingular ? 'Housemate' : 'Housemates';
        } else {
            roommatesCountLabel = isSingular ? 'House mate' : 'House mates';
        }
    }

    const valueCount = [priceText, bedText, bathText, sqftText, roomSqFt, roommatesCount].filter((value) =>
        Boolean(value)
    ).length;
    const isCompact = valueCount === 4;
    const isExtraCompact = valueCount > 4;

    return (
        <div className="HdpSummaryDetails">
            <Container>
                <div className={getDetailedRowCSS({ isRoomForRent: listingType === 'room' })}>
                    <div className={getDetailItemCSS({ isCompact: isCompact && priceText.length > 5, isExtraCompact })}>
                        <Text className={detailValueCSS} responsive={{ smAndUp: 'md', mdAndUp: 'lg' }}>{priceText}</Text>
                        <Text className={detailLabelCSS}>Monthly Rent</Text>
                    </div>
                    <div className={getDetailItemCSS({ isCompact: isCompact && bedText.length > 1, isExtraCompact })}>
                        <Text className={detailValueCSS} responsive={{ smAndUp: 'md', mdAndUp: 'lg' }}>{bedText}</Text>
                        <Text className={detailLabelCSS}>Beds</Text>
                    </div>
                    <div className={getDetailItemCSS({ isCompact: isCompact && bathText.length > 1, isExtraCompact })}>
                        <Text className={detailValueCSS} responsive={{ smAndUp: 'md', mdAndUp: 'lg' }}>{bathText}</Text>
                        <Text className={detailLabelCSS}>Baths</Text>
                    </div>
                    {(Boolean(minSqft) || Boolean(maxSqft)) && showSqft && (
                        <div className={getDetailItemCSS({ isCompact: isCompact && sqftText.length > 4, isExtraCompact })}>
                            <Text className={detailValueCSS} responsive={{ smAndUp: 'md', mdAndUp: 'lg' }}>{sqftText}</Text>
                            <Text className={detailLabelCSS}>Sqft</Text>
                        </div>
                    )}
                    {Boolean(roomSqFt) && (
                        <div className={getDetailItemCSS({ isCompact: isCompact && sqftText.length > 4, isExtraCompact })}>
                            <Text className={detailValueCSS} responsive={{ smAndUp: 'md', mdAndUp: 'lg' }}>{roomSqFt}</Text>
                            <Text className={detailLabelCSS}>Sqft room</Text>
                        </div>
                    )}
                    {roommatesCount && (
                        <div className={getDetailItemCSS({ isCompact: isCompact && roommatesCount.length > 4, isExtraCompact })}>
                            <Text className={detailValueCSS} responsive={{ smAndUp: 'md', mdAndUp: 'lg' }}>{roommatesCount}</Text>
                            <Text className={detailLabelCSS}>{roommatesCountLabel}</Text>
                        </div>
                    )}
                </div>
            </Container>
        </div>
    );
};

export default HdpSummaryDetails;
