// @ts-nocheck
/* eslint-enable */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { margin, stacked } from 'app/shared/styles/_spacing';
import HdpContentWrapper from 'app/shared/modules/hdp/HdpContentWrapper';
import Linker from 'app/shared/modules/Linker';
import IconViewed from 'images/icons/viewed.svg';
import IconContact from 'images/icons/contact.svg';
import IconWatch from 'images/icons/watch.svg';
import 'app/shared/modules/hdp/Popularity.scss';

const StyledContainer = styled.div`
    ${margin.top._1x};
`;
const StyledStackedContainers = styled.div`
    ${stacked._2x};
`;
class Popularity extends React.Component {
    static propTypes = {
        handleContactCta: PropTypes.func
    };

    constructor(props) {
        super(props);
    }

    render() {
        var clientLoaded = this.props.clientLoaded;
        var popularity = this.props.listing.popularity || {};
        var lastContacted = popularity.lastContacted || {};
        var summary = popularity.summary || {};

        const showPopularity = clientLoaded && (popularity.summary || {}).viewed > 0;
        if (!showPopularity) {
            return null;
        }

        return (
            <div className="Popularity">
                <HdpContentWrapper collapsable header="Competition for this rental" headerHtmlTag="h2">
                    <StyledContainer>
                        {summary.viewed > 0 && (
                            <StyledStackedContainers>
                                <img src={IconViewed} className="Popularity-icon" alt="" />
                                <span className="Popularity-text">
                                    Viewed {summary.viewed}
                                    {summary.viewed > 1 ? ' times' : ' time'} this past week.
                                </span>
                            </StyledStackedContainers>
                        )}
                        {summary.inquiries > 0 ? (
                            <StyledStackedContainers>
                                <img src={IconContact} className="Popularity-icon" alt="" />
                                <span className="Popularity-text">
                                    Contacted {summary.inquiries} {summary.inquiries > 1 ? 'times' : 'time'} in the past
                                    week.
                                </span>
                            </StyledStackedContainers>
                        ) : (
                            <StyledStackedContainers>
                                <img src={IconContact} className="Popularity-icon" alt="" />
                                <span className="Popularity-text">
                                    No one has contacted this listing.{' '}
                                    {this.props.listing.active && (
                                        <Linker useButton linkType="default" onClick={this.props.handleContactCta}>
                                            Be the FIRST!
                                        </Linker>
                                    )}
                                </span>
                            </StyledStackedContainers>
                        )}
                        {lastContacted.ago && (
                            <StyledStackedContainers>
                                <img src={IconWatch} className="Popularity-icon" alt="" />
                                <span className="Popularity-text">Last contacted {lastContacted.ago}.</span>
                            </StyledStackedContainers>
                        )}
                    </StyledContainer>
                </HdpContentWrapper>
            </div>
        );
    }
}

export default Popularity;
