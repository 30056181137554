// @ts-nocheck
/* eslint-enable */
import styled, { css } from 'styled-components';
import { font } from 'app/shared/styles/_fontsize';
import { padding } from 'app/shared/styles/_spacing';
import { colors } from 'app/shared/styles/_colors';
import { inline, stacked } from 'app/shared/styles/_spacing';
import { viewports } from 'app/shared/styles/_breakpoints';
import { onHover } from 'app/shared/styles/_mixins';

import Linker from 'app/shared/modules/Linker';

export const SeoFooterArticle = styled.article`
    background: ${colors['$hpx-grey-100']};
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 40px;

`;

export const SeoFooterSectionList = styled.ul`
    @media ${viewports['md-and-up']} {
        display: flex;
        flex-wrap: wrap;
    }
`;

export const SemanticDivider = styled.hr`
    border: none;
    ${(props) => props.hideDivider === false && css`
        border-top: 1px solid ${colors['$hpx-grey-500']};
    `}
    margin: 0;
    padding-bottom: 40px;
    background: ${colors['$hpx-grey-100']};

    @media ${viewports['md-and-up']} {
        width: 100%;
    }
`;

export const header = styled.header`
    ${padding.top._1x};
    ${stacked._2x};
`;

export const h2 = styled.h2`
    ${font.md};
    font-weight: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

export const h3 = styled.h3`
    ${font.sm};
    font-weight: normal;
    color: ${colors['$hpx-grey-600']};
`;

export const SectionItem = styled.li`
    width: 100%;
    &:not(:last-of-type) {
        ${stacked._3x};
    }

    @media ${viewports['md-and-up']} {
        width: 50%;

        &:nth-child(even) {
            padding-right: 16px;
        }
    }
`;

export const p = styled.p`
    ${font.md};
    padding: 2px 0;
    margin-bottom: 8px;
`;

export const ul = styled.ul`
    ${(props) =>
        props.$isVisible === false &&
        `
        display: none;
    `};
`;

export const li = styled.li`
    padding-right: 8px;

    &:not(:last-of-type) {
        ${stacked._1x};
    }

    ${(props) =>
        props.$isVisible === false &&
        `
        display: none;
    `};
`;

export const InvisibleLi = styled.li`
    display: none;
`;

export const Link = styled(Linker)`
    display: block;
    &:focus {
        outline-offset: 4px;
    }
    ${font.sm};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

export const HeadingButton = styled.button`
    ${font.md};
    border: none;
    background: none;
    font-weight: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    padding: 0;
    text-align: left;
`;

export const HeadingButtonCarat = styled.img`
    ${inline._1x};
`;

export const ShowMoreButton = styled.button`
    border: none;
    background: none;
    ${font.tiny};
    padding: 8px 8px 8px 0;
    cursor: pointer;
`;

export const ShowMoreCarat = styled.img`
    padding-left: 4px;
`;

// Hidden from visual sight - used for screen readers
// @see https://adamsilver.io/articles/building-an-accessible-autocomplete-control/#using-a-live-region-to-make-sure-screen-reader-users-know-when-options-are-suggested
export const ScreenReaderOnly = styled.div`
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
`;
