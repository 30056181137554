// @ts-nocheck
/* eslint-enable */
import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';
import { font } from 'app/shared/styles/_fontsize';

import Checkbox from 'app/shared/core/Checkbox';
import Row from 'app/shared/core/Row';
import Text from 'app/shared/core/Text';

export const Disclaimer = styled(Row)`
    ${font.sm}
    background-color: rgba(218, 242, 240, 0.25);
    border: 0.5px solid ${colors['$hpx-teal-100']};
    border-radius: 4px;
    padding: 8px;
`;

export const DisclaimerTitle = styled.div`
    ${font.sm}
    font-weight: 700;
    padding-bottom: 4px;
`;

export const DisclaimerIcon = styled.img`
    padding: 0 4px 4px 0;
`;

export const InputLabel = styled.div`
    ${font.sm}
    font-weight: 700;
    padding-bottom: 6px;
`;

export const StyledCheckBox = styled(Checkbox)`
    display: block;
    padding-top: 10px;
`;

export const SectionHint = styled.div`
    color: #596b82;
    font-size: 12px;
    line-height: 16px;
`;

export const StyledContainer = styled.div`
    padding: 0 0 8px 0;

    ${(props) =>
        props.isPopup &&
        `
        padding: 0 8px 8px 8px; // Handles spacing issues with Popup Modal
    `}
`;

export const StyledPrimaryText = styled(Text)`
    color: ${colors['$hpx-teal-500']};
`;

export const StyledAlertText = styled(Text)`
    color: ${colors['$hpx-red-400']};
`;