// @ts-nocheck
/* eslint-enable */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useYieldCallback } from '@zillow/yield-callback';
import { v4 as uuidv4 } from 'uuid';
import Button from 'app/shared/core/Button';
import Text from 'app/shared/core/Text';
import api from 'app/shared/utils/api';
import dateUtils from 'app/shared/utils/dateUtils';
import { getScheduledTour } from 'app/shared/utils/listingUtils';
import SpinnerDots from 'app/shared/modules/SpinnerDots';
import SpinnerDotsV2 from 'app/shared/modules/SpinnerDotsV2';
import AppActions from 'app/shared/flux/actions/AppActions';
import ContactActions from 'app/shared/flux/actions/ContactActions';
import NotificationActions from 'app/shared/flux/actions/NotificationActions';
import loginUtils from 'app/shared/utils/loginUtils';

import ListingEngineActions from 'app/shared/flux/actions/ListingEngineActions';
import UserActions from 'app/shared/flux/actions/UserActions';

import SelectTourTypeStep from './components/SelectTourTypeStep';
import SelectDateTimeStep from './components/SelectDateTimeStep';
import ContactInfoAndConfirmStep from './components/ContactInfoAndConfirmStep';
import ModifyTourStep from './components/ModifyTourStep';
import ConfirmationStep from './components/ConfirmationStep';
import SystemErrorStep from './components/SystemErrorStep';
import SchedulingErrorStep from './components/SchedulingErrorStep';
import DayAndTimeDisplay from './components/DayAndTimeDisplay';
import inPersonTourIcon from 'images/icons/in-person-tour.svg';
import selfGuideTourIcon from 'images/icons/self-guided-tour.svg';
import liveVideoTourIcon from 'images/icons/live-video-tour.svg';

import { useInstantTourForm, useSelectedTourProvider } from './hooks';
import { STEPS, TOUR_TYPES } from './constants';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';

import * as S from './styles';
import {
    TrackTourRequestFailed,
    TrackTourRequestSubmit,
    TrackTourRequestSuccess
} from 'app/shared/models/Clickstream/TourClickstreamEvents';
import { RootReduxState } from 'app/types/redux';

const InstantTour = ({ isModifyingTour = false, onActionPopup = () => { }, onHidePopup = () => { } }) => {
    const dispatch = useDispatch();
    const { fetchTourData, updateInstantTourForm } = useInstantTourForm();
    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const [isPrimaryBtnDisabled, setIsPrimaryBtnDisabled] = useState(false);
    const [isAvailTimesReqLoading, setIsAvailTimesReqLoading] = useState(false);
    const [isSchedulingReqLoading, setIsSchedulingReqLoading] = useState(false);
    const [deactivatedAccountEmail, setDeactivatedAccountEmail] = useState('');

    const user = useSelector((state: RootReduxState) => state.user);
    const isWebView = useSelector((state: RootReduxState) => state.location.ssrEntry.query?.instantTour);
    const {
        step,
        tourType: selectedTourType,
        selectedDateTime,
        firstName,
        lastName,
        email,
        phone
    } = useSelector((state: RootReduxState) => state.currentListingDetails.instantTourForm);
    const availableToursForListing = useSelector(
        (state: RootReduxState) => state.currentListingDetails.availableToursForListing
    );
    const { aliasEncoded } = useSelector((state: RootReduxState) => state.currentListingDetails.currentListing);
    const { dateOnly } = useSelectedTourProvider();
    const scheduledToursForUser = useSelector((state: RootReduxState) => state.user.scheduledTours);
    const {
        startTime: scheduledDateWithOrWithoutTime,
        tourId: scheduledTourId,
        tourType: scheduledTourType
    } = getScheduledTour(aliasEncoded, scheduledToursForUser);
    const scheduledDateTime = dateUtils.appendTimeISOSubstringIfMissing(scheduledDateWithOrWithoutTime);
    const countryCode = useSelector((state: RootReduxState) => state.geolocation.area.countryCode);

    const { instantTourProviders } = useSelector(
        (state: RootReduxState) => state.currentListingDetails.currentListing.details
    );
    const { inPersonProvider, liveVideoProvider, selfGuidedProvider } = instantTourProviders ?? {};

    const availableTours = Object.keys(instantTourProviders).map((tourType) => instantTourProviders[tourType]);
    const hideBackButtonForTimeSelection = availableTours.filter(Boolean).length === 1;

    const changeStep = useCallback(
        (newStep) => {
            updateInstantTourForm({ step: newStep });
        },
        [updateInstantTourForm]
    );

    const fetchAndHandleTourData = useCallback(
        async (tourType) => {
            setIsAvailTimesReqLoading(true);
            setIsPrimaryBtnDisabled(true);

            try {
                if (Object.keys(availableToursForListing.availableToursMap).length === 0) {
                    await dispatch(ListingEngineActions.hpTours.fetchAvailableToursForListing(aliasEncoded, tourType));
                    dispatch(analyticsEvent(gaEvents.HPTOUR_TOUR_TYPE_CONTINUE));
                } else {
                    await fetchTourData(aliasEncoded, tourType);
                }
                changeStep(STEPS.SELECT_DATE_TIME);
            } catch (e) {
                if (e?.message === 'Request failed with status code 404') {
                    changeStep(STEPS.SELECT_DATE_TIME);
                } else {
                    changeStep(STEPS.SYSTEM_ERROR);
                    dispatch(analyticsEvent(gaEvents.HPTOUR_SYSTEM_ERROR, { label: e?.message }));
                }
            } finally {
                setIsAvailTimesReqLoading(false);
                setIsPrimaryBtnDisabled(false);
            }
        },
        [availableToursForListing, dispatch, aliasEncoded, fetchTourData, changeStep]
    );

    const handleFetchDefaultTourData = useCallback(
        (tourType) => {
            changeStep(STEPS.SELECT_DATE_TIME);
            fetchAndHandleTourData(tourType);
        },
        [changeStep, fetchAndHandleTourData]
    );

    useEffect(() => {
        const availableTourTypes = [
            inPersonProvider && TOUR_TYPES.IN_PERSON,
            selfGuidedProvider && TOUR_TYPES.SELF_GUIDED,
            liveVideoProvider && TOUR_TYPES.LIVE_VIDEO
        ].filter(Boolean);

        if (
            !step ||
            step === STEPS.CONFIRMATION ||
            step === STEPS.CANCEL_SUCCESS ||
            step === STEPS.CANCEL_ERROR ||
            step === STEPS.RESCHEDULE_SUCCESS ||
            step === STEPS.RESCHEDULE_ERROR ||
            step === STEPS.SCHEDULE_TOUR_ERROR ||
            step === STEPS.SYSTEM_ERROR
        ) {
            if (isModifyingTour) {
                changeStep(STEPS.MODIFY_TOUR);
            } else if (availableTourTypes.length === 1 && availableTourTypes[0]) {
                updateInstantTourForm({ tourType: availableTourTypes[0] });
                handleFetchDefaultTourData(availableTourTypes[0]);
            } else {
                changeStep(STEPS.SELECT_TOUR_TYPE);
            }
        }

        updateInstantTourForm({ isOpen: true });
        setIsInitialLoading(false);

        return () => {
            updateInstantTourForm({ isOpen: false });
        };

        // We only want this useEffect to run on initial render:
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleScheduleTourButtonClick = useCallback(
        async ({ rentalSubmitId }: { rentalSubmitId: string }) => {
            setIsSchedulingReqLoading(true);

            // create and login new freepass account if using a non-account-associated email
            let emailAssociatedWithExistingAccount = false;

            if (!user.loggedIn) {
                dispatch(AppActions.setAppStoreBool('userExists', false));
                const userDetails = await dispatch(ContactActions.loginUser(user.loggedIn, email));

                if (!userDetails || userDetails.error) {
                    changeStep(STEPS.SYSTEM_ERROR);
                    setIsSchedulingReqLoading(false);
                    return;
                } else if (userDetails.status === 'ACCOUNT_DEACTIVATED') {
                    setDeactivatedAccountEmail(email);
                    setIsSchedulingReqLoading(false);
                    return;
                } else if (userDetails.status === 'USER_ALREADY_EXISTS') {
                    dispatch(AppActions.setAppStoreBool('userExists', true));
                    emailAssociatedWithExistingAccount = true;
                } else if (
                    userDetails.success &&
                    userDetails?.data?.passwordStatus?.toLowerCase() === 'freepass' &&
                    loginUtils.isCanadianUser(countryCode, email)
                ) {
                    // freepassed user from canada
                    dispatch(NotificationActions.email.updateEmailOptIn(true, 'other'));
                }

                if (!emailAssociatedWithExistingAccount) {
                    dispatch(analyticsEvent(gaEvents.HPTOUR_LOGIN_FREEPASS));
                }
            }

            const requestBody = {
                listingAlias: aliasEncoded,
                tourType: selectedTourType,
                email,
                phone,
                firstName,
                lastName,
                requestedTime: dateOnly ? dateUtils.removeTimeISOSubstring(selectedDateTime) : selectedDateTime,
                rentalSubmitId
            };

            dispatch(api.hpToursV3.requestTour(requestBody))
                .then((res) => {
                    if (res.success) {
                        changeStep(STEPS.CONFIRMATION);
                        dispatch(UserActions.hpTours.fetchScheduledToursForUser()).then((toursForUser) => {
                            if (toursForUser.length === 0) {
                                // add tour to redux to display tour status module incase user opts out of signing in
                                dispatch(UserActions.hpTours.addScheduledTourForUser(scheduledToursForUser, res.data));
                            }
                        });
                        dispatch(
                            analyticsEvent(gaEvents.HPTOUR_SCHEDULE_TOUR_SUCCESS, {
                                newLaneEvent: TrackTourRequestSuccess()
                            })
                        );

                        // input email associated with existing account
                        if (emailAssociatedWithExistingAccount) {
                            dispatch(analyticsEvent(gaEvents.HPTOUR_LOGIN_PROMPTED));
                            changeStep(STEPS.PROMPT_LOGIN);
                        }
                    } else {
                        changeStep(STEPS.SCHEDULE_TOUR_ERROR);
                        dispatch(
                            analyticsEvent(gaEvents.HPTOUR_SCHEDULE_TOUR_ERROR, {
                                label: res?.error?.message,
                                newLaneEvent: TrackTourRequestFailed()
                            })
                        );
                        dispatch(ListingEngineActions.hpTours.clearAvailableToursForListing());
                    }
                })
                .catch((e) => {
                    changeStep(STEPS.SCHEDULE_TOUR_ERROR);
                    dispatch(ListingEngineActions.hpTours.clearAvailableToursForListing());
                    dispatch(
                        analyticsEvent(gaEvents.HPTOUR_SCHEDULE_TOUR_ERROR, {
                            label: e.message,
                            newLaneEvent: TrackTourRequestFailed()
                        })
                    );
                })
                .finally(() => {
                    setIsSchedulingReqLoading(false);
                });
        },
        [
            user.loggedIn,
            aliasEncoded,
            selectedTourType,
            email,
            phone,
            firstName,
            lastName,
            dateOnly,
            selectedDateTime,
            dispatch,
            countryCode,
            changeStep,
            scheduledToursForUser
        ]
    );

    const handleChangeButtonClick = useCallback(() => {
        dispatch(analyticsEvent(gaEvents.HPTOUR_CHANGE_TOUR_SUBMIT));

        if (selectedTourType === scheduledTourType && selectedDateTime === scheduledDateTime) {
            changeStep(STEPS.RESCHEDULE_SUCCESS);
            dispatch(analyticsEvent(gaEvents.HPTOUR_CHANGE_SUCCESS));
            return;
        }

        setIsSchedulingReqLoading(true);
        const requestBody = {
            listingAlias: aliasEncoded,
            tourType: selectedTourType,
            email: user.info.email ?? email,
            firstName: user.info.firstName ?? firstName,
            lastName: user.info.lastName ?? lastName,
            requestedTime: dateOnly ? dateUtils.removeTimeISOSubstring(selectedDateTime) : selectedDateTime,
            tourId: scheduledTourId
        };

        dispatch(api.hpToursV3.rescheduleTour(requestBody))
            .then((res) => {
                if (res.success) {
                    changeStep(STEPS.RESCHEDULE_SUCCESS);
                    dispatch(UserActions.hpTours.fetchScheduledToursForUser());
                    dispatch(analyticsEvent(gaEvents.HPTOUR_CHANGE_SUCCESS));
                } else {
                    changeStep(STEPS.RESCHEDULE_ERROR);
                    updateInstantTourForm({
                        tourType: scheduledTourType,
                        selectedDate: '',
                        selectedDateTime: ''
                    });
                    dispatch(analyticsEvent(gaEvents.HPTOUR_CHANGE_ERROR), { label: res.message });
                }
            })
            .catch((e) => {
                changeStep(STEPS.RESCHEDULE_ERROR);
                updateInstantTourForm({
                    tourType: scheduledTourType,
                    selectedDate: '',
                    selectedDateTime: ''
                });
                dispatch(analyticsEvent(gaEvents.HPTOUR_CHANGE_ERROR), { label: e.message });
            })
            .finally(() => {
                dispatch(ListingEngineActions.hpTours.fetchAvailableToursForListing(aliasEncoded, scheduledTourType));
                setIsSchedulingReqLoading(false);
            });
    }, [
        dispatch,
        selectedTourType,
        scheduledTourType,
        selectedDateTime,
        scheduledDateTime,
        aliasEncoded,
        user.info.email,
        user.info.firstName,
        user.info.lastName,
        email,
        firstName,
        lastName,
        dateOnly,
        scheduledTourId,
        changeStep,
        updateInstantTourForm
    ]);

    const yieldHandleSelectTourType = useYieldCallback(() => {
        fetchAndHandleTourData(selectedTourType);
    });

    const yieldHandleOtherType = useYieldCallback(({ event = null, options = null, nextStep = null, cb = null }) => {
        if (event) {
            dispatch(analyticsEvent(event, options));
        }
        if (nextStep) {
            changeStep(nextStep);
        }
        if (cb) {
            cb();
        }
    });

    const handleNextButtonClick = useCallback(() => {
        switch (step) {
            case STEPS.SELECT_TOUR_TYPE:
                yieldHandleSelectTourType();
                break;
            case STEPS.SELECT_DATE_TIME:
                yieldHandleOtherType({
                    event: gaEvents.HPTOUR_CONTINUE_DATE_SELECTION,
                    nextStep: STEPS.CONTACT_INFO_AND_CONFIRM
                });
                break;
            case STEPS.CONTACT_INFO_AND_CONFIRM:
                const rentalSubmitId = uuidv4();
                yieldHandleOtherType({
                    event: gaEvents.HPTOUR_SCHEDULE_TOUR_SUBMIT,
                    cb: () => handleScheduleTourButtonClick({ rentalSubmitId }),
                    options: { newLaneEvent: TrackTourRequestSubmit({ rentalSubmitId }) }
                });
                break;
            case STEPS.PROMPT_LOGIN:
                yieldHandleOtherType({
                    event: gaEvents.HPTOUR_LOGIN_SKIPPED,
                    nextStep: STEPS.CONFIRMATION
                });
                break;
            case STEPS.MODIFY_TOUR:
                yieldHandleOtherType({
                    cb: () => handleChangeButtonClick()
                });
                break;
            case STEPS.SYSTEM_ERROR:
                onHidePopup();
                break;
        }
    }, [
        step,
        yieldHandleSelectTourType,
        yieldHandleOtherType,
        onHidePopup,
        handleScheduleTourButtonClick,
        handleChangeButtonClick
    ]);

    const handleBackButtonClick = useCallback(() => {
        switch (step) {
            case STEPS.CONFIRMATION:
                yieldHandleOtherType({
                    nextStep: STEPS.CONTACT_INFO_AND_CONFIRM
                });
                break;
            case STEPS.CONTACT_INFO_AND_CONFIRM:
                yieldHandleOtherType({
                    nextStep: STEPS.SELECT_DATE_TIME,
                    event: gaEvents.HPTOUR_GO_BACK_SCHEDULE_TOUR,
                    cb: () => setIsPrimaryBtnDisabled(false)
                });
                break;
            case STEPS.SELECT_DATE_TIME:
                yieldHandleOtherType({
                    nextStep: STEPS.SELECT_TOUR_TYPE,
                    event: gaEvents.HPTOUR_GO_BACK_DATE_SELECTION
                });
                break;
        }
    }, [yieldHandleOtherType, step]);

    const getNextButtonText = () => {
        switch (step) {
            case STEPS.MODIFY_TOUR:
                return 'Change';
            case STEPS.CONTACT_INFO_AND_CONFIRM:
                return 'Schedule Tour';
            case STEPS.PROMPT_LOGIN:
                return 'Skip Login';
            case STEPS.SYSTEM_ERROR:
                return 'Close';
            default:
                return 'Continue';
        }
    };

    const getPrimaryBtnAria = () => {
        let ariaLabel = '';

        if (step === STEPS.SYSTEM_ERROR) {
            ariaLabel = 'Unable to continue. Close modal and try again later.';
        } else if (step === STEPS.SELECT_TOUR_TYPE) {
            ariaLabel = 'Continue to select tour time';
        } else if (step === STEPS.SELECT_DATE_TIME) {
            ariaLabel = 'Continue to enter contact information';
        } else if (step === STEPS.CONTACT_INFO_AND_CONFIRM || step === STEPS.MODIFY_TOUR) {
            const requestedDate = new Date(selectedDateTime);
            const dateOptions: Intl.DateTimeFormatOptions = {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            };
            const timeOptions: Intl.DateTimeFormatOptions = {
                hour: 'numeric',
                minute: 'numeric'
            };
            let updateSelectedTourType = 'in-person tour';

            if (updateSelectedTourType === TOUR_TYPES.SELF_GUIDED) {
                updateSelectedTourType = 'self-guided tour';
            } else if (updateSelectedTourType === TOUR_TYPES.LIVE_VIDEO) {
                updateSelectedTourType = 'live video tour';
            }

            const action = step === STEPS.CONTACT_INFO_AND_CONFIRM ? 'Schedule' : 'Change to ';

            ariaLabel = `${action} ${updateSelectedTourType} for ${requestedDate.toLocaleDateString(
                'en-US',
                dateOptions
            )} at ${requestedDate.toLocaleTimeString('en-US', timeOptions)}`;
        }

        return ariaLabel;
    };

    const getSecondaryBtnAria = () => {
        let ariaLabel = '';

        if (step === STEPS.SELECT_DATE_TIME) {
            ariaLabel = 'Go back to selecting tour type';
        } else if (step === STEPS.CONTACT_INFO_AND_CONFIRM) {
            ariaLabel = 'Go back to selecting tour time';
        }

        return ariaLabel;
    };

    const showBackButton = () => {
        if ((step === STEPS.SELECT_DATE_TIME && hideBackButtonForTimeSelection) || step === STEPS.SYSTEM_ERROR) {
            return false;
        }

        if (step === STEPS.SELECT_DATE_TIME || step === STEPS.CONTACT_INFO_AND_CONFIRM) {
            return true;
        }

        if (step !== STEPS.SELECT_TOUR_TYPE && step !== STEPS.MODIFY_TOUR && step !== STEPS.PROMPT_LOGIN) {
            return true;
        }
    };

    const hideInstantTourFooterButtons =
        isInitialLoading ||
        step === STEPS.CONFIRMATION ||
        step === STEPS.CANCEL_SUCCESS ||
        step === STEPS.CANCEL_ERROR ||
        step === STEPS.RESCHEDULE_SUCCESS ||
        step === STEPS.RESCHEDULE_ERROR ||
        step === STEPS.SCHEDULE_TOUR_ERROR;

    return (
        <div className={S.InstantTourContainerCx}>
            {isWebView ? null : (
                <h2 className={S.InstantTourHeaderCx}>
                    {step === STEPS.MODIFY_TOUR ? 'Reschedule your tour' : 'Book tour now'}
                </h2>
            )}
            <div className={S.InstantTourBodyCx}>
                {isInitialLoading && (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: '50%'
                        }}
                    >
                        <SpinnerDotsV2 />
                    </div>
                )}
                {step === STEPS.SELECT_TOUR_TYPE && (
                    <SelectTourTypeStep setIsPrimaryBtnDisabled={setIsPrimaryBtnDisabled} />
                )}
                {step === STEPS.SELECT_DATE_TIME && (
                    <SelectDateTimeStep
                        isAvailTimesReqLoading={isAvailTimesReqLoading}
                        setIsPrimaryBtnDisabled={setIsPrimaryBtnDisabled}
                    />
                )}
                {step === STEPS.CONTACT_INFO_AND_CONFIRM && (
                    <ContactInfoAndConfirmStep
                        setIsPrimaryBtnDisabled={setIsPrimaryBtnDisabled}
                        isSchedulingReqLoading={isSchedulingReqLoading}
                        deactivatedAccountEmail={deactivatedAccountEmail}
                    />
                )}
                {step === STEPS.MODIFY_TOUR && <ModifyTourStep setIsPrimaryBtnDisabled={setIsPrimaryBtnDisabled} />}
                {(step === STEPS.CONFIRMATION ||
                    step === STEPS.CANCEL_SUCCESS ||
                    step === STEPS.RESCHEDULE_SUCCESS ||
                    step === STEPS.PROMPT_LOGIN) && <ConfirmationStep onActionPopup={onActionPopup} />}
                {step === STEPS.SYSTEM_ERROR && <SystemErrorStep />}
                {(step === STEPS.SCHEDULE_TOUR_ERROR ||
                    step === STEPS.CANCEL_ERROR ||
                    step === STEPS.RESCHEDULE_ERROR) && <SchedulingErrorStep />}
            </div>
            {!hideInstantTourFooterButtons && (
                <div className={S.InstantTourFooterButtonGroupCx}>
                    {showBackButton() && (
                        <Button
                            aria-label={getSecondaryBtnAria()}
                            bold
                            btnType="secondary"
                            hideInputBorder
                            size="md"
                            onClick={handleBackButtonClick}
                            disabled={isSchedulingReqLoading}
                        >
                            Go Back
                        </Button>
                    )}
                    {step === STEPS.MODIFY_TOUR && (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {selectedTourType === TOUR_TYPES.IN_PERSON && (
                                <span>
                                    <img className={S.TourTypeIconCx} src={inPersonTourIcon} />
                                    <div className={S.TourTypeTextCx}>In-person tour</div>
                                </span>
                            )}
                            {selectedTourType === TOUR_TYPES.SELF_GUIDED && (
                                <span>
                                    <img className={S.TourTypeIconCx} src={selfGuideTourIcon} />
                                    <div className={S.TourTypeTextCx}>Self-guided tour</div>
                                </span>
                            )}
                            {selectedTourType === TOUR_TYPES.LIVE_VIDEO && (
                                <span>
                                    <img className={S.TourTypeIconCx} src={liveVideoTourIcon} />
                                    <div className={S.TourTypeTextCx}>Live Video tour</div>
                                </span>
                            )}
                            <DayAndTimeDisplay
                                dateTimeToDisplay={selectedDateTime ? selectedDateTime : scheduledDateTime}
                                dateOnly={dateOnly}
                            />
                        </div>
                    )}
                    <Button
                        aria-label={getPrimaryBtnAria()}
                        btnType="primary"
                        disabled={isPrimaryBtnDisabled || isSchedulingReqLoading}
                        size="md"
                        onClick={handleNextButtonClick}
                    >
                        {isAvailTimesReqLoading || isSchedulingReqLoading ? (
                            <SpinnerDots />
                        ) : (
                            <Text>{getNextButtonText()}</Text>
                        )}
                    </Button>
                </div>
            )}
        </div>
    );
};
export default InstantTour;
