// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { stacked, padding } from 'app/shared/styles/_spacing';
import { textOverflow } from 'app/shared/styles/_mixins';

import './styles.scss';
import CallLinkV2 from 'app/shared/modules/hdp/CallLinkV2';
import controller from './controller';
import formatter from 'app/shared/utils/formatter';
import Linker from 'app/shared/modules/Linker';
import Title from 'app/shared/modules/Title';

const StyledTitle = styled(Title)`
    ${stacked._2x};
`;
const StyledDiv = styled.div`
    ${padding.top._4x};
`;
const TextOverflowDiv = styled.div`
    ${textOverflow};
`;
class FdpFloorplans extends Component {
    static propTypes = {
        active: PropTypes.bool,
        floorplan: PropTypes.object.isRequired,
        onHideModal: PropTypes.func.isRequired,
        waitlisted: PropTypes.bool
    };

    static defaultProps = {
        active: true,
        waitlisted: false
    };

    renderNoUnitsRow = () => {
        return (
            <div className="FdpFloorplans-unit FdpFloorplans-unit-empty">
                <div className="FdpFloorplans-unit-price FdpFloorplans-unit-price-empty">No data</div>
                <div className="FdpFloorplans-unit-availability FdpFloorplans-unit-availability-empty">No data</div>
                <div className="FdpFloorplans-unit-name FdpFloorplans-unit-name-empty">No data</div>
            </div>
        );
    };

    renderUnitDetailRow = ({ unit, i }) => {
        const { listing, active, waitlisted } = this.props;
        let priceText = controller.getPriceSummaryDisplay({ unit });
        if (isEmpty(priceText)) {
            priceText = <CallLinkV2 listing={listing} />;
        }

        return (
            <div className="FdpFloorplans-unit" key={`floor-plan-unit-${i}`}>
                <div className="FdpFloorplans-unit-price">{priceText}</div>
                <TextOverflowDiv className="FdpFloorplans-unit-availability">
                    {controller.getAvailabilityDisplay({ active, unit, waitlisted })}
                </TextOverflowDiv>
                <TextOverflowDiv className="FdpFloorplans-unit-name">
                    {formatter.string.upperFirstLowerRest(unit.name || '')}
                </TextOverflowDiv>
            </div>
        );
    };

    render() {
        const { floorplan, onHideModal, isMobile } = this.props;
        const { topDisplay, bottomDisplay, imgUrl, imgStyle, units } = controller.getFloorplanDetails(floorplan);

        return (
            <div className="FdpFloorplans">
                <div className="FdpFloorplans-header-cont">
                    <Title className="FdpFloorplans-header" size="xl">
                        Floor plans
                    </Title>
                    <div className="FdpFloorplans-summary">
                        <div className="FdpFloorplans-summary-top">{topDisplay}</div>
                        <div className="FdpFloorplans-summary-bottom">{bottomDisplay}</div>
                    </div>
                </div>
                <div className="FdpFloorplans-img-details-cont">
                    <div className="FdpFloorplans-img-cont" style={isMobile ? imgStyle : {}}>
                        <img src={imgUrl} className="FdpFloorplans-img" />
                    </div>
                    <div className="FdpFloorplans-details-cont">
                        <StyledTitle className="FdpFloorplans-subheader" size="md">
                            Available units
                        </StyledTitle>
                        <div className="FdpFloorplans-label-header">
                            <div className="FdpFloorplans-label-price">Price</div>
                            <div className="FdpFloorplans-label-availability">Available</div>
                            <div className="FdpFloorplans-label-name">Unit</div>
                        </div>
                        {isEmpty(units)
                            ? this.renderNoUnitsRow()
                            : units.map((unit, i) => this.renderUnitDetailRow({ unit, i }))}
                        {isEmpty(units) && (
                            <StyledDiv>
                                Browse{' '}
                                <Linker onClick={onHideModal} linkType="underline" tabIndex="0">
                                    other floor plans
                                </Linker>
                                .
                            </StyledDiv>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isMobile: state.app.device.screenWidth === 'sm' || state.app.device.screenWidth === 'xs'
});

export default connect(mapStateToProps)(FdpFloorplans);
