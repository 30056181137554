// @ts-nocheck
/* eslint-enable */
import React from 'react';
import './style.scss';
import Row from 'app/shared/core/Row';
import Text from 'app/shared/core/Text';
import Container from 'app/shared/core/Container';

class ListingRestrictionsInline extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { listing } = this.props;
        const { building, contact, details, listingType } = listing;

        let restrictionsArray = [];
        const isIncomeRestricted = !building && contact.restricted;
        const isStudentHousing = details.studentHousing;
        const isSeniorHousing = details.seniorHousing;
        const isRoom = listingType === 'room';
        const isSublet = listingType === 'sublet';
        const isFurnished = details.furnished;

        const hasRestriction =
            isIncomeRestricted || isSeniorHousing || isStudentHousing || isRoom || isSublet || isFurnished;

        if (!hasRestriction) {
            return null;
        }

        if (isRoom) {
            restrictionsArray.push('Room for rent');
        }
        if (isSublet) {
            restrictionsArray.push('Sublet');
        }

        if (isFurnished) {
            restrictionsArray.push('Furnished');
        }

        if (isIncomeRestricted) {
            restrictionsArray.push('Income restricted');
        }

        if (isSeniorHousing) {
            restrictionsArray.push('Senior housing');
        }

        if (isStudentHousing) {
            restrictionsArray.push('Student housing');
        }
        return (
            <div className="ListingRestrictionsInline">
                <Container>
                    <Row size="sm">
                        <Row size="sm">
                            {hasRestriction &&
                                restrictionsArray.map((item, i) => {
                                    return (
                                        <Text size="sm" key={item + i} className="ListingRestrictionsInline-item">
                                            {item}
                                        </Text>
                                    );
                                })}
                        </Row>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default ListingRestrictionsInline;
