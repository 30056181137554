import React from 'react';
import loadable from '@loadable/component';

const BreadcrumbNav = loadable(
    () =>
        import(
            /* webpackChunkName: "BreadcrumbNav.loadable" */ 'app/shared/modules/BreadcrumbNav/component'
        ),
    {
        ssr: false
    }
);

class LoadableBreadcrumbNav extends React.Component {
    render() {
        return <BreadcrumbNav {...this.props} />;
    }
}

export default LoadableBreadcrumbNav;
