// @ts-nocheck
/* eslint-enable */
import React from 'react';
import cx from 'classnames';
import Row from 'app/shared/core/Row';
import 'app/shared/modules/hdp/SchoolRatingIcon.scss';

class SchoolRatingIcon extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { rating, size } = this.props;

        return (
            <div className="SchoolRatingIcon">
                <div
                    className={cx('SchoolRatingIcon-circle', {
                        'SchoolRatingIcon-small': size === 'sm',
                        'SchoolRatingIcon-default': rating > 0,
                        'SchoolRatingIcon-gray': rating === 0,
                        'SchoolRatingIcon-none': rating === 0 && size !== 'sm',
                        'SchoolRatingIcon-none-sm': rating === 0 && size === 'sm'
                    })}
                >
                    {rating === 0 || !rating ? 'NR' : rating}
                </div>
                {size !== 'sm' && <Row size="sm">out of 10</Row>}
            </div>
        );
    }
}

export default SchoolRatingIcon;
