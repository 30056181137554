// @ts-nocheck
/* eslint-enable */
import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { margin, stacked, padding } from 'app/shared/styles/_spacing';
import { colors } from 'app/shared/styles/_colors';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import assign from 'lodash/assign';
import isEqual from 'lodash/isEqual';
import mailcheck from 'mailcheck';
import PropTypes from 'prop-types';

// Components
import Button from 'app/shared/core/Button';
import ContactUserInfo from 'app/shared/modules/hdp/form/Contact/ContactUserInfo';
import Input from 'app/shared/core/Input';
import InputPhoneNumber from 'app/shared/modules/InputPhoneNumber';
import Linker from 'app/shared/modules/Linker';
import Row from 'app/shared/core/Row';
import Text from 'app/shared/core/Text';
import Title from 'app/shared/modules/Title';

// Misc / Utils
import controller from 'app/shared/modules/hdp/form/Contact/controller';
import { listingUtils_getContactEmail } from 'app/shared/utils/listingUtils';
import CONSTANTS from 'app/shared/constants/ConstantsBundle';
import './style.scss';

const { bool, object, func, string } = PropTypes;

const UPDATE_EMAIL_ERROR_MSG = 'Please update your email address.';
const VERBIAGE_CONSTANTS = {
    title: 'Ready to apply?',
    body: "Enter your contact details and we'll let the rental manager know you want to submit an application. If they're interested, they'll contact you with next steps."
};

const StyledSubmitContainer = styled.div`
    ${stacked._1x};
    ${margin.top._3x};
`;

const VerbiageContainer = styled.div`
    ${margin.top._2x};
    ${margin.bottom._1x};
`;

const StyledFormContainer = styled.div`
    ${padding.left._2x};
    ${padding.right._2x};
`;

const StyledInput = styled(Input)`
    ${padding.top._2x};
`;

const StyledInputPhoneNumber = styled(InputPhoneNumber)`
    ${padding.top._2x};
`;
const StyledAddCustomMessageDiv = styled.div`
    ${stacked._2x};
`;
const StyledVerbiageTitle = styled(Title)`
    ${stacked._1x};
`;
const StyledAlertDiv = styled.div`
    color: ${colors['$hpx-red-400']};
`;
class ContactFormLite extends Component {
    static propTypes = {
        clientLoaded: bool.isRequired,
        contactSubmitted: bool.isRequired,
        errorMessage: string.isRequired,
        isLoading: bool.isRequired,
        listing: object.isRequired,
        onSubmitForm: func.isRequired,
        user: object.isRequired,
        userInquiryDataCache: object.isRequired
    };

    constructor(props) {
        super(props);
        const { user, userInquiryDataCache } = this.props;
        const userEmail = listingUtils_getContactEmail(user, userInquiryDataCache);
        const userInfoFirstLastName =
            user.info.firstName && user.info.lastName ? `${user.info.firstName} ${user.info.lastName}` : '';

        // const customMessageVisible = false;
        this.userMessageInput = React.createRef();
        this.state = {
            inputValues: {
                userMessage: '',
                userEmail: listingUtils_getContactEmail(user, userInquiryDataCache) || '',
                userName: userInquiryDataCache.name || userInfoFirstLastName || '',
                userPhoneNumber: userInquiryDataCache.phone || ''
            },
            inputErrors: {
                userMessage: null,
                userEmail: controller.userHasMaskedEmail(userEmail) ? UPDATE_EMAIL_ERROR_MSG : null,
                userName: null,
                userPhoneNumber: null
            },
            formError: null,
            editingUserInfo: false,
            isDisabled: false,
            customMessageVisible: false
        };
    }
    componentDidMount() {
        this.updateTextAreaHeight();
    }

    componentWillReceiveProps(nextProps) {
        const { user, userInquiryDataCache, contactSubmitted, isLoading } = nextProps;
        const { inputValues } = this.state;

        const userInfoFirstLastName =
            user.info.firstName && user.info.lastName ? `${user.info.firstName} ${user.info.lastName}` : '';

        // Prevents a null value from occuring if the user IS logged in but doesn't have a phone number.
        const userPhoneNumber = userInquiryDataCache.phone ? userInquiryDataCache.phone : '';

        // When this component initially mounts, this.props.user is full of null values.
        // Here, we wait for the component to get the correct props and then update
        // its state (which is used for values inside Input).
        let newInputValuesObj = {};
        if (isLoading) {
            newInputValuesObj = {
                userMessage: inputValues.userMessage,
                userEmail: inputValues.userEmail,
                userName: inputValues.userName,
                userPhoneNumber: inputValues.userPhoneNumber
            };
        } else {
            newInputValuesObj = {
                userMessage: inputValues.userMessage,
                userEmail: listingUtils_getContactEmail(user, userInquiryDataCache) || inputValues.userEmail,
                userName: userInquiryDataCache.name || userInfoFirstLastName || inputValues.userName,
                userPhoneNumber: userPhoneNumber || inputValues.userPhoneNumber
            };
        }

        if (!isEqual(inputValues, newInputValuesObj)) {
            this.setState({ inputValues: newInputValuesObj });
        }

        if (contactSubmitted) {
            this.clearForm();
        }
    }

    componentDidUpdate() {
        this.updateTextAreaHeight();
    }

    updateTextAreaHeight = () => {
        // Dynamically change height of contact message text area if scroll height is larger than current height.
        let textElements = ReactDOM.findDOMNode(this.userMessageInput.current);
        if (textElements) {
            let userMessageTextArea = textElements.getElementsByTagName('textarea')[0];

            if (userMessageTextArea.scrollHeight > userMessageTextArea.style.height) {
                userMessageTextArea.style.height = userMessageTextArea.scrollHeight + 4 + 'px';
            }
        }
    };

    clearForm = () => {
        const { inputValues } = this.state;
        let newInputObj = assign({}, inputValues);
        newInputObj.moveDate = '';
        newInputObj.numBaths = '';
        newInputObj.numBeds = '';

        this.setState({ inputValues: newInputObj });
    };

    handleEmailBlur = (e) => {
        const { inputValues } = this.state;
        let emailInput = e.target.value;
        let newInputValuesObj = assign({}, inputValues);
        newInputValuesObj.userEmail = emailInput;
        this.setState({
            inputValues: newInputValuesObj
        });
        mailcheck.run({
            email: emailInput,
            suggested: (suggestion) => {
                let mailcheckHint = (
                    <span
                        className="suggestion"
                        onClick={() => {
                            let newEmail = suggestion.full;
                            let newInputObj = assign({}, inputValues);
                            newInputObj.userEmail = newEmail;
                            this.setState({
                                inputValues: newInputObj,
                                mailcheckHint: ''
                            });
                        }}
                    >
                        Did you mean <Linker>{suggestion.full}</Linker>?
                    </span>
                );
                this.setState({
                    mailcheckHint
                });
            },
            empty: () => {
                this.setState({
                    mailcheckHint: ''
                });
            }
        });
    };

    handleEmailChange = (e) => {
        const { inputValues } = this.state;
        let emailInput = e.target.value;
        let newInputValuesObj = assign({}, inputValues);
        newInputValuesObj.userEmail = emailInput;
        this.setState({
            inputValues: newInputValuesObj
        });
    };

    handleEditUserInfo = () => {
        this.setState({
            editingUserInfo: true
        });
    };

    handleTextInput = (field, e) => {
        const { inputValues } = this.state;
        let newInputValuesObj = assign({}, inputValues);

        newInputValuesObj[field] = e.target.value;
        this.setState({
            inputValues: newInputValuesObj
        });
    };

    validateInput = (inputValues) => {
        const { listing } = this.props;
        const { contact } = listing;
        const { restrictedConfirmation } = this.state;
        const landlordRequiredFields = {
            contact: {
                requiresMessage: contact.requiresMessage,
                requiresName: contact.requiresName,
                requiresEmail: contact.requiresEmail,
                requiresPhone: contact.requiresPhone
            }
        };
        const { errorObj, isValid } = controller.validateContactFormInput({
            inputValues,
            listing: landlordRequiredFields,
            restrictedConfirmation
        });

        this.setState(errorObj);
        return isValid;
    };

    handleSubmit = (e) => {
        const { onSubmitForm, listing } = this.props;
        const hideStreet = listing.address.hideStreet;
        const streetAddress = listing.address.street;
        const { inputValues } = this.state;

        if (e) {
            e.preventDefault();
        }

        // Always give userMessage a default
        if (inputValues.userMessage === '') {
            if (hideStreet) {
                inputValues.userMessage = CONSTANTS.APPLICATION_REQUEST_INQUIRY;
            } else {
                inputValues.userMessage = `I'm interested in ${streetAddress} and would like to fill out an application. Can you send me one?`;
            }
        }

        // Always set requestToApply to true
        inputValues.requestToApply = true;

        if (!this.validateInput(inputValues)) {
            return;
        }

        onSubmitForm(inputValues);
    };

    render() {
        const {
            clientLoaded,
            emailErrorMessage,
            errorMessage,
            isLoading,
            listing,
            phoneErrorMessage,
            user,
            userInquiryDataCache
        } = this.props;
        const {
            customMessageVisible,
            editingUserInfo,
            formError,
            inputErrors,
            inputValues,
            mailcheckHint,
            isDisabled
        } = this.state;
        const { contact } = listing;

        if (!clientLoaded) {
            return null;
        }

        const phoneRequired = contact.requiresPhone;
        const userInfoFirstLastName = `${user.info.firstName} ${user.info.lastName}`.trim();
        const userName = userInquiryDataCache.name || userInfoFirstLastName;
        const userEmail = listingUtils_getContactEmail(user, userInquiryDataCache);
        const userPhone = userInquiryDataCache.phone;
        const isMissingUserInfo = !user.loggedIn || !userName || !userEmail || (phoneRequired && !userPhone);
        const maskedUserEmail = controller.userHasMaskedEmail(userEmail);
        const sendButtonLabel = 'Send message';

        return (
            <StyledFormContainer className="ContactFormLite">
                <VerbiageContainer className="ContactFormLite-verbiage">
                    <StyledVerbiageTitle size="lg">{VERBIAGE_CONSTANTS.title}</StyledVerbiageTitle>
                    <Text size="md">{VERBIAGE_CONSTANTS.body}</Text>
                </VerbiageContainer>
                <form>
                    {!customMessageVisible && (
                        <Fragment>
                            <StyledAddCustomMessageDiv onClick={() => this.setState({ customMessageVisible: true })}>
                                <Text size="md" className="ContactFormLite-custom-message">
                                    {'+ Add custom message'}
                                </Text>
                            </StyledAddCustomMessageDiv>
                            <div className="ContactFormLite-bottom-border" />
                        </Fragment>
                    )}

                    {customMessageVisible && (
                        <Input
                            help={inputErrors.userMessage}
                            hpxStyle={inputErrors.userMessage ? 'error' : null}
                            label="Customize message"
                            maxLength={750}
                            onChange={this.handleTextInput.bind(null, 'userMessage')}
                            ref={this.userMessageInput}
                            required={false}
                            type="textarea"
                            value={inputValues.userMessage}
                            placeholder={"Anything you'd like to add?"}
                        />
                    )}
                    {isMissingUserInfo || editingUserInfo ? (
                        <Fragment>
                            <StyledInput
                                help={inputErrors.userName}
                                hpxStyle={inputErrors.userName ? 'error' : null}
                                label="Your name"
                                maxLength={50}
                                onChange={this.handleTextInput.bind(null, 'userName')}
                                required={contact.requiresName}
                                value={inputValues.userName}
                            />
                            <StyledInput
                                help={emailErrorMessage || inputErrors.userEmail || mailcheckHint}
                                hpxStyle={inputErrors.userEmail ? 'error' : null}
                                label="Your email"
                                maxLength={50}
                                onChange={this.handleEmailChange}
                                onBlur={this.handleEmailBlur}
                                required={contact.requiresEmail}
                                name="email"
                                type="email"
                                value={inputValues.userEmail}
                            />
                            <StyledInputPhoneNumber
                                help={phoneErrorMessage || inputErrors.userPhoneNumber}
                                hpxStyle={inputErrors.userPhoneNumber ? 'error' : null}
                                label="Your phone number"
                                maxLength={15}
                                onChange={this.handleTextInput.bind(null, 'userPhoneNumber')}
                                required={contact.requiresPhone}
                                value={inputValues.userPhoneNumber}
                            />
                        </Fragment>
                    ) : (
                        <ContactUserInfo onEditUserInfo={this.handleEditUserInfo} />
                    )}
                    <StyledSubmitContainer>
                        <Button
                            full
                            size="lg"
                            btnType="text-color"
                            onClick={this.handleSubmit}
                            disabled={isLoading || maskedUserEmail || isDisabled}
                        >
                            {isLoading ? 'Sending Message..' : sendButtonLabel}
                        </Button>
                        {(errorMessage || formError) && (
                            <Row>
                                <StyledAlertDiv>{errorMessage || formError}</StyledAlertDiv>
                            </Row>
                        )}
                    </StyledSubmitContainer>
                </form>
            </StyledFormContainer>
        );
    }
}

export default connect()(ContactFormLite);
