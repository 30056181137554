/*global PasswordCredential */
export function store(id: string, password: string) {
    try {
        if (navigator && navigator.credentials) {
            // @ts-expect-error This is a browser API
            navigator.credentials.store(new PasswordCredential({ id, password }));
        }
    } catch (e) {
        // avoid ReferenceError with PasswordCredential
    }
}
