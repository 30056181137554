// @ts-nocheck
/* eslint-enable */
import React from 'react';
import IconPromo from 'images/icons/promo-teal.min.svg';
import * as S from './styles';

const SpecialOffers = ({ offers = [] }) => {
    const renderOffer = (offer, i) => {
        let description = offer.description;
        let expirationString = '';
        let expiration = new Date(offer.endDate);

        if (!isNaN(expiration.getTime())) {
            expirationString = `Expires ${expiration.toLocaleDateString('en-US')}.`;
        }

        return (
            <S.OfferDetails key={`SpecialOffer-${i}`}>
                {i === 0 && <S.OfferText bold>{`Special offer! `}</S.OfferText>}
                <S.OfferText>{description}</S.OfferText>
                {expirationString && (
                    <>
                        {' '}
                        <S.OfferText className="SpecialOffers-expiration">{expirationString}</S.OfferText>
                    </>
                )}
            </S.OfferDetails>
        );
    };

    if (offers.length === 0) {
        return null;
    }

    return (
        <S.SpecialOffers>
            <S.SpecialOfferContainer>
                <S.IconColumn>
                    <S.PromoIcon
                        src={IconPromo}
                        width="16px"
                        height="16px"
                        alt="This listing has a special promotion - contact for details"
                    />
                </S.IconColumn>
                <S.OfferTextColumn>{offers.map((offer, i) => renderOffer(offer, i))}</S.OfferTextColumn>
            </S.SpecialOfferContainer>
        </S.SpecialOffers>
    );
};

export default SpecialOffers;
