// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import HdpContentWrapper from 'app/shared/modules/hdp/HdpContentWrapper';
import Text from 'app/shared/core/Text';
import './style.scss';

class HdpLegalInfo extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { listing } = this.props;
        return (
            <div className="HdpLegalInfo">
                <HdpContentWrapper collapsable header="Legal info" headerHtmlTag="h2">
                    <Text size="md">
                        <div
                            className="HdpLegalInfo-text-wrapper" dangerouslySetInnerHTML={{
                                __html: listing.legalDisclaimer
                            }}
                        />
                    </Text>
                </HdpContentWrapper>
            </div>
        );
    }
}

export default connect()(HdpLegalInfo);