// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { padding } from 'app/shared/styles/_spacing';
import { colors } from 'app/shared/styles/_colors';
import { yieldCallback } from '@zillow/yield-callback';

// Components
import Button from 'app/shared/core/Button';
import Center from 'app/shared/modules/Center';
import Input from 'app/shared/core/Input';
import Label from 'app/shared/core/Label';
import Radio from 'app/shared/core/Radio';
import Row from 'app/shared/core/Row';
import Text from 'app/shared/core/Text';
import Title from 'app/shared/modules/Title';

// Actions
import AppActions from 'app/shared/flux/actions/AppActions';
import ListingEngineActions from 'app/shared/flux/actions/ListingEngineActions';

// Misc / utils
import 'app/shared/modules/hdp/form/Report.scss';
const logger = getLogger('modules/hdp');
const StyledRadio = styled(Radio)`
    ${padding.top._1x};
`;
const StyledAlertDiv = styled.div`
    color: ${colors['$hpx-red-400']};
`;
class Report extends React.Component {
    static displayName = 'Report';

    static defaultProps = {
        onHidePopup: () => { }
    };

    constructor(props) {
        super(props);
        this.state = {
            message: '',
            loading: false,
            reportValue: null,
            errorMsg: null
        };
    }

    validateRequiredData() {
        let reportRequiresComment = {
            badDescription: true,
            fairHousing: true,
            offensive: true,
            other: true,
            spam: true
        };

        if (reportRequiresComment[this.state.reportValue] && this.state.message.length === 0) {
            this.setState({ errorMsg: 'Please describe the problem with this listing.' });
            return false;
        } else {
            return true;
        }
    }

    yieldSubmitForm = yieldCallback(() => {
        this.setState({
            loading: true,
            errorMsg: null
        });

        let params = {
            alias: this.props.listing.aliasEncoded,
            type: this.state.reportValue,
            message: this.state.message
        };

        // Validate content if required.
        if (!this.validateRequiredData()) {
            this.setState({
                loading: false
            });

            return;
        }

        if (params.type) {
            this.props
                .dispatch(ListingEngineActions.sendReport(params))
                .then(() => {
                    this.props.dispatch(
                        AppActions.toggleNotificationMessage({
                            content: 'This listing has been reported.'
                        })
                    );
                    this.handleHideModal();
                })
                .catch((err) => {
                    let msg = 'Failed to report listing.';
                    logger.warn(err, msg);
                    this.setState({
                        loading: false,
                        errorMsg: msg
                    });
                });
        } else {
            this.setState({
                loading: false,
                errorMsg: 'Please select a reason for reporting this listing.'
            });
        }
    });

    handleSubmitForm = (e) => {
        e.preventDefault();
        this.yieldSubmitForm();
    };

    handleTextInput = (e) => {
        this.setState({ message: e.target.value });
    };

    handleSelection = (e) => {
        const reportValue = e.target.value;
        this.setState({ reportValue });
    };

    handleHideModal = () => {
        let { onHidePopup } = this.props;

        if (onHidePopup) {
            onHidePopup();
        }
    };

    render() {
        const { reportValue } = this.state;
        return (
            <div className="Report">
                <form>
                    <Title id="dialog-title" size="lg">
                        Report a listing
                    </Title>
                    <div className="Report-subtitle">
                        <Text size="md">Thanks for taking the time to help make HotPads better!</Text>
                    </div>
                    <Row>
                        <Input
                            label="Please describe the issue"
                            maxLength={750}
                            onChange={this.handleTextInput}
                            type="textarea"
                            value={this.state.message}
                        />
                    </Row>
                    <Label id="report-reason-group">Reason for reporting</Label>
                    <div className="Report-radio" role="radiogroup" aria-labelledby="report-reason-group">
                        <StyledRadio
                            ariaLabel="Report fraudulent"
                            isChecked={reportValue === 'scam'}
                            name="ReportType"
                            id="scam"
                            onChange={this.handleSelection}
                            value="scam"
                        >
                            Fraud
                        </StyledRadio>
                        <StyledRadio
                            ariaLabel="Report spam"
                            isChecked={reportValue === 'spam'}
                            name="ReportType"
                            id="spam"
                            onChange={this.handleSelection}
                            value="spam"
                        >
                            Spam
                        </StyledRadio>
                        <StyledRadio
                            ariaLabel="Report inappropriate content"
                            isChecked={reportValue === 'offensive'}
                            name="ReportType"
                            id="offensive"
                            onChange={this.handleSelection}
                            value="offensive"
                        >
                            Inappropriate content
                        </StyledRadio>
                        <StyledRadio
                            ariaLabel="Report discriminatory or offensive listing"
                            isChecked={reportValue === 'fairHousing'}
                            name="ReportType"
                            id="fairHousing"
                            onChange={this.handleSelection}
                            value="fairHousing"
                        >
                            Discriminatory or offensive listing
                        </StyledRadio>
                        <StyledRadio
                            ariaLabel="Report not available"
                            isChecked={reportValue === 'notAvailable'}
                            name="ReportType"
                            id="notAvailable"
                            onChange={this.handleSelection}
                            value="notAvailable"
                        >
                            Not available
                        </StyledRadio>
                        <StyledRadio
                            ariaLabel="Report inaccurate"
                            isChecked={reportValue === 'badDescription'}
                            name="ReportType"
                            id="badDescription"
                            onChange={this.handleSelection}
                            value="badDescription"
                        >
                            Inaccurate
                        </StyledRadio>
                        <StyledRadio
                            ariaLabel="Report other"
                            isChecked={reportValue === 'other'}
                            name="ReportType"
                            id="other"
                            onChange={this.handleSelection}
                            value="other"
                        >
                            Other
                        </StyledRadio>
                    </div>
                    <Row>
                        <Center>
                            <Button
                                full
                                size="md"
                                btnType="primary"
                                onClick={this.handleSubmitForm}
                                disabled={this.state.loading}
                            >
                                {this.state.loading ? 'Reporting listing...' : 'Report this listing'}
                            </Button>
                        </Center>
                    </Row>
                    {this.state.errorMsg && (
                        <Center>
                            <StyledAlertDiv>{this.state.errorMsg}</StyledAlertDiv>
                        </Center>
                    )}
                </form>
            </div>
        );
    }
}

export default connect()(Report);
