// @ts-nocheck
/* eslint-enable */
import React from 'react';
import Text from 'app/shared/core/Text';
import dateUtils from 'app/shared/utils/dateUtils';

const DayAndTimeDisplay = ({ dateTimeToDisplay = '', dateOnly = false } = {}) => {
    if (!dateTimeToDisplay) {
        return null;
    }

    const date = new Date(dateTimeToDisplay);
    const dayOfTheWeek = dateUtils.getDayDisplayFromDate(date, true, true);
    const month = dateUtils.getMonthDisplayFromDate(date);
    const dayOfTheMonth = date.getDate();

    if (dateOnly) {
        return <Text htmlTag="p">{`${dayOfTheWeek}, ${month} ${dayOfTheMonth}`}</Text>;
    }

    const hour = date.getHours();
    const minutes = date.getMinutes();

    return (
        <Text htmlTag="p">
            {`${dayOfTheWeek}, ${month} ${dayOfTheMonth} at ${dateUtils.formatHourMinuteString(`${hour}:${minutes}`)}`}
        </Text>
    );
};

export default DayAndTimeDisplay;
