// @ts-nocheck
/* eslint-enable */
import isNull from 'lodash/isNull';

const controller = {
    buildDirectionsUrl(from = {}, to = {}, travelMode = '') {
        return (
            'https://www.google.com/maps/dir/?api=1&origin=' +
            from.lat +
            ',' +
            from.lon +
            '&destination=' +
            to.lat +
            ',' +
            to.lon +
            '&travelmode=' +
            travelMode
        );
    },

    getGoogleDirectionsLinks(listing, userPoint) {
        if (isNull(userPoint)) {
            return;
        }

        return {
            cycling: controller.buildDirectionsUrl(listing.geo, userPoint, 'bicycling'),
            driving: controller.buildDirectionsUrl(listing.geo, userPoint, 'driving'),
            transit: controller.buildDirectionsUrl(listing.geo, userPoint, 'transit'),
            walking: controller.buildDirectionsUrl(listing.geo, userPoint, 'walking')
        };
    }
};

export default controller;
