// @ts-nocheck
/* eslint-enable */
/* eslint-disable camelcase */
import cx from 'classnames';
import map from 'lodash/map';
import React, { Component, Fragment } from 'react';
import some from 'lodash/some';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';

import AppActions from 'app/shared/flux/actions/AppActions';
import appUtils from 'app/shared/utils/appUtils';
import BedBathComboFilter from 'app/shared/modules/MobileSRP/Header/FilterModules/BedBathComboFilter';
import Button from 'app/shared/core/Button';
import constants from 'app/shared/constants/ConstantsBundle';
import { filterUtils_getFilterPillLabels, filterUtils_hasActiveFilters } from 'app/shared/utils/filterUtils';
import IconAlert from 'images/icons/alert-orange.svg';
import NotificationActions from 'app/shared/flux/actions/NotificationActions';
import PriceSlider from 'app/shared/modules/CollectionResultPage/PriceFilter/PriceSlider';
import queryUtils from 'app/shared/utils/queryUtils';
import Radio from 'app/shared/core/Radio';
import routeUtils from 'app/shared/utils/routeUtils';
import Row from 'app/shared/core/Row';
import './style.scss';
import Text from 'app/shared/core/Text';
import UserSearchActions from 'app/shared/flux/actions/UserSearchActions';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import { TrackCreateSearchAlert, TrackPostContactPopupView } from 'app/shared/models/Clickstream/SearchAlertEvents';

const logger = getLogger('modules/postcontact');
const StyledDarkSpanText = styled.span`
    color: ${colors['$hpx-grey-600']};
`;
const StyledAlertText = styled(Text)`
    color: ${colors['$hpx-red-400']};
`;
const StyledBoldText = styled(Text)`
    font-weight: 700;
    text-align: center;
`;
const StyledBoldSpan = styled.span`
    font-weight: 700;
`;
const StyledCenteredText = styled(Text)`
    text-align: center;
`;
const StyledCenteredDiv = styled.div`
    text-align: center;
`;
const StyledCenteredRow = styled(Row)`
    text-align: center;
`;
class PostContactSavedSearch extends Component {
    constructor(props) {
        super(props);
        const { area, shouldUseAreaBoundary, totalListings, filter } = this.props;
        const initialEmailFrequency = totalListings > constants.LISTINGS_THRESHOLD ? 'daily' : 'hourly';

        let searchName = area.fullName;
        if (!shouldUseAreaBoundary && searchName) {
            searchName = searchName.split(', ')[0];
            searchName += ' Area';
        }

        this.searchName = searchName;
        this.hasDefaultFilters = !filterUtils_hasActiveFilters(filter);

        this.state = {
            frequency: initialEmailFrequency,
            errorMsg: '',
            isScrolling: false
        };
    }
    componentDidMount() {
        const { mobileScreenSize, dispatch } = this.props;
        dispatch(NotificationActions.notifications.getSettings());
        if (mobileScreenSize) {
            appUtils.lockVerticalScrolling();
        }
        dispatch(
            analyticsEvent(gaEvents.SEARCH_ALERT_POST_CONTACT_DISPLAY, { newLaneEvent: TrackPostContactPopupView() })
        );
    }
    componentWillUnmount() {
        const { mobileScreenSize } = this.props;
        if (mobileScreenSize) {
            appUtils.unlockVerticalScrolling();
        }
    }
    handleSaveSearch = () => {
        const { area, dispatch, email, onHidePopup, pathname, savedSearchPreferences, shouldUseAreaBoundary } =
            this.props;
        const { frequency } = this.state;

        const useMapBounds = routeUtils.isAreaUrl(pathname) || routeUtils.isPadOrBuildingUrl(pathname);

        dispatch(
            UserSearchActions.search.saved.create(
                this.searchName,
                email,
                frequency,
                useMapBounds,
                shouldUseAreaBoundary ? area.id : null
            )
        )
            .then(() => {
                dispatch(
                    analyticsEvent(gaEvents.SEARCH_ALERT_POST_CONTACT_CREATE, {
                        newLaneEvent: TrackCreateSearchAlert({
                            triggerLocation: 'post_contact_popup',
                            triggerObject: 'post_contact_popup|alerts_modal'
                        })
                    })
                );
                dispatch(UserSearchActions.getSavedSearches());

                if (!some(savedSearchPreferences)) {
                    dispatch(AppActions.toggleOverlay('optInSearchAlerts', true));
                } else {
                    dispatch(
                        AppActions.toggleNotificationMessage({
                            content: 'Success! Search alert created.'
                        })
                    );
                }
                onHidePopup();
            })
            .catch(() => {
                this.setState({
                    errorMsg: 'Oops! Something went wrong.'
                });
            });
    };
    handleEmailFrequencyChange = (e) => {
        const frequency = e.target.value;
        this.setState({ frequency });
    };
    render() {
        const { filter } = this.props;
        const { frequency, isScrolling, errorMsg } = this.state;
        const filterDiff = filterUtils_getFilterPillLabels(filter);

        return (
            <div className={cx('PostContactSavedSearch', { 'PostContactSavedSearch-scroll-lock': isScrolling })}>
                <StyledBoldText htmlTag="div" id="dialog-title" className="PostContactSavedSearch-title" size="lg">
                    Message sent!
                </StyledBoldText>
                <div className="PostContactSavedSearch-top-divider" />
                <StyledCenteredDiv className="PostContactSavedSearch-icon">
                    <img src={IconAlert} height="16" width="16" alt="" />
                </StyledCenteredDiv>
                <StyledBoldText size="md" htmlTag="div" className="PostContactSavedSearch-subtitle">
                    Never miss a beat
                </StyledBoldText>
                <StyledCenteredText htmlTag="div" size="sm">
                    Save your search and get notified about new homes as soon as they're posted.
                </StyledCenteredText>
                <div className="PostContactSavedSearch-bottom-divider" />
                <StyledCenteredText htmlTag="div" size="sm">
                    Location: <StyledBoldSpan>{this.searchName}</StyledBoldSpan>
                </StyledCenteredText>
                {this.hasDefaultFilters ? (
                    <Fragment>
                        <Row>
                            <StyledCenteredText size="sm" htmlTag="div" className="PostContactSavedSearch-label">
                                What's your price range?
                            </StyledCenteredText>
                            <PriceSlider preventDefault />
                        </Row>
                        <Row>
                            <StyledCenteredText size="sm" htmlTag="div" className="PostContactSavedSearch-label">
                                How many bedrooms?
                            </StyledCenteredText>
                            <BedBathComboFilter
                                hideBath
                                preventDefault
                                triggerLocation="post_contact_popup"
                                triggerObject="post_contact_popup|alerts_modal"
                            />
                        </Row>
                    </Fragment>
                ) : (
                    <div className="PostContactSavedSearch-summary">
                        {map(filterDiff, (value, key) => {
                            return (
                                <Text size="sm" htmlTag="div" key={key}>
                                    <StyledDarkSpanText>{value[0]}:</StyledDarkSpanText> {value[1]}
                                </Text>
                            );
                        })}
                    </div>
                )}
                <Row>
                    <StyledCenteredText
                        size="sm"
                        htmlTag="div"
                        className="PostContactSavedSearch-label"
                        id="email-frequency-group"
                    >
                        I want new home alerts sent to me:
                    </StyledCenteredText>
                    <div
                        className="PostContactSavedSearch-radio-btns"
                        role="radiogroup"
                        aria-labelledby="email-frequency-group"
                    >
                        <Radio
                            ariaLabel="Instantly"
                            id="instantly"
                            isChecked={frequency === 'hourly'}
                            name="frequency"
                            onChange={this.handleEmailFrequencyChange}
                            value="hourly"
                        >
                            Instantly
                        </Radio>
                        <Radio
                            ariaLabel="Daily"
                            id="daily"
                            isChecked={frequency === 'daily'}
                            name="frequency"
                            onChange={this.handleEmailFrequencyChange}
                            value="daily"
                        >
                            Daily
                        </Radio>
                        <Radio
                            ariaLabel="Never"
                            id="never"
                            isChecked={frequency === 'never'}
                            name="frequency"
                            onChange={this.handleEmailFrequencyChange}
                            value="never"
                        >
                            Never
                        </Radio>
                    </div>
                </Row>
                <div className="PostContactSavedSearch-btn">
                    <Button full onClick={this.handleSaveSearch}>
                        Save search
                    </Button>
                </div>
                {errorMsg && (
                    <StyledCenteredRow>
                        <StyledAlertText size="sm">{errorMsg}</StyledAlertText>
                    </StyledCenteredRow>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const screenWidth = state.app.device.screenWidth;
    return {
        area: state.area.area,
        email: state.user.info.email,
        filter: state.filter,
        mobileScreenSize: screenWidth === 'xs' || screenWidth === 'sm',
        pathname: ownProps.location.pathname,
        savedSearchPreferences: state.user.savedSearchPreferences,
        shouldUseAreaBoundary: queryUtils.parse(ownProps.location.search).border !== false,
        totalListings: state.listings.totalListings
    };
};

export default withRouter(connect(mapStateToProps)(PostContactSavedSearch));
