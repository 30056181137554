// @ts-nocheck
/* eslint-enable */
import AuthActions from 'app/shared/flux/actions/AuthActions';
import constants from 'app/shared/constants/ConstantsBundle';
import ListingEngineActions from 'app/shared/flux/actions/ListingEngineActions';
import UserActions from 'app/shared/flux/actions/UserActions';
import userUtils from 'app/shared/utils/userUtils';

const logger = getLogger('actions/contact');

const ContactActions = {
    freepassLogin(email) {
        return (dispatch) => {
            return dispatch(AuthActions.login.load({ email, dontSendWelcomeEmail: true }))
                .then((data) => {
                    // allow the inquiry to be sent, but don't log the user in afterwards if they aren't authd
                    if (data.success && !data.loggedIn) {
                        return Promise.resolve(true);
                    } else if (data.status === 'USER_ALREADY_EXISTS' && !data.loggedIn) {
                        return Promise.resolve(data);
                    } else {
                        userUtils.successLoginRedirect();
                        return Promise.resolve(data);
                    }
                })
                .catch((err) => {
                    logger.warn(err, 'CONTACT_FORM_FREEPASS_LOGIN_ERROR');
                    return Promise.resolve(false);
                });
        };
    },
    loginUser(userLoggedIn, inputEmail) {
        return (dispatch) => {
            if (!userLoggedIn) {
                return dispatch(ContactActions.freepassLogin(inputEmail));
            } else {
                return Promise.resolve(true);
            }
        };
    },
    submitContact({ listing, inquiryParams, rentalSubmitId }) {
        return (dispatch) => {
            /**
             * DISABLE CONTACT INQUIRIES
             * Allows devs to quickly disable sending inquiries to backend API. This is useful for testing
             * front-end contact form logic and validation without the need for creating / renewing fake listings
             * on HotPads.
             *
             * __DEV__ flag should ensure that this never gets enabled in production environments.
             **/
            const DISABLE_INQUIRIES_FOR_CONTACT_TESTING = false;
            if (DISABLE_INQUIRIES_FOR_CONTACT_TESTING && __DEV__) {
                console.warn(
                    '\n** WARNING **: DISABLE_INQUIRIES_FOR_CONTACT_TESTING is true.\nContact inquiries are currently DISABLED and not being send to backend API.\n'
                );
                return {
                    success: 'ok'
                };
            }

            return dispatch(
                ListingEngineActions.sendInquiry({
                    options: inquiryParams,
                    listing,
                    rentalSubmitId
                })
            ).then((response = {}) => {
                if (response.error) {
                    logger.error(response.error, 'ListingEngineActions.sendInquiry responded with an error');
                } else {
                    const updateMessageParams = {
                        autoGeneratedMessage: false,
                        email: inquiryParams.email,
                        phone: inquiryParams.phone,
                        name: inquiryParams.name,
                        text: inquiryParams.textToCache || '',
                        displayName: inquiryParams.displayName,
                        address: inquiryParams.address,
                        defaultMessage: inquiryParams.defaultMessage
                    };

                    dispatch(UserActions.inquiry.updateMessageCache(updateMessageParams));
                }

                return response;
            });
        };
    },
    // HPWEB-5993: CTA A/B test
    updateCtaButtonContext(source = '') {
        return (dispatch) => {
            return dispatch({
                type: constants.UPDATE_CTA_BUTTON_CONTEXT,
                payload: source
            });
        };
    }
};

export default ContactActions;
