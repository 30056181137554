// @ts-nocheck
/* eslint-enable */
import React from 'react';
import Text from 'app/shared/core/Text';
import problemCircleFill from 'images/icons/problem-circle-fill.svg';
import styled from 'styled-components';

const SystemErrorContainter = styled.div`
    padding: 16px 16px 32px 16px;
`;

const SystemErrorHeader = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    gap 12px;
    padding: 10px 0;
`;

const SystemErrorHeaderText = styled.h3`
    font-size: 28px;
`;


const SystemErrorStep = () => {
    return (
        <SystemErrorContainter aria-live="assertive">
            <SystemErrorHeader>
                <img alt="" height={'35px'} width={'35px'} src={problemCircleFill} />
                <SystemErrorHeaderText>We're having a system issue</SystemErrorHeaderText>
            </SystemErrorHeader>
            <Text>We can't connect to the tour scheduling service right now. Please try again later.</Text>
        </SystemErrorContainter>
    );
};

export default SystemErrorStep;
