// @ts-nocheck
/* eslint-enable */
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import some from 'lodash/some';

import 'app/shared/modules/hdp/SurroundingStats.scss';
import HdpContentWrapper from 'app/shared/modules/hdp/HdpContentWrapper';
import IconArrowDown from 'images/icons/compare-arrow-down.svg';
import IconArrowUp from 'images/icons/compare-arrow-up.svg';
import Linker from 'app/shared/modules/Linker';
import ListItem from 'app/shared/modules/ListItem';
import numberUtils from 'app/shared/utils/numberUtils';
import Row from 'app/shared/core/Row';
import Text from 'app/shared/core/Text';

class SurroundingStats extends React.Component {
    static propTypes = {
        listing: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
    }

    priceDifference = (stat) => {
        const className = 'SurroundingStats-icon';
        if (stat.percentageValue < 0) {
            return {
                type: 'less',
                description: ' cheaper than',
                icon: <img src={IconArrowDown} className={className} height="16px" alt="" />,
                priceClass: 'SurroundingStats-price-less'
            };
        }
        if (stat.percentageValue > 0) {
            return {
                type: 'more',
                description: ' more than',
                icon: <img src={IconArrowUp} className={className} height="16px" alt="" />,
                priceClass: 'SurroundingStats-price-more'
            };
        }

        return {
            type: 'equal',
            description: ' similarly to',
            icon: null,
            priceClass: null
        };
    };

    getPreviewData = (stats) => {
        let { county, neighborhood, city } = stats;
        let previewStat;
        let previewAreaType = null;

        if (neighborhood) {
            previewAreaType = 'neighborhood';
            previewStat = neighborhood;
        } else if (city) {
            previewAreaType = 'city';
            previewStat = city;
        } else if (county) {
            previewAreaType = 'county';
            previewStat = county;
        } else {
            return null;
        }

        let { type, icon, priceClass, description } = this.priceDifference(previewStat);

        return {
            type,
            previewAreaType,
            description,
            icon,
            priceClass,
            difference: previewStat.difference
        };
    };

    render() {
        const {
            areas: { surroundingAreaStats = {} },
            listingMinMaxPriceBeds,
            floorplans,
            listingType,
            showPricingStats
        } = this.props.listing;
        const isExpired = !this.props.listing.active;
        const minPrice = listingMinMaxPriceBeds.minPrice;
        const fullPriceDisplay = '$' + numberUtils.fullNumber(minPrice);
        const haveAreaStats = some(surroundingAreaStats);
        const previewData = haveAreaStats ? this.getPreviewData(surroundingAreaStats) : null;
        let headerAside;

        if (previewData) {
            const hasNoPriceDifference = previewData.type === 'equal';

            headerAside = hasNoPriceDifference ? null : (
                <div className={cx('SurroundingStats-price', previewData.priceClass)}>
                    {previewData.icon}
                    <span className="SurroundingStats-difference">
                        {numberUtils.prettyPrintNumber(previewData.difference)}
                    </span>
                </div>
            );
        } else {
            return null;
        }

        let homeQualifier;
        if (listingType === 'room') {
            homeQualifier = ['room for rent', 'rooms for rent'];
        } else if (floorplans[0].beds === 0) {
            homeQualifier = ['studio', 'studios'];
        } else {
            homeQualifier = [floorplans[0].beds + ' bedroom home', floorplans[0].beds + ' bedroom homes'];
        }

        const comparisonTxt = `Other ${homeQualifier[1]}...`;

        if (showPricingStats) {
            return (
                <HdpContentWrapper
                    active={isExpired}
                    collapsable
                    header="Pricing comparison"
                    headerAside={headerAside}
                    headerHtmlTag="h2"
                >
                    <div className="SurroundingStats">
                        {haveAreaStats && (
                            <Text size="md">
                                <Row>
                                    <span>At {fullPriceDisplay}, this listing is priced </span>
                                    {previewData.type !== 'equal' && (
                                        <span>
                                            <strong className="SurroundingStats-price">
                                                {numberUtils.prettyPrintNumber(previewData.difference)}
                                            </strong>
                                        </span>
                                    )}
                                    <span className="SurroundingStats-price">{previewData.description}</span>
                                    <span> the current market rate for a {homeQualifier[0]} in </span>
                                    <Linker
                                        to={surroundingAreaStats[previewData.previewAreaType].link}
                                        linkType="accent"
                                    >
                                        {surroundingAreaStats[previewData.previewAreaType].name}
                                    </Linker>
                                    .
                                </Row>

                                {!isEmpty(surroundingAreaStats) && (
                                    <Row size="sm">
                                        <Text size="md">{comparisonTxt}</Text>
                                    </Row>
                                )}
                                {!isEmpty(surroundingAreaStats) && (
                                    <ul>
                                        {map(surroundingAreaStats, (stat) => {
                                            if (stat) {
                                                let { id, link, name, areaAveragePrice } = stat;

                                                return (
                                                    <ListItem key={id}>
                                                        <span>in </span>
                                                        <Linker to={link} linkType="accent">
                                                            {name}
                                                        </Linker>
                                                        <span>
                                                            {' '}
                                                            go for ${numberUtils.prettyPrintNumber(areaAveragePrice)}
                                                        </span>
                                                    </ListItem>
                                                );
                                            }
                                        })}
                                    </ul>
                                )}
                            </Text>
                        )}
                    </div>
                </HdpContentWrapper>
            );
        } else {
            return null;
        }
    }
}

export default SurroundingStats;
