// @ts-nocheck
/* eslint-enable */
import React from 'react';
import PropTypes from 'prop-types';

// Components
import HdpContentWrapper from 'app/shared/modules/hdp/HdpContentWrapper';

// Icons
import IconAppliances from 'images/amenities/teal/appliances.svg';
import IconBuilding from 'images/icons/building-teal.min.svg';
import IconCat from 'images/amenities/teal/cat.svg';
import IconCooling from 'images/amenities/teal/air-conditioning.min.svg';
import IconDog from 'images/amenities/teal/pets.svg';
import IconGym from 'images/amenities/teal/gym.svg';
import IconHeating from 'images/amenities/teal/hvac.svg';
import IconHistory from 'images/icons/history-teal.min.svg';
import IconHourglass from 'images/amenities/teal/hourglass.svg';
import IconLaundry from 'images/amenities/teal/laundry.svg';
import IconOutdoorAreas from 'images/amenities/teal/outdoorareas.svg';
import IconParking from 'images/amenities/teal/parking.svg';
import IconPool from 'images/amenities/teal/pool.svg';
import IconSecurity from 'images/amenities/teal/security.svg';
import IconCalendar from 'images/icons/calendar-empty-teal.svg';
import IconList from 'images/icons/list-outline-teal.svg';
import IconLease from 'images/icons/compose-sublet-teal.svg';

// Misc / Utils
import {
    AMENITY_DISPLAY_ORDER,
    AMENITY_DISPLAY_ORDER_R4R,
    AMENITIES_TO_FORMAT,
    SHOULD_EXPAND_AMENITY,
    buildHighlightsDictionary,
    getExpandedAmenityDetails,
    parseActivatedTime,
    parseUpdatedTime,
    prettyPrintHighlight
} from './controller';
import { listingUtils_isMultifamilyType } from 'app/shared/utils/listingUtils';
import stringHelper from 'app/shared/utils/stringHelper';
import dateUtils from 'app/shared/utils/dateUtils';

import * as S from './styles';

const amenityIcons = {
    appliances: <S.HighlightsIcon src={IconAppliances} height="24px" alt="" />,
    cats: <S.HighlightsIcon src={IconCat} height="24px" alt="" />,
    cooling: <S.HighlightsIcon src={IconCooling} height="24px" alt="" />,
    dogs: <S.HighlightsIcon src={IconDog} height="24px" alt="" />,
    gym: <S.HighlightsIcon src={IconGym} height="24px" alt="" />,
    heating: <S.HighlightsIcon src={IconHeating} height="24px" alt="" />,
    laundry: <S.HighlightsIcon src={IconLaundry} height="24px" alt="" />,
    outdoorAreas: <S.HighlightsIcon src={IconOutdoorAreas} height="24px" alt="" />,
    parking: <S.HighlightsIcon src={IconParking} height="24px" alt="" />,
    pool: <S.HighlightsIcon src={IconPool} height="24px" alt="" />,
    security: <S.HighlightsIcon src={IconSecurity} height="24px" alt="" />
};

const HdpHighlightsV2 = ({ listing }) => {
    const { amenities = {}, details = {}, history = {}, propertyType, listingType, floorplans } = listing;
    const { highlights = [] } = amenities;
    const { leaseTerms = '', feesAndDeposits = [] } = details;
    const { availabilityDate = '' } = floorplans[0] ?? {};
    const isRoomForRent = listingType === 'room';
    const highlightsDictionary = buildHighlightsDictionary(highlights);
    const isMultiFamily = listingUtils_isMultifamilyType(propertyType);
    const securityDeposit = feesAndDeposits.find((feeOrDeposit) => feeOrDeposit.feeType === 'deposit')?.fee;

    const renderHighlights = () => {
        let highlightComponents = [];
        const amenityDisplayOrderToUse = listingType === 'room' ? AMENITY_DISPLAY_ORDER_R4R : AMENITY_DISPLAY_ORDER;
        amenityDisplayOrderToUse.forEach((amenityId) => {
            if (highlightsDictionary[amenityId] && SHOULD_EXPAND_AMENITY.includes(amenityId)) {
                const { types = [] } = highlightsDictionary[amenityId];

                types.forEach((amenityType) => {
                    const expandedAmenityDetails = getExpandedAmenityDetails(amenityId, amenityType);

                    if (expandedAmenityDetails) {
                        const icon = amenityIcons[expandedAmenityDetails.icon];
                        highlightComponents.push(
                            <S.HighlightsListItem key={`hdpHighlight-${highlightComponents.length + 1}`}>
                                {icon}
                                <S.HighlightsText>{expandedAmenityDetails.description}</S.HighlightsText>
                            </S.HighlightsListItem>
                        );
                    }
                });

                return;
            }

            if (highlightsDictionary[amenityId]) {
                let formattedAmenityText;
                const icon = amenityIcons[amenityId];

                // Certain types of amenities should be better formatted.
                if (AMENITIES_TO_FORMAT.includes(amenityId)) {
                    formattedAmenityText =
                        prettyPrintHighlight[amenityId] &&
                        prettyPrintHighlight[amenityId](highlightsDictionary[amenityId].types);
                } else {
                    formattedAmenityText = highlightsDictionary[amenityId].types.join(', ');
                }

                formattedAmenityText = stringHelper.titleCase(formattedAmenityText);

                highlightComponents.push(
                    <S.HighlightsListItem key={`hdpHighlight-${highlightComponents.length + 1}`}>
                        {icon}
                        <S.HighlightsText>{stringHelper.titleCase(formattedAmenityText)}</S.HighlightsText>
                    </S.HighlightsListItem>
                );
            }
        });

        return highlightComponents;
    };

    // header={'Highlights} is tied to a Sonar test via HdpContentWrapper; update the test if this changes!
    return (
        <HdpContentWrapper active header={`Highlights`} headerHtmlTag="h2" noBorder>
            <S.HighlightsList>
                {isMultiFamily && (
                    <S.HighlightsListItem>
                        <S.HighlightsIcon src={IconBuilding} height="24px" alt="" />
                        <S.HighlightsText>Apartment Building</S.HighlightsText>
                    </S.HighlightsListItem>
                )}
                {isRoomForRent && (
                    <>
                        {availabilityDate && (
                            <S.HighlightsListItem>
                                <S.HighlightsIcon src={IconCalendar} height="24px" alt="" />
                                <S.HighlightsText>
                                    {`Date Available: ${dateUtils.formatDateToString(availabilityDate)}`}
                                </S.HighlightsText>
                            </S.HighlightsListItem>
                        )}
                        {securityDeposit && (
                            <S.HighlightsListItem>
                                <S.HighlightsIcon src={IconList} height="24px" alt="" />
                                <S.HighlightsText>{`Security deposit: ${securityDeposit}`}</S.HighlightsText>
                            </S.HighlightsListItem>
                        )}
                        {leaseTerms && (
                            <S.HighlightsListItem>
                                <S.HighlightsIcon src={IconLease} height="24px" alt="" />
                                <S.HighlightsText>{`Lease: ${leaseTerms}`}</S.HighlightsText>
                            </S.HighlightsListItem>
                        )}
                    </>
                )}
                {renderHighlights()}
                {!isMultiFamily && (
                    <S.HighlightsListItem>
                        <S.HighlightsIcon src={IconHistory} height="24px" alt="" />
                        <S.HighlightsText>{parseActivatedTime(history, isMultiFamily)}</S.HighlightsText>
                    </S.HighlightsListItem>
                )}
                <S.HighlightsListItem>
                    <S.HighlightsIcon src={IconHourglass} height="24px" alt="" />
                    <S.HighlightsText>{parseUpdatedTime(history)}</S.HighlightsText>
                </S.HighlightsListItem>
            </S.HighlightsList>
        </HdpContentWrapper>
    );
};

HdpHighlightsV2.propTypes = {
    listing: PropTypes.object
};

HdpHighlightsV2.defaultProps = {
    listing: {}
};

export default HdpHighlightsV2;
