import { css } from 'app/styled-system/css';

export const InstantTourContainerCx = css({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxHeight: '100vh'
});

export const InstantTourHeaderCx = css({
    alignItems: 'center',
    backgroundColor: 'hpxWhite',
    borderBottomColor: 'hpxGrey400',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    color: 'hpxBlue600',
    display: 'flex',
    flexShrink: 0,
    fontSize: 'lg',
    fontWeight: 'bold',
    justifyContent: 'center',
    lineHeight: 'lg',
    minHeight: '50px'
});

export const InstantTourBodyCx = css({
    flexGrow: 1,
    minHeight: 0,
    overflowY: 'auto',
    md: {
        width: '540px'
    }
});

export const InstantTourFooterButtonGroupCx = css({
    alignItems: 'center',
    backgroundColor: 'hpxWhite',
    borderTopColor: 'hpxGrey400',
    borderTopStyle: 'solid',
    borderTopWidth: '1px',
    display: 'flex',
    flexShrink: 0,
    gap: '12px',
    justifyContent: 'flex-end',
    padding: '10px',
    position: 'static',
    width: '100%',
    md: {
        position: 'static'
    }
});

export const TourTypeIconCx = css({
    width: '22px',
    height: '22px'
});

export const TourTypeTextCx = css({
    fontWeight: 'bold',
    marginLeft: '8px'
});
