// @ts-nocheck
/* eslint-enable */
// App
import React, { useRef, useEffect } from 'react';
import PropTypes, { string } from 'prop-types';
import cx from 'classnames';

// Misc / Utils
import IconX from 'images/icons/x.svg';
import './style.scss';

const { node, func } = PropTypes;
/**
 * Popover is a small popup-esque component that is not full screen.
 * @param {Object} props - Component props
 * @param {ReactNode} props.children - React children components
 * @param {string} props.className - Additional classes to be added
 * @param {function} props.onHidePopup - Required function to close the popover
 */
const Popover = ({ children, className, onHidePopup }) => {
    /**
     * Adds ref to component - used to determine whether the user
     * has clicked outside of the component
     */
    const popoverRef = useRef();

    const onHandleClick = (e) => {
        if (popoverRef.current.contains(e.target)) {
            // If the click is within the element, do nothing
            return;
        }

        /**
         * If the click is outside of the element, call the onHidePopup
         * callback
         */
        onHidePopup();
    };

    /**
     * Adds event listeners that will be used by the ref
     */
    useEffect(() => {
        document.addEventListener('mousedown', onHandleClick);

        return () => {
            document.removeEventListener('mousedown', onHandleClick);
        };
    }, [onHandleClick]);

    return (
        <div ref={popoverRef} className={cx('Popover', className)}>
            <div className="Popover-close" onClick={onHidePopup}>
                <img src={IconX} height="16px" width="16px" alt="" />
            </div>
            <div className="Popover-body">{children}</div>
        </div>
    );
};

Popover.propTypes = {
    children: node,
    className: string,
    onHidePopup: func
};

export default Popover;
