import React from 'react';
import Linker from 'app/shared/modules/Linker';
import has from 'lodash/has';

interface Props {
    listing: {
        listedBy: {
            contactPhone: string
        }
    }
    useLongText?: boolean;
}

const CallLinkV2 = (props: Props) => {
    let { listing, useLongText } = props;
    if (!has(listing, 'listedBy.contactPhone')) {
        return null;
    }

    return (
        <Linker rel="nofollow" to={'tel://' + listing.listedBy.contactPhone}>
            {useLongText ? 'Call for price' : 'Call'}
        </Linker>
    );
};

export default CallLinkV2;
