// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import findIndex from 'lodash/findIndex';
import styled from 'styled-components';
import { stacked } from 'app/shared/styles/_spacing';

import controller from './controller';
import Select from 'app/shared/core/Select';

const { object, func, number } = PropTypes;

const StyledContainer = styled.div`
    ${stacked._2x};
`;
class BedsBathsSelect extends Component {
    static propTypes = {
        listing: object.isRequired,
        onBedsBathsSelect: func.isRequired,
        beds: number.isRequired,
        baths: number.isRequired
    };
    handleSelect = (e) => {
        const { listing, onBedsBathsSelect } = this.props;
        const model = controller.dedupeBedsBathsOptions(listing.floorplans)[e.target.value];
        onBedsBathsSelect({ beds: model.beds, baths: model.baths });
    };
    render() {
        const { listing, beds, baths } = this.props;
        const dedupedModels = controller.dedupeBedsBathsOptions(listing.floorplans);
        return (
            <StyledContainer className="BedsBathsSelect">
                <Select
                    aria-label="bed and bath"
                    label="Unit"
                    required
                    value={findIndex(dedupedModels, { beds, baths })}
                    onChange={this.handleSelect}
                >
                    {dedupedModels.map((model, idx) => {
                        return (
                            <option value={idx} key={`${model.beds}-${model.baths}`}>
                                {model.key}
                            </option>
                        );
                    })}
                </Select>
            </StyledContainer>
        );
    }
}

export default BedsBathsSelect;
