// @ts-nocheck
/* eslint-enable */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

// Components
import Text from 'app/shared/core/Text';

// Styles
import * as S from './styles';

// Utils
import { analyticsEvent } from 'app/client/universal-analytics';

/* eslint-disable react/jsx-no-bind */

const Slide = ({
    body,
    btnPrimaryEvent,
    btnPrimaryHref,
    btnPrimaryLabel,
    btnSecondaryHref,
    btnSecondaryLabel,
    btnSecondaryEvent,
    heading,
    imgFull,
    imgSrc
}) => {
    const ref = useRef();
    const dispatch = useDispatch();
    const [isInView, setIsInView] = useState(false);

    useEffect(() => {
        require('intersection-observer');
    }, []);

    // use IntersectionObserver to disable tab navigation of buttons on slides out of view
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsInView(true);
                } else {
                    setIsInView(false);
                }
            },
            {
                threshold: 1 // only consider in view if every pixel is in view
            }
        );
        if (ref.current) {
            observer.observe(ref.current);
        }
    }, [ref]);

    const trackBtnPrimaryClick = () => {
        dispatch(analyticsEvent(btnPrimaryEvent));
    };

    const trackBtnSecondaryClick = () => {
        dispatch(analyticsEvent(btnSecondaryEvent));
    };

    return (
        <S.Container ref={ref}>
            <S.Image src={imgSrc} imgFull={imgFull} />
            <S.TextContainer>
                <S.HeaderText htmlTag="h3" size="xl">
                    {heading}
                </S.HeaderText>
                <Text size="md">{body}</Text>
            </S.TextContainer>
            <S.ButtonContainer>
                <S.PrimaryButton
                    tabIndex={isInView ? 0 : -1}
                    htmlTag="a"
                    href={btnPrimaryHref}
                    onClick={trackBtnPrimaryClick}
                >
                    {btnPrimaryLabel}
                </S.PrimaryButton>
                {btnSecondaryLabel && (
                    <S.SecondaryButton
                        tabIndex={isInView ? 0 : -1}
                        to={btnSecondaryHref}
                        onClick={trackBtnSecondaryClick}
                    >
                        {btnSecondaryLabel}
                    </S.SecondaryButton>
                )}
            </S.ButtonContainer>
        </S.Container>
    );
};

export default Slide;
