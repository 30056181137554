// @ts-nocheck
/* eslint-enable */
import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';
import { smoothTransition, noUserSelect } from 'app/shared/styles/_mixins';
import { zindex } from 'app/shared/styles/_zindex';
import { viewports } from 'app/shared/styles/_breakpoints';
import TextBase from 'app/shared/core/Text';

export const MenuDropdown = styled.ul`
    ${smoothTransition};
    position: fixed;
    margin-top: 5px;
    right: 5px;
    z-index: ${zindex['$z-index-header']};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border: 1px solid ${colors['$hpx-grey-500']};
    border-radius: 4px;
    background: ${colors['$hpx-white']};
    cursor: pointer;

    @media ${viewports['md-and-up']} {
        right: 20px;
    }
`;

export const MenuItem = styled.li`
    width: 100%;

    &:first-child {
        border-radius: 4px 4px 0 0;
    }

    &:last-child {
        border-radius: 0 0 4px 4px;
    }

    &:hover {
        background-color: ${colors['$hpx-grey-100']};
    }
`;

export const Button = styled.button`
    ${smoothTransition};
    ${noUserSelect};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 16px;
    width: 100%;
`;

export const Icon = styled.img`
    margin-right: 5px;
`;

export const Text = styled(TextBase)`
    margin-top: 2px;
`;
