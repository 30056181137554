import styled, { css } from 'styled-components';
import { colors } from 'app/shared/styles/_colors';

interface Props {
    isActive: boolean
}

export const Container = styled.div`
    position: absolute;
    display: flex;
    justify-content: space-between;
    bottom: 24px;
    left: 50%;
    transform: translate(-50%, 50%);
`;

export const Dot = styled.button`
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 5px solid white;
    cursor: pointer;
    background: ${colors['$hpx-grey-400']};
    margin-right: 2px;

    &:hover {
        background: ${colors['$hpx-grey-500']};
    }

    &:last-of-type {
        margin: 0;
    }

    ${({ isActive }: Props) =>
        isActive && css`
            background: ${colors['$hpx-teal-500']};

            &:hover {
                background: ${colors['$hpx-teal-600']}};
            }
        `
    };
`;
