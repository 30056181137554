// @ts-nocheck
/* eslint-enable */
import React from 'react';
import ReviewsBreakdownBar from 'app/shared/modules/reviews/ReviewsBreakdownBar';
import './style.scss';
import IconStarYellow from 'images/icons/star-yellow.svg';

const ReviewsBreakdown = ({ reviewsBreakdown }) => {
    return (
        <div className="ReviewsBreakdown">
            <div className="ReviewsBreakdown-rating-container">
                <span className="ReviewsBreakdown-label">
                    <span className="ReviewsBreakdown-num">5</span>{' '}
                    <img className="ReviewsBreakdown-star" src={IconStarYellow} height={12} width={12} />
                </span>
                <div className="ReviewsBreakdown-bar-container">
                    <ReviewsBreakdownBar percentWidth={reviewsBreakdown.fiveStar} />
                </div>
                {reviewsBreakdown.fiveStar}%
            </div>
            <div className="ReviewsBreakdown-rating-container">
                <span className="ReviewsBreakdown-label">
                    <span className="ReviewsBreakdown-num">4</span>{' '}
                    <img className="ReviewsBreakdown-star" src={IconStarYellow} height={12} width={12} />
                </span>
                <div className="ReviewsBreakdown-bar-container">
                    <ReviewsBreakdownBar percentWidth={reviewsBreakdown.fourStar} />
                </div>
                {reviewsBreakdown.fourStar}%
            </div>
            <div className="ReviewsBreakdown-rating-container">
                <span className="ReviewsBreakdown-label">
                    <span className="ReviewsBreakdown-num">3</span>{' '}
                    <img className="ReviewsBreakdown-star" src={IconStarYellow} height={12} width={12} />
                </span>
                <div className="ReviewsBreakdown-bar-container">
                    <ReviewsBreakdownBar percentWidth={reviewsBreakdown.threeStar} />
                </div>
                {reviewsBreakdown.threeStar}%
            </div>
            <div className="ReviewsBreakdown-rating-container">
                <span className="ReviewsBreakdown-label">
                    <span className="ReviewsBreakdown-num">2</span>{' '}
                    <img className="ReviewsBreakdown-star" src={IconStarYellow} height={12} width={12} />
                </span>
                <div className="ReviewsBreakdown-bar-container">
                    <ReviewsBreakdownBar percentWidth={reviewsBreakdown.twoStar} />
                </div>
                {reviewsBreakdown.twoStar}%
            </div>
            <div className="ReviewsBreakdown-rating-container">
                <span className="ReviewsBreakdown-label">
                    <span className="ReviewsBreakdown-num">1</span>{' '}
                    <img className="ReviewsBreakdown-star" src={IconStarYellow} height={12} width={12} />
                </span>
                <div className="ReviewsBreakdown-bar-container">
                    <ReviewsBreakdownBar percentWidth={reviewsBreakdown.oneStar} />
                </div>
                {reviewsBreakdown.oneStar}%
            </div>
        </div>
    );
};

export default ReviewsBreakdown;
