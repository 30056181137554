import React from 'react';
import HdpSummaryAddress from 'app/shared/modules/hdp/HdpSummary/HdpSummaryAddress';
import HdpSummaryDetails from 'app/shared/modules/hdp/HdpSummary/HdpSummaryDetails';
import HdpSummaryListingTags from 'app/shared/modules/hdp/HdpSummary/HdpSummaryListingTags';
import { ListingType } from 'app/types';

const HdpSummary = ({ listing }: { listing: ListingType }) => {
    const { active: isListingActive, isAllowedToRetain } = listing;
    /**
     * Data should be hidden per MLS if the listing is:
     * -- active: false
     * -- isAllowedToRetain: false
     */
    const shouldBeHiddenIfDataCannotBeRetained = isListingActive === false && isAllowedToRetain === false;

    return (
        <>
            <HdpSummaryAddress listing={listing} />
            {shouldBeHiddenIfDataCannotBeRetained ? null : <HdpSummaryDetails listing={listing} />}
            <HdpSummaryListingTags listing={listing} />
        </>
    );
};

export default HdpSummary;
