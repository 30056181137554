import React from 'react';
import cx from 'classnames';
import './style.scss';

interface Props {
    className?: string
    children: React.ReactNode
}

class ListItem extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <li className={cx('ListItem', this.props.className)}>
                <span className="ListItem-text">{this.props.children}</span>
            </li>
        );
    }
}

export default ListItem;
