// @ts-nocheck
/* eslint-enable */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useYieldCallback } from '@zillow/yield-callback';
import isEmpty from 'lodash/isEmpty';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import IconArrowRight from 'images/icons/arrow-right.svg';
import RouteActions from 'app/shared/flux/actions/RouteActions';
import * as S from './styles';

const NeighborhoodLink = React.memo(() => {
    const dispatch = useDispatch();
    const areaBreadcrumbs = useSelector((state) => state.currentListingDetails.currentListing.areas.breadcrumbs);
    const searchSlug = useSelector((state) => state.filter.search.slug);

    const getMostSpecificArea = useCallback(() => {
        // Some areas contain only 2 breadcrumbs, some contain 3. Get most specific area we can get.
        return areaBreadcrumbs[areaBreadcrumbs.length - 1] || {};
    }, [areaBreadcrumbs]);

    const getAreaUri = useCallback(() => {
        const { resourceId } = getMostSpecificArea();
        if (resourceId) {
            return '/' + resourceId + '/' + searchSlug;
        } else {
            return null;
        }
    }, [getMostSpecificArea, searchSlug]);

    const areaUri = getAreaUri();

    const yieldTrackAndTransitionTo = useYieldCallback(() => {
        dispatch(analyticsEvent(gaEvents.HDP_EXPIRED_AREA_CLICK));
        dispatch(RouteActions.transitionToUrlWithFilterQuery(areaUri));
    });

    const handleClick = useCallback(
        (e) => {
            if (e) {
                e.preventDefault();
            }

            yieldTrackAndTransitionTo();
        },
        [yieldTrackAndTransitionTo]
    );

    if (isEmpty(areaBreadcrumbs)) {
        return null;
    }

    return (
        <S.NeighborhoodLinkContainer>
            <S.StyledLinker to={areaUri} onClick={handleClick}>
                See more listings in {getMostSpecificArea().name}
                <S.ArrowIcon src={IconArrowRight} />
            </S.StyledLinker>
        </S.NeighborhoodLinkContainer>
    );
});

export default NeighborhoodLink;
