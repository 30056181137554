// @ts-nocheck
/* eslint-enable */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import IconEye from 'images/icons/eye.svg';
import Button from 'app/shared/core/Button';
import Input from 'app/shared/core/Input';
import Radio from 'app/shared/core/Radio';
import Row from 'app/shared/core/Row';
import UserActions from 'app/shared/flux/actions/UserActions';
import userUtils from 'app/shared/utils/userUtils';

import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import dateUtils from 'app/shared/utils/dateUtils';
import {
    Disclaimer,
    DisclaimerIcon,
    DisclaimerTitle,
    InputLabel,
    SectionHint,
    StyledCheckBox,
    StyledContainer,
    StyledPrimaryText,
    StyledAlertText
} from './styles';

const initialFormState = {
    moveInDate: false,
    moveInTimeFrame: '',
    leaseDuration: '',
    incomeYearly: '',
    creditScore: '',
    creditScoreMin: '',
    creditScoreMax: '',
    numOccupants: '',
    numBeds: '',
    desiredPets: '',
    desiredParking: ''
};

const formReducer = (state, action) => {
    const { payload, type } = action;

    switch (type) {
        case 'SET_MOVE_IN_DATE':
            return {
                ...state,
                moveInDate: payload
            };
        case 'SET_FLEXIBLE_MOVE_IN':
            return {
                ...state,
                moveInTimeFrame: payload
            };
        case 'SET_LEASE_DURATION':
            return {
                ...state,
                leaseDuration: Number(payload)
            };
        case 'SET_INCOME':
            return {
                ...state,
                incomeYearly: Number(payload)
            };
        case 'SET_CREDIT_SCORE':
            if (!payload) {
                return state;
            }

            const [min, max] = payload.split('-');

            return {
                ...state,
                creditScore: payload,
                creditScoreMin: min,
                creditScoreMax: max
            };
        case 'SET_OCCUPANTS':
            return {
                ...state,
                numOccupants: Number(payload)
            };
        case 'SET_BEDS':
            return {
                ...state,
                numBeds: Number(payload)
            };
        case 'SET_PETS':
            return {
                ...state,
                desiredPets: payload
            };
        case 'SET_PARKING':
            return {
                ...state,
                desiredParking: payload
            };
        default:
            return state;
    }
};

const RenterProfileForm = ({ isPopup = false, shouldSaveData = false, hidePopupCallback = () => { } } = {}) => {
    const dispatch = useDispatch();
    const renterProfileRedux = useSelector((state) => state.user.renterProfile);

    const [updatePending, setUpdatePending] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');

    const [state, dispatchState] = useReducer(formReducer, initialFormState);

    const shouldAddLeadingZeroForDate = (value) => {
        if (value < 10) {
            return `0${value}`;
        }

        return value;
    };

    const mapInputToApi = () => {
        return userUtils.mapRenterProfileDataToApi({
            moveInDate: state.moveInDate,
            moveInTimeFrame: state.moveInTimeFrame,
            leaseDuration: state.leaseDuration,
            incomeYearly: state.incomeYearly,
            numOccupants: state.numOccupants,
            creditScoreMin: state.creditScoreMin,
            creditScoreMax: state.creditScoreMax,
            numBeds: state.numBeds,
            desiredPets: state.desiredPets,
            desiredParking: state.desiredParking
        });
    };

    const handleSaveClick = useCallback(
        ({ fromHdp } = {}) => {
            if (updatePending) {
                return;
            }

            if (fromHdp) {
                dispatch(analyticsEvent(gaEvents.RENTER_PROFILE_SAVE, { category: 'HDP' }));
            } else {
                dispatch(analyticsEvent(gaEvents.RENTER_PROFILE_SAVE, { category: 'UserDashboard' }));
            }

            setErrorMsg('');
            setSuccessMsg('');
            setUpdatePending(true);

            const queryObj = mapInputToApi();

            dispatch(UserActions.renterProfile.update(queryObj))
                .then(() => {
                    setUpdatePending(false);
                    setSuccessMsg('Your profile has been updated');
                    hidePopupCallback();
                })
                .catch(() => {
                    setUpdatePending(false);
                    setErrorMsg('An error has occurred, please try again later');
                    hidePopupCallback();
                });
        },
        [dispatch, mapInputToApi]
    );

    const handleDateChange = useCallback((e) => {
        const { value } = e.target;
        const inputDate = new Date(value);

        // Native date picker returns the incorrect day for some
        // reason, so we need to add 24 hours to the selected date.
        const adjustedDate = new Date(inputDate.getTime() + 60 * 60 * 24 * 1000);

        dispatchState({ type: 'SET_MOVE_IN_DATE', payload: adjustedDate });
    }, []);

    const toggleFlexibleMoveIn = useCallback(() => {
        const { moveInTimeFrame } = state;
        dispatchState({ type: 'SET_FLEXIBLE_MOVE_IN', payload: !moveInTimeFrame ? 'flexible' : null });
    }, [state.moveInTimeFrame]);

    const handleLeaseSelect = useCallback((e) => {
        const { value } = e.target;
        dispatchState({ type: 'SET_LEASE_DURATION', payload: value });
    }, []);

    const handleIncomeSelect = useCallback((e) => {
        const { value } = e.target;
        dispatchState({ type: 'SET_INCOME', payload: value });
    }, []);

    const handleCreditScoreSelect = useCallback((e) => {
        const { value } = e.target;
        dispatchState({ type: 'SET_CREDIT_SCORE', payload: value });
    }, []);

    const handleNumOccupantsSelect = useCallback((e) => {
        const { value } = e.target;
        dispatchState({ type: 'SET_OCCUPANTS', payload: value });
    }, []);

    const handleBedsSelect = useCallback((e) => {
        const { value } = e.target;
        dispatchState({ type: 'SET_BEDS', payload: value });
    }, []);

    const handlePetsSelect = useCallback((e) => {
        const { value } = e.target;
        dispatchState({ type: 'SET_PETS', payload: value });
    }, []);

    const handleParkingSelect = useCallback((e) => {
        const { value } = e.target;
        dispatchState({ type: 'SET_PARKING', payload: value });
    }, []);

    const updateRenterProfileData = async (data) => {
        dispatchState({ type: 'SET_MOVE_IN_DATE', payload: data.moveInDate });
        dispatchState({ type: 'SET_FLEXIBLE_MOVE_IN', payload: data.moveInTimeFrame });
        dispatchState({ type: 'SET_LEASE_DURATION', payload: data.leaseDuration });
        dispatchState({ type: 'SET_INCOME', payload: data.incomeYearly });
        dispatchState({ type: 'SET_OCCUPANTS', payload: data.numOccupants });
        dispatchState({ type: 'SET_BEDS', payload: data.numBeds });
        dispatchState({ type: 'SET_PETS', payload: data.desiredPets });
        dispatchState({ type: 'SET_PARKING', payload: data.desiredParking });
        dispatchState({ type: 'SET_CREDIT_SCORE', payload: data.creditScore });
    };

    useEffect(() => {
        updateRenterProfileData(renterProfileRedux);
    }, [renterProfileRedux]);

    // Handles instance when clicking "Save" button from PostContactRenterProfile modal.
    useEffect(() => {
        if (shouldSaveData) {
            handleSaveClick({ fromHdp: true });
        }
    }, [shouldSaveData]);

    let formatDate = state.moveInDate
        ? [
            state.moveInDate.getFullYear(),
            shouldAddLeadingZeroForDate(state.moveInDate.getMonth() + 1),
            shouldAddLeadingZeroForDate(state.moveInDate.getDate())
        ].join('-')
        : '';

    return (
        <StyledContainer isPopup={isPopup}>
            <Row size="md">
                Your renter profile gives landlords a few details about yourself and what you want in a rental.
            </Row>
            <Disclaimer size="md">
                <DisclaimerTitle>
                    <DisclaimerIcon alt="disclaimer information icon" src={IconEye} /> Shared with all contacts made on
                    HotPads
                </DisclaimerTitle>
                Your profile is shared automatically with all landlords you contact on HotPads.
            </Disclaimer>
            <Row size="md">
                <InputLabel>Preferred move-in date</InputLabel>
                <Input
                    placeholder="mm/dd/yyyy"
                    type="date"
                    onChange={handleDateChange}
                    value={formatDate}
                    min={dateUtils.formatDateYyyyMmDd(new Date(), '-')}
                />
                <StyledCheckBox
                    onChange={toggleFlexibleMoveIn}
                    checked={state.moveInTimeFrame === 'flexible'}
                    ariaLabel={'Flexible on move-in date'}
                    label="Flexible on move-in date"
                />
            </Row>
            <Row size="md">
                <InputLabel>Preferred lease duration</InputLabel>
                <div aria-labelledby="lease-duration-group" role="radiogroup">
                    <Radio
                        ariaLabel="Month to month"
                        id="leaseMonthToMonth"
                        isChecked={state.leaseDuration === 1}
                        name="LeaseDurationOption"
                        onChange={handleLeaseSelect}
                        value={1}
                    >
                        Month-to-month
                    </Radio>
                    <Radio
                        ariaLabel="6 months"
                        id="leaseMonths6"
                        isChecked={state.leaseDuration === 6}
                        name="LeaseDurationOption"
                        onChange={handleLeaseSelect}
                        value={6}
                    >
                        6 months
                    </Radio>
                    <Radio
                        ariaLabel="12 months"
                        id="leaseMonths12"
                        isChecked={state.leaseDuration === 12}
                        name="LeaseDurationOption"
                        onChange={handleLeaseSelect}
                        value={12}
                    >
                        12 months
                    </Radio>
                    <Radio
                        ariaLabel="13 months or longer"
                        id="leaseMonths13plus"
                        isChecked={state.leaseDuration === 13}
                        name="LeaseDurationOption"
                        onChange={handleLeaseSelect}
                        value={13}
                    >
                        13+ months
                    </Radio>
                </div>
            </Row>
            <Row size="md">
                <InputLabel>Estimated annual income</InputLabel>
                <div aria-labelledby="estimated-income-group" role="radiogroup">
                    <Radio
                        ariaLabel="0 to 35,000 dollars"
                        name="AnnualIncomeOption"
                        id="income0to35"
                        isChecked={state.incomeYearly === 17500}
                        onChange={handleIncomeSelect}
                        value={17500}
                    >
                        $0-$35,000
                    </Radio>
                    <Radio
                        ariaLabel="35,001 to 50,000 dollars"
                        name="AnnualIncomeOption"
                        id="income350to50"
                        isChecked={state.incomeYearly === 42500}
                        onChange={handleIncomeSelect}
                        value={42500}
                    >
                        $35,001-$50,000
                    </Radio>
                    <Radio
                        ariaLabel="50,001 to 75,000 dollars"
                        name="AnnualIncomeOption"
                        id="income50to75"
                        isChecked={state.incomeYearly === 62500}
                        onChange={handleIncomeSelect}
                        value={62500}
                    >
                        $50,001-$75,000
                    </Radio>
                    <Radio
                        ariaLabel="75,001 to $100,000 dollars"
                        name="AnnualIncomeOption"
                        id="income75to100"
                        isChecked={state.incomeYearly === 87500}
                        onChange={handleIncomeSelect}
                        value={87500}
                    >
                        $75,001-$100,000
                    </Radio>
                    <Radio
                        ariaLabel="$100,001 to 150,000 dollars"
                        name="AnnualIncomeOption"
                        id="income100to150"
                        isChecked={state.incomeYearly === 125000}
                        onChange={handleIncomeSelect}
                        value={125000}
                    >
                        $100-001-$150,000
                    </Radio>
                    <Radio
                        ariaLabel="$150,000 dollars or more"
                        name="AnnualIncomeOption"
                        id="income150plus"
                        isChecked={state.incomeYearly === 150001}
                        onChange={handleIncomeSelect}
                        value={150001}
                    >
                        $150,000+
                    </Radio>
                </div>
                <SectionHint>Select your gross (pre-tax) income</SectionHint>
            </Row>
            <Row size="md">
                <InputLabel>Credit score</InputLabel>
                <div aria-labelledby="credit-score-group" role="radiogroup">
                    <Radio
                        ariaLabel="credit score between 300 to 579"
                        name="CreditScoreOption"
                        id="credit300to579"
                        isChecked={state.creditScore === '300-579'}
                        onChange={handleCreditScoreSelect}
                        value="300-579"
                    >
                        300-579
                    </Radio>
                    <Radio
                        ariaLabel="credit score between 580 to 619"
                        name="CreditScoreOption"
                        id="credit580to619"
                        onChange={handleCreditScoreSelect}
                        isChecked={state.creditScore === '580-619'}
                        value="580-619"
                    >
                        580-619
                    </Radio>
                    <Radio
                        ariaLabel="credit score between 620 to 719"
                        name="CreditScoreOption"
                        id="credit620to719"
                        isChecked={state.creditScore === '620-719'}
                        onChange={handleCreditScoreSelect}
                        value="620-719"
                    >
                        620-719
                    </Radio>
                    <Radio
                        ariaLabel="credit score 720 or more"
                        name="CreditScoreOption"
                        id="credit720plus"
                        isChecked={state.creditScore === '720-850'}
                        onChange={handleCreditScoreSelect}
                        value="720-850"
                    >
                        720+
                    </Radio>
                </div>
                <SectionHint>It's okay if you're not sure</SectionHint>
            </Row>
            <Row size="md">
                <InputLabel>Number of people who will live in the home</InputLabel>
                <div aria-labelledby="total-people-group" role="radiogroup">
                    <Radio
                        ariaLabel="1 person"
                        name="TotalPeopleOption"
                        id="people1"
                        isChecked={state.numOccupants === 1}
                        onChange={handleNumOccupantsSelect}
                        value={1}
                    >
                        1
                    </Radio>
                    <Radio
                        ariaLabel="1 person"
                        name="TotalPeopleOption"
                        id="people2"
                        isChecked={state.numOccupants === 2}
                        onChange={handleNumOccupantsSelect}
                        value={2}
                    >
                        2
                    </Radio>
                    <Radio
                        ariaLabel="1 person"
                        name="TotalPeopleOption"
                        id="people3"
                        isChecked={state.numOccupants === 3}
                        onChange={handleNumOccupantsSelect}
                        value={3}
                    >
                        3
                    </Radio>
                    <Radio
                        ariaLabel="1 person"
                        name="TotalPeopleOption"
                        id="people4"
                        isChecked={state.numOccupants === 4}
                        onChange={handleNumOccupantsSelect}
                        value={4}
                    >
                        4
                    </Radio>
                    <Radio
                        ariaLabel="1 person"
                        name="TotalPeopleOption"
                        id="people5plus"
                        isChecked={state.numOccupants === 5}
                        onChange={handleNumOccupantsSelect}
                        value={5}
                    >
                        5+
                    </Radio>
                </div>
                <SectionHint>Include yourself, any other adults, and any children</SectionHint>
            </Row>
            <Row size="md">
                <InputLabel>Number of bedrooms desired</InputLabel>
                <div aria-labelledby="bedrooms-desired-group" role="radiogroup">
                    <Radio
                        ariaLabel="Studio"
                        name="BedroomsOption"
                        id="bedrooms0"
                        isChecked={state.numBeds === 0}
                        onChange={handleBedsSelect}
                        value={0}
                    >
                        Studio
                    </Radio>
                    <Radio
                        ariaLabel="1 bedroom"
                        name="BedroomsOption"
                        isChecked={state.numBeds === 1}
                        id="bedrooms1"
                        onChange={handleBedsSelect}
                        value={1}
                    >
                        1
                    </Radio>
                    <Radio
                        ariaLabel="2 bedrooms"
                        name="BedroomsOption"
                        isChecked={state.numBeds === 2}
                        id="bedrooms2"
                        onChange={handleBedsSelect}
                        value={2}
                    >
                        2
                    </Radio>
                    <Radio
                        ariaLabel="3 bedrooms"
                        name="BedroomsOption"
                        isChecked={state.numBeds === 3}
                        id="bedrooms3"
                        onChange={handleBedsSelect}
                        value={3}
                    >
                        3
                    </Radio>
                    <Radio
                        ariaLabel="4 or more bedrooms"
                        name="BedroomsOption"
                        isChecked={state.numBeds === 4}
                        onChange={handleBedsSelect}
                        id="bedrooms4plus"
                        value={4}
                    >
                        4+
                    </Radio>
                </div>
            </Row>
            <Row size="md">
                <InputLabel>Pets</InputLabel>
                <div aria-labelledby="pets-group" role="radiogroup">
                    <Radio
                        ariaLabel="Have pets"
                        name="PetsOption"
                        id="petsYes"
                        value="true"
                        isChecked={state.desiredPets === 'true'}
                        onChange={handlePetsSelect}
                    >
                        Yes
                    </Radio>
                    <Radio
                        ariaLabel="No pets"
                        name="PetsOption"
                        id="petsNo"
                        value="false"
                        isChecked={state.desiredPets === 'false'}
                        onChange={handlePetsSelect}
                    >
                        No
                    </Radio>
                </div>
                <SectionHint>Assistance animals don’t need to be included</SectionHint>
            </Row>
            <Row size="md">
                <InputLabel>Parking needs</InputLabel>
                <div aria-labelledby="parking-needs-group" role="radiogroup">
                    <Radio
                        ariaLabel="Need parking"
                        name="ParkingOption"
                        id="parkingYes"
                        value="true"
                        isChecked={state.desiredParking === 'true'}
                        onChange={handleParkingSelect}
                    >
                        Yes
                    </Radio>
                    <Radio
                        ariaLabel="No parking needed"
                        name="ParkingOption"
                        id="parkingNo"
                        value="false"
                        isChecked={state.desiredParking === 'false'}
                        onChange={handleParkingSelect}
                    >
                        No
                    </Radio>
                </div>
            </Row>
            {!isPopup && (
                <Button onClick={handleSaveClick} className="UserRenterIdPage-button">
                    {updatePending ? 'Saving...' : 'Save'}
                </Button>
            )}
            {successMsg && (
                <Row>
                    <StyledPrimaryText size="sm">
                        {successMsg}
                    </StyledPrimaryText>
                </Row>
            )}
            {errorMsg && (
                <Row>
                    <StyledAlertText size="sm">
                        {errorMsg}
                    </StyledAlertText>
                </Row>
            )}
        </StyledContainer>
    );
};

export default RenterProfileForm;
