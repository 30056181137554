// @ts-nocheck
/* eslint-enable */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import Text from 'app/shared/core/Text';
import * as S from './styles';
import Disclaimer from 'app/shared/modules/hdp/form/Disclaimer';
import { useSelector } from 'react-redux';
import TourStatusModule from 'app/shared/modules/hdp/InstantTour/components/TourStatusModule';
import validator from 'app/shared/utils/validator';
import Linker from 'app/shared/modules/Linker';
import mailcheck from 'mailcheck';
import { useInstantTourForm, useSelectedTourProvider } from '../../hooks';

const ContactInfoAndConfirmStep = ({ setIsPrimaryBtnDisabled, isSchedulingReqLoading, deactivatedAccountEmail }) => {
    const { updateInstantTourForm } = useInstantTourForm();
    const user = useSelector((state) => state.user);
    const instantTourForm = useSelector((state) => state.currentListingDetails.instantTourForm);
    const { providerName } = useSelectedTourProvider();
    const nameInputRef = useRef(null);
    const [nameInput, setNameInput] = useState('');
    const [phoneInput, setPhoneInput] = useState('');
    const [emailInput, setEmailInput] = useState('');
    const [isNameValid, setIsNameValid] = useState(false);
    const [isPhoneValid, setIsPhoneValid] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [mailcheckHint, setMailcheckHint] = useState('');
    const [emailHelpMessage, setEmailHelpMessage] = useState('');
    const [showEmailErrorBorder, setShowEmailErrorBorder] = useState(false);

    useEffect(() => {
        nameInputRef?.current?.focus();
    }, []);

    const validateNameInput = useCallback((name) => {
        if (name.trim().includes(' ')) {
            setIsNameValid(true);
        } else {
            setIsNameValid(false);
        }
    }, []);

    const validatePhoneInput = useCallback((phone) => {
        // majority of phone number sanitization and validation is handled by <S.PhoneTextInput />
        if (phone.length === 10) {
            setIsPhoneValid(true);
        } else {
            setIsPhoneValid(false);
        }
    }, []);

    const validateEmailInput = useCallback((email) => {
        if (validator.email(email).isValid) {
            setIsEmailValid(true);
        } else {
            setIsEmailValid(false);
        }
    }, []);

    // handle auto populating input fields on initial render
    useEffect(() => {
        // choose user entered instantTourForm name over account associated name
        const userHasAccountName = user.info.firstName || user.info.lastName;
        const userChangedName = Boolean(instantTourForm.firstName);
        let firstName = instantTourForm.firstName;
        let lastName = instantTourForm.lastName;

        if (userHasAccountName && !userChangedName) {
            firstName = user.info.firstName;
            lastName = user.info.lastName;
        }

        let name = firstName ? `${firstName} ${lastName}` : '';
        let phone = instantTourForm.phone;
        let email = user.info.email ?? instantTourForm.email;

        if (user.loggedIn) {
            updateInstantTourForm({
                firstName,
                lastName,
                email
            });
        }

        setNameInput(name);
        setPhoneInput(phone);
        setEmailInput(email);

        validateNameInput(name);
        validatePhoneInput(phone);
        validateEmailInput(email);
        // We only want this useEffect to run on initial render:
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // handle disabling submit button on invalid input
    useEffect(() => {
        const isEmailAccountDeactivated = deactivatedAccountEmail && emailInput === deactivatedAccountEmail;

        if (isNameValid && isPhoneValid && isEmailValid && !isEmailAccountDeactivated) {
            setIsPrimaryBtnDisabled(false);
        } else {
            setIsPrimaryBtnDisabled(true);
        }
    }, [isNameValid, isPhoneValid, isEmailValid, setIsPrimaryBtnDisabled, deactivatedAccountEmail, emailInput]);

    // handle updating email input help message and error border status
    useEffect(() => {
        if (deactivatedAccountEmail && emailInput === deactivatedAccountEmail) {
            setEmailHelpMessage(
                'This email is associated with an account marked for deletion. Please use another email address.'
            );
            setShowEmailErrorBorder(true);
        } else if (isEmailValid || !emailInput) {
            setEmailHelpMessage(mailcheckHint);
            setShowEmailErrorBorder(false);
        } else {
            setEmailHelpMessage('Please enter a valid email address.');
            setShowEmailErrorBorder(true);
        }
    }, [emailInput, isEmailValid, deactivatedAccountEmail, mailcheckHint]);

    const onNameChange = useCallback(
        (e) => {
            const updatedName = e.target.value;
            setNameInput(updatedName);
            validateNameInput(updatedName);

            // commit name to redux
            const [firstName, ...lastNameTokens] = updatedName.split(' ');
            const lastName = lastNameTokens.join(' ').trim();

            updateInstantTourForm({
                firstName,
                lastName
            });
        },
        [validateNameInput, updateInstantTourForm]
    );

    const onPhoneChange = useCallback(
        (e) => {
            const updatedPhone = e.target.value;
            setPhoneInput(updatedPhone);
            validatePhoneInput(updatedPhone);

            updateInstantTourForm({
                phone: updatedPhone
            });
        },
        [validatePhoneInput, updateInstantTourForm]
    );

    const onEmailChange = useCallback(
        (e) => {
            const updatedEmail = e.target.value;
            setEmailInput(updatedEmail);
            validateEmailInput(updatedEmail);

            // offer suggestions when email format is valid
            if (isEmailValid) {
                mailcheck.run({
                    email: updatedEmail,
                    suggested: (suggestion) => {
                        const updateMailcheckHint = (
                            <span
                                onClick={() => {
                                    setEmailInput(suggestion.full);
                                    setMailcheckHint('');
                                    updateInstantTourForm({
                                        email: suggestion.full
                                    });
                                }}
                            >
                                Did you mean <Linker>{suggestion.full}</Linker>?
                            </span>
                        );

                        setMailcheckHint(updateMailcheckHint);
                    },
                    empty: () => {
                        setMailcheckHint('');
                    }
                });
            }
            updateInstantTourForm({
                email: updatedEmail
            });
        },
        [validateEmailInput, isEmailValid, updateInstantTourForm]
    );

    return (
        <>
            <TourStatusModule />
            <S.Container>
                <S.Header>Confirm your details</S.Header>
                <Text>The property manager may use this information to confirm your tour</Text>
                <S.TextInput
                    required
                    focusInput
                    label={'Your First & Last Name'}
                    autoComplete="name"
                    maxLength={50}
                    disabled={isSchedulingReqLoading}
                    value={nameInput}
                    onChange={onNameChange}
                    placeholder="Your First & Last Name"
                    help={isNameValid || !nameInput ? '' : 'Please enter a first and last name separated by a space.'}
                    hpxStyle={isNameValid || !nameInput ? null : 'error'}
                />
                <S.PhoneTextInput
                    required
                    label={'Phone'}
                    autoComplete="tel-national"
                    maxLength={15}
                    disabled={isSchedulingReqLoading}
                    value={phoneInput}
                    onChange={onPhoneChange}
                    placeholder="Phone"
                    help={isPhoneValid || !phoneInput ? '' : 'Please enter a valid phone number.'}
                    hpxStyle={isPhoneValid || !phoneInput ? null : 'error'}
                />
                <S.TextInput
                    required
                    label={'Email'}
                    autoComplete="email"
                    maxLength={50}
                    disabled={isSchedulingReqLoading || user.loggedIn}
                    type="email"
                    value={emailInput}
                    onChange={onEmailChange}
                    placeholder="Email"
                    help={emailHelpMessage}
                    hpxStyle={showEmailErrorBorder ? 'error' : null}
                />
                <Disclaimer linkType="inverted" size="sm" tourProviderName={providerName} />
            </S.Container>
        </>
    );
};

export default ContactInfoAndConfirmStep;
